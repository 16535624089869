import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import I18n, { useI18nFunction } from '../I18n'
import Button from '../Button'
import { SimpleSelect as Select } from '../form'
import {
  updateProjectUserForm,
  clearProjectUserForm,
  selectProjectUser,
  fetchUserNames,
} from '../../redux/actions'
import {
  getProjectUserForm,
  getUserList,
  getUserPagination,
} from '../../redux/selectors'
import usePagination from '../../hooks/usePagination'
import useValidation from '../../hooks/useValidation'
import useVisited from '../../hooks/useVisited'
import * as schema from '../../validation'

const ProjectUserForm = ({
  pending,
  projectId,
  updateProjectUserForm,
  projectUserForm,
  clearProjectUserForm,
  selectProjectUser,
  selectedProjectUsers,
  users,
  fetchUserNames,
  userPagination,
}) => {
  const I18nFunction = useI18nFunction()

  const { query, setQueryHandler } = usePagination(
    fetchUserNames,
    getUserPagination,
    userPagination,
  )

  const { errors, validate } = useValidation(
    schema.projectUserForm(I18nFunction),
  )

  useEffect(() => {
    const cancelablePromise = validate(projectUserForm)
    return cancelablePromise.cancel
  }, [validate, projectUserForm])

  const { visited, visitField, visitFields } = useVisited()

  const handleBlur = (e) => visitField(e.target.name)

  const handleChange = (e) => {
    updateProjectUserForm({
      [e.target.name]: e.target.value,
      ['name']: getNameById(e.target.value),
    })
  }

  function getNameById(id) {
    const user = users.find((item) => item.id == id)
    return user ? user.name : null
  }

  const handleInputChange = (value) => {
    setQueryHandler(value)
  }

  let hasErrors = Object.keys(errors).length > 0

  const handleSubmit = () => {
    if (hasErrors) {
      visitFields(Object.keys(errors))
    } else {
      if (
        !selectedProjectUsers?.find(
          (user) => user?.userId === projectUserForm?.userId,
        )
      ) {
        selectProjectUser(projectId, projectUserForm)
      }
      clearProjectUserForm()
      visited['userId'] = false
    }
  }

  return (
    <FormWrapper>
      <SubHeader>
        <I18n id="Select_user" />
      </SubHeader>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Name>
            <I18n id="Name" />
          </Name>
          <InputWrapper>
            <Select
              name="userId"
              id="userId"
              value={projectUserForm?.userId}
              onInputChange={handleInputChange}
              onChange={handleChange}
              inputValue={query}
              options={
                users
                  ? users?.map(({ name, id }) => ({
                      label: name,
                      value: id,
                    }))
                  : []
              }
              onBlur={handleBlur}
              error={errors.userId}
              visited={visited.userId}
            />
          </InputWrapper>
        </Row>
        <Actions>
          <Button
            type="submit"
            small
            onClick={() => handleSubmit()}
            inactive={hasErrors}
            pending={pending}
          >
            <I18n id="Add" />
          </Button>
        </Actions>
      </Form>
    </FormWrapper>
  )
}

const Form = styled.form`
  margin: 0 auto;
`

const FormWrapper = styled.div`
  width: 100%;
  height: 25%;
  background-color: ${(props) => props.theme.pageBackground};
  border-radius: 5px;
  padding: 1em;
  margin: 0em 0em;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding-top: 1em;
`

const Name = styled.div`
  display: flex;
  width: 10%;
  align-items: center;
  color: ${({ theme: { subtleText } }) => subtleText};
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 1em;
`
const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1em 0em;
`

const SubHeader = styled.h2`
  margin: 0;
  margin-bottom: 0.5em;
  color: ${(props) => props.theme.text};
`

const mapStateToProps = (state, props) => ({
  projectUserForm: getProjectUserForm(state),
  users: getUserList(state),
  userPagination: getUserPagination(state),
})

const mapDispatchToProps = {
  updateProjectUserForm,
  clearProjectUserForm,
  selectProjectUser,
  fetchUserNames,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectUserForm)
