import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { removeProjectUser } from '../redux/actions'
import caretDown from '../icons/caret-down_gray.svg'
import attentionIcon from '../icons/circled_alert_icon.svg'
import deleteIcon from '../icons/trashcan.svg'
import ownershipIcon from '../icons/chartLine.svg'
import roleIcon from '../icons/role.svg'
import relationIcon from '../icons/relation.svg'
import otherIcon from '../icons/other.svg'
import userIcon from '../icons/user.svg'
import I18n, { useI18nFunction } from './I18n'
import { Link } from 'react-router-dom'
import {
  USER_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  RELATION_OWNERSHIP_CASE,
  RELATION_ROLE_CASE,
  USER_INDIRECT_OWNERSHIP_CASE,
  USER_INDIRECT_ROLE_CASE,
  RELATION_INDIRECT_OWNERSHIP_CASE,
  RELATION_INDIRECT_ROLE_CASE
} from '../caseTypes'
import { camelize } from 'humps'

const ProjectUserList = ({
  projectId,
  projectUsers,
  action,
  setAction,
  unsetCompanyActions,
  removeProjectUser,
}) => {
  const I18nFunction = useI18nFunction()

  const [expandedItems, setExpandedItems] = useState([])

  const handleExpand = (id) => {
    setAction(null)
    if (expandedItems.includes(id)) {
      setExpandedItems(expandedItems.filter((item) => item != id))
    } else {
      setExpandedItems([...expandedItems, id])
    }
  }

  const handleAction = (e, id) => {
    e.stopPropagation()
    unsetCompanyActions(null)
    if (action == id) {
      setAction(null)
    } else {
      setAction(id)
    }
  }

  const handleRemove = (userId) => {
    removeProjectUser(projectId, userId)
  }

  return (
    <Scrollbox>
      {projectUsers?.length == 0 && (
        <NoneAdded>Ingen brukere lagt til</NoneAdded>
      )}
      <List>
        {projectUsers?.map(({ id, name, userId, email, conflicts }) => (
          <li key={userId}>
            <UserListItem>
              <User onClick={() => handleExpand(id)}>
                <Name>{name}</Name>
                <Email>{email}</Email>
              </User>
              <WarningWrapper onClick={() => handleExpand(id)}>
                {conflicts?.length > 0 && (
                  <Warning src={attentionIcon} alt="" />
                )}
              </WarningWrapper>
              <CaretButtonWrapper onClick={() => handleExpand(id)}>
                {conflicts?.length > 0 && (
                  <CaretButton>
                    <Caret
                      src={caretDown}
                      expanded={expandedItems.includes(id)}
                      alt=""
                    />
                  </CaretButton>
                )}
              </CaretButtonWrapper>
              <MoreActionsWrapper>
                <MoreActionsButton
                  className={action == id && 'active'}
                  onClick={(e) => handleAction(e, id)}
                >
                  &middot;&middot;&middot;
                </MoreActionsButton>
                {action == id && (
                  <MoreActions>
                    <ProfileLink to={`/users/${userId}`}>
                      <Action>
                        <ActionText>Gå til profil</ActionText>
                        <ActionIcon src={userIcon} alt="" />
                      </Action>
                    </ProfileLink>
                    <Divider />
                    <Action onClick={() => handleRemove(userId)}>
                      <ActionText>Fjern fra prosjekt</ActionText>
                      <ActionIcon src={deleteIcon} alt="" />
                    </Action>
                  </MoreActions>
                )}
              </MoreActionsWrapper>
            </UserListItem>

            {expandedItems.includes(id) && (
              <ExpandedInfo conflicts={conflicts?.length > 0}>
                {conflicts?.length > 0 ? (
                  <SubList>
                    {conflicts?.map((conflict) => (
                      <li key={conflict?.caseId}>
                        <Conflict>
                          <IconWrapper>
                            <Icon src={conflictIcon(conflict)} />
                          </IconWrapper>
                          <Description>
                            {conflictDescription(conflict, I18nFunction)}
                          </Description>
                          <CaseLinkWrapper>
                            <CaseLink to={`/cases/${conflict?.caseId}`}>
                              <I18n id="Go_to_case" />
                            </CaseLink>
                          </CaseLinkWrapper>
                        </Conflict>
                      </li>
                    ))}
                  </SubList>
                ) : (
                  <NoConflicts>Ingen konflikter</NoConflicts>
                )}
              </ExpandedInfo>
            )}
          </li>
        ))}
      </List>
    </Scrollbox>
  )
}

const conflictDescription = (conflict, I18nFunction) => {
  switch (camelize(conflict.caseType)) {
    case USER_OWNERSHIP_CASE:
      return I18nFunction({
        id: 'conflict_list_user.ownership',
        stockCount: conflict.stockCount,
        shareType: conflict.shareType,
        stakePercentage: parseFloat(conflict.stakePercentage).toFixed(2),
        company: conflict.organizationName,
      })
    case USER_ROLE_CASE:
      return I18nFunction({
        id: 'conflict_list_user.role',
        title: conflict.title,
        company: conflict.organizationName,
      })
    case RELATION_OWNERSHIP_CASE:
      return I18nFunction({
        id: 'conflict_list_user.relation_ownership',
        stockCount: conflict.stockCount,
        shareType: conflict.shareType,
        stakePercentage: parseFloat(conflict.stakePercentage).toFixed(2),
        company: conflict.organizationName,
      })
    case RELATION_ROLE_CASE:
      return I18nFunction({
        id: 'conflict_list_user.relation_role',
        title: conflict.title,
        company: conflict.organizationName,
      })
      case USER_INDIRECT_OWNERSHIP_CASE:
        return I18nFunction({
          id: 'conflict_list_user.indirect_ownership',
          company: conflict.organizationName,
        })
      case USER_INDIRECT_ROLE_CASE:
        return I18nFunction({
          id: 'conflict_list_user.indirect_role',
          company: conflict.organizationName,
        })
      case RELATION_INDIRECT_OWNERSHIP_CASE:
        return I18nFunction({
          id: 'conflict_list_user.relation_indirect_ownership',
          company: conflict.organizationName,
        })
      case RELATION_INDIRECT_ROLE_CASE:
        return I18nFunction({
          id: 'conflict_list_user.relation_indirect_role',
          company: conflict.organizationName,
        })
    default:
      return ''
  }
}

const conflictIcon = (conflict) => {
  switch (camelize(conflict.caseType)) {
    case USER_OWNERSHIP_CASE:
      return ownershipIcon
    case USER_ROLE_CASE:
      return roleIcon
    case RELATION_OWNERSHIP_CASE:
    case RELATION_ROLE_CASE:
      return relationIcon
    default:
      return otherIcon
  }
}

const Scrollbox = styled.div`
  margin: 0;
  position: relative;
  padding: 0em 1em;
  padding-bottom: 6em;
  max-height: 550px;
  overflow: auto;
  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const NoneAdded = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & > * {
    margin-bottom: 0.5em;
  }

  & > :first-child {
    margin-top: 0.2em;
  }
`

const SubList = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;

  & > :not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
`

const UserListItem = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.primaryBackground};
  box-shadow: rgb(219 219 219) 0px 2px 5px;
  padding: 0em 1em;
  height: 3em;
  border-radius: 6px;
`
const User = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  gap: 0.5em;
  cursor: pointer;
`
const Name = styled.div`
  margin: 0px;
  font-size: ${(props) => props.theme.normalText};
  text-overflow: ellipsis;
  overflow: hidden;
`

const Email = styled.div`
  margin: 0px;
  color: ${({ theme: { lightText } }) => lightText};
`

const MoreActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 7%;
  cursor: default;
`

const MoreActionsButton = styled.div`
  font-size: 20px;
  cursor: pointer;
  padding: 0.1em 0.2em;
  border-radius: 3px;
  &:hover {
    background-color: #ebebeb;
  }
  &.active {
    background-color: #ebebeb;
  }
`

const MoreActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  z-index: 3;
  position: absolute;
  transform: translate(0em, 4em);
  padding: 0.3em;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: rgb(219 219 219) 0px 2px 5px;
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background-color: #ebebeb;
  }
`

const Divider = styled.div`
  border-top: 1px solid #ddd;
  margin: 0em 0.3em;
`

const ActionText = styled.div`
  margin: 0;
`
const ProfileLink = styled(Link)`
  color: ${({ theme: { secondary } }) => secondary};
  text-decoration: none;
`

const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 6%;
  cursor: pointer;
`

const CaretButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 7%;
  cursor: pointer;
`

const CaretButton = styled.div`
  font-size: 20px;
  cursor: pointer;
  padding: 0.1em 0.2em;
  border-radius: 3px;
  &:hover {
    background-color: #ebebeb;
  }
`

const ActionIcon = styled.img`
  height: 1.2em;
`

const Warning = styled.img`
  height: 1.5em;
`

const Caret = styled.img`
  height: 0.5em;
  transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : '')};
`

const ExpandedInfo = styled.div`
  display: flex;
  margin: 0.5em 0em 1em 3em;
  background-color: ${(props) =>
    props.conflicts
      ? props.theme.primaryBackground
      : props.theme.pageBackground};
  box-shadow: ${({ conflicts }) =>
    conflicts ? 'rgb(219 219 219) 0px 2px 5px' : ''};
  border-radius: 6px;
`

const NoConflicts = styled.div`
  margin-left: 1em;
  margin-bottom: 0.5em;
`

const Conflict = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3em;
  padding: 0em 1em;
`

const Description = styled.div`
  width: 75%;
  padding-left: 0.5em;
`

const CaseLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
`

const CaseLink = styled(Link)`
  color: ${({ theme: { secondary } }) => secondary};
  text-decoration: none;
  padding: 0.3em;
  border-radius: 3px;
  &:hover {
    background-color: #ebebeb;
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5%;
`
const Icon = styled.img`
  height: 1.5em;
`

const mapDispatchToProps = { removeProjectUser }

export default connect(null, mapDispatchToProps)(ProjectUserList)

