import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { selectCase } from '../../redux/actions'
import { getSelectedCase } from '../../redux/selectors'
import styled from 'styled-components'
import ConnectionForm from '../ConnectionForm'
import IndirectOwnershipList from './IndirectOwnershipList'
import SelectedCase from './SelectedCase'
import I18n from '../I18n'
import infoIcon from '../../icons/info.svg'
import {
  RELATION,
  USER_OWNERSHIP,
  PUBLIC_DATA_OWNERSHIP,
  RELATION_OWNERSHIP,
  RELATION_PUBLIC_DATA_OWNERSHIP,
  USER_ROLE,
  PUBLIC_DATA_ROLE,
  RELATION_ROLE,
  RELATION_PUBLIC_DATA_ROLE,
  USER_OTHER_CONNECTION,
  RELATION_OTHER_CONNECTION,
} from '../../connectionTypes'

const ConnectionModal = ({
  activeConnection,
  unsetActiveConnection,
  userId,
  item,
  isCurrentUsersProfile,
  Case, 
  setShowConnectionModal,
  selectCase,
  connectionType
} ) => {

  useEffect(() => { if (!activeConnection && !Case){
    setShowConnectionModal(false)
    }
  }, [activeConnection])

  const onClose = () => {
    setShowConnectionModal(false)
    unsetActiveConnection()
    selectCase(null)
  }

  const isRelationOrOther = [RELATION,USER_OTHER_CONNECTION, RELATION_OTHER_CONNECTION].includes(connectionType)

  // TODO: add custom text for each type, with guide on how a user should register direct and indirect.
  //console.log('Connection type: ', connectionType, '\n') // will remove after all custom texts are added
  const renderInfoText = () => {
    if (connectionType == USER_OWNERSHIP) {
      return <I18n id={`connection_form.tabs.ownership.renderInfo`}/>
    } else if (connectionType == USER_ROLE) {
      return <I18n id={`connection_form.tabs.role.renderInfo`}/>
    } else if (connectionType == RELATION) {
      return <I18n id={`connection_form.tabs.relation.renderInfo`}/>
    } else if (connectionType == USER_OTHER_CONNECTION) {
      return <I18n id={`connection_form.tabs.other.renderInfo`}/>
    }
  }

  return (
  <Overlay onClick={onClose}>
    {isRelationOrOther || !Case ?
    <ContentWrapper className={'narrow'} onClick={(e) => e.stopPropagation()}>
      <BackButton 
        onClick={onClose}
        >
        <I18n id="Close"/>
      </BackButton>
      <Rows>
      <TopInfoBox>
        <IconWrapper>
          <Icon src={infoIcon} alt="" />
        </IconWrapper>
        {connectionType && 
          <InfoText>{renderInfoText()}</InfoText>
        }
      </TopInfoBox>
      {Case &&
        <TitleWrapperBox>
          <SelectedCase
            Case={Case}
            activeConnection={activeConnection}
            unsetActiveConnection={unsetActiveConnection}
            connectionType={connectionType}
          />
        </TitleWrapperBox>
      }
      <FormWrapperBox>
            {activeConnection &&
              <ConnectionForm
                key={activeConnection.id}
                id={activeConnection.id}
                caseId={activeConnection.caseId}
                connectionType={activeConnection.connectionType}
                userId={userId}
                unsetActiveConnection={unsetActiveConnection}
                connection={activeConnection}
                item={item}
                isCurrentUsersProfile={isCurrentUsersProfile}
                selectCase={selectCase}
              />
            }
            </FormWrapperBox>
      </Rows>
    </ContentWrapper>  
    :
    <ContentWrapper className={'wide'} onClick={(e) => e.stopPropagation()}>
      <BackButton 
        onClick={onClose}
        >
        <I18n id="Close"/>
      </BackButton>
      <Rows>
        <TopInfoBox>
          <IconWrapper>
            <Icon src={infoIcon} alt="" />
          </IconWrapper>
          {connectionType && 
            <InfoText>{renderInfoText()}</InfoText>
          }
        </TopInfoBox>
        <OwnershipColumns>
          <OwnershipLeftColumn>
            <TitleWrapperBox>
            <SelectedCase
              Case={Case}
              activeConnection={activeConnection}
              unsetActiveConnection={unsetActiveConnection}
              connectionType={connectionType}
            />
            </TitleWrapperBox>
            <ListWrapperBox>
              <IndirectOwnershipList
                userId={userId}/>
            </ListWrapperBox>
          </OwnershipLeftColumn>
          <OwnershipRightColumn>
            <FormWrapperBox>
            {activeConnection &&
              <ConnectionForm
                key={activeConnection.id}
                id={activeConnection.id}
                caseId={activeConnection.caseId}
                connectionType={activeConnection.connectionType}
                userId={userId}
                unsetActiveConnection={unsetActiveConnection}
                connection={activeConnection}
                item={item}
                isCurrentUsersProfile={isCurrentUsersProfile}
              />
            }
            </FormWrapperBox>
          </OwnershipRightColumn>
        </OwnershipColumns>
      </Rows>
    </ContentWrapper>
    }
  </Overlay>
  )
}

const TopInfoBox = styled.div`
  display: flex;
  gap: 1em;
  height: 20%;
  width: 100%;
  padding: 1em;
  padding-top: 0em;
  border-radius: 6px;
  background-color: ${({ theme: { modalBackground } }) => modalBackground}; 
  overflow: auto;
`

const InfoText = styled.div`
`

const IconWrapper = styled.div`
  background-color: ${({ theme: { secondary } }) => secondary};
  border-radius: 2em;
  margin-top: 1em;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
`

const Icon = styled.img`
  margin: 4px;
  width: 24px;
  height: 24px;
`

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2em;
  gap: 1em;
`

const TitleWrapperBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30%;
  border-radius: 6px;
  background-color: ${({ theme: { modalBackground } }) => modalBackground}; 
`

const ListWrapperBox = styled.div`
  height: 70%;
  border-radius: 6px;
  background-color: ${({ theme: { modalBackground } }) => modalBackground}; 
`

const FormWrapperBox = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 6px;
  padding: 1em;
  background-color: ${({ theme: { modalBackground } }) => modalBackground}; 
`

const OwnershipColumns = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  height: 100%;
  gap: 1em;
`

const OwnershipLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 1em;
  width: 50%;
  height: 100%;
`

const OwnershipRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 1em;
  width: 50%;
  height: 100%;
`

const Overlay = styled.div`
  overflow: auto
  animation: none;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`

const BackButton = styled.button.attrs({
  type: 'button',
})`
  -webkit-appearance: initial !important;

  right: 2.5%;
  top: 1%;
  position: absolute;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  color: ${({ theme }) => theme.secondary};
  font-size: 1em;
  background-color: ${(props) => props.theme.primaryBackground};
  width: 1em;
  height: 1em;
`

const ContentWrapper = styled.div`
  position: relative;
  overflow: auto
  background-color: ${(props) => props.theme.primaryBackground};
  margin: 0 auto;
  height: 95%;
  min-width: 90%;
  max-width: 90%;
  -moz-box-shadow: 0px 0px 18px 6px #ccc;
  -webkit-box-shadow: 0px 0px 18px 6px #ccc;
  box-shadow: 0px 0px 10px 0px #ccc;
  display: flex;
  flex-direction: column;

  &.wide {
    height: 95%;
    min-width: 90%;
    max-width: 90%;
  }

  &.narrow {
    height: 95%;
    min-width: 1000px;
    max-width: 1000px;
  }
`

const getConnectionType = (Case) => {
  if (Case.relationType){
    return RELATION
  } else if (Case.userOwnership != null) {
    return USER_OWNERSHIP
  } else if (Case.userPublicDataOwnership != null) {
    return PUBLIC_DATA_OWNERSHIP
  } else if (Case.relationOwnership != null) {
    return RELATION_OWNERSHIP
  } else if (Case.relationPublicDataOwnership != null) {
    return RELATION_PUBLIC_DATA_OWNERSHIP
  } else if (Case.userRole != null) {
    return USER_ROLE
  } else if (Case.userPublicDataRole != null) {
    return PUBLIC_DATA_ROLE
  } else if (Case.relationRole != null) {
    return RELATION_ROLE
  } else if (Case.relationPublicDataRole != null) {
    return RELATION_PUBLIC_DATA_ROLE
  } else if (Case.userOtherConnection != null) {
    return USER_OTHER_CONNECTION
  } else if (Case.relationOtherConnection != null) {
    return RELATION_OTHER_CONNECTION
  }
}

const mapDispatchToProps = {
  selectCase
}

const mapStateToProps = (state, props) => {
  const Case = getSelectedCase(state)
  const connectionType = Case ? getConnectionType(Case) : props.activeConnection?.connectionType

  return {
    Case: Case,
    connectionType: connectionType
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionModal)
