import React from 'react'
import { prettyDate } from '../../dateUtils'
import ListItem from './ListItem'
import ownershipIcon from '../../icons/chartLine.svg'
import { useI18nFunction } from '../I18n'
import {
  USER_OWNERSHIP,
  PUBLIC_DATA_OWNERSHIP,
  RELATION_OWNERSHIP,
  RELATION_PUBLIC_DATA_OWNERSHIP,
} from '../../connectionTypes'

const OwnershipListItem = (props) => {
  const I18nFunction = useI18nFunction();
  const renderDescription = () => {
    const ownershipData =
      props.item?.userOwnership || props.item?.userPublicDataOwnership
    if (ownershipData != null) {
      return I18nFunction( { 
        id:'connection_list_item.ownership_description',
        stockCount: ownershipData.stockCount,
        shareType: ownershipData.shareType,
        stakePercentage: parseFloat(ownershipData.stakePercentage).toFixed(2)
      })
    } else {
      return I18nFunction( { id: 'connection_list_item.ownership_description_fallback' } )
    }
  }

  const connectionType = () => {
    if (props.item?.userOwnership != null) {
      return USER_OWNERSHIP
    } else if (props.item?.userPublicDataOwnership != null) {
      return PUBLIC_DATA_OWNERSHIP
    } else if (props.item?.relationOwnership != null) {
      return RELATION_OWNERSHIP
    } else if (props.item?.relationPublicDataOwnership != null) {
      return RELATION_PUBLIC_DATA_OWNERSHIP
    }
  }

  const itemIsApplication = () => {
    return (
      !props.item?.userOwnership &&
      !props.item?.userPublicDataOwnership &&
      !props.item?.relationOwnership &&
      !props.item?.relationPublicDataOwnership
    )
  }

  return (
    <li>
      <ListItem
        {...props}
        checked={
          (props.item?.userOwnership != null ||
          props.item?.relationOwnership ||
          props.item?.ownershipApplications?.length > 0) &&
          props.item?.allIndirectCasesConfirmed
        }
        image={ownershipIcon}
        title={props.item.organizationName}
        description={renderDescription()}
        from={prettyDate(props.item?.updatedOn)}
        connectionType={connectionType()}
        itemIsApplication={itemIsApplication()}
        Case={props.item}
      />
    </li>
  )
}

export default OwnershipListItem
