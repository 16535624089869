import React, { useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import I18n, { useI18nFunction } from './I18n'
import { statusColor, StatusBadge } from '../CaseStatus'
import {
  USER_OWNERSHIP_CASE,
  USER_INDIRECT_OWNERSHIP_CASE,
  RELATION_OWNERSHIP_CASE,
  RELATION_INDIRECT_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  USER_INDIRECT_ROLE_CASE,
  RELATION_ROLE_CASE,
  RELATION_INDIRECT_ROLE_CASE,
  USER_OTHER_CONNECTION_CASE,
  RELATION_OTHER_CONNECTION_CASE,
} from '../caseTypes'
import { camelize } from 'humps'
import caseProps from './caseProps'
import { fetchRelation } from '../redux/actions'
import {
  getOwnershipStockCount,
  getOwnershipShareType,
  getOwnershipStakePercentage,
  getOtherConnectionDescription,
} from './caseProps'
import CasePriorityWarningMarker from './CaseListItem/CasePriorityWarningMarker'

const MultiApproveCasesListItem = ({
  Item,
  userName,
  relation,
  isRelationCase,
  fetchRelation,
  Case,
}) => {

  const I18nFunction = useI18nFunction();


  useEffect(() => {
    Item.relationId && fetchRelation(Item.relationId, Item.userId)
  }, [fetchRelation, Item.relationId])

  const showRiskWarning =
    Item.positionOfInfluence ||
    Item.organizationIsCustomerForUserCompany ||
    Item.organizationIsSupplierForUserCompany ||
    Item.isClient ||
    Item.isSupplier ||
    Item.isBoardMember
  return (
    <Wrapper statusColor={statusColor(Item.status)}>

      <CaseRisk>
        {showRiskWarning && (
          <CasePriorityWarningMarker Item={Item.caseId ? Case : Item} />
        )}
      </CaseRisk>

      <IconWrapper>
        <Icon
          src={
            Item.caseId
              ? Case?.caseType
                ? caseProps[camelize(Case?.caseType)].image
                : undefined
              : Item?.caseType
              ? caseProps[camelize(Item?.caseType)].image
              : undefined
          }
          alt=""
        />
      </IconWrapper>
      <CaseSummary>
          {isRelationCase && (
            <RelationOfUser>
              <I18n id="Relation" /> <I18n id="of" /> {userName}
            </RelationOfUser>
          )}
          {isRelationCase
            ? relation.firstName + ' ' + relation.lastName
            : userName}{' '}
          {Item.caseId
            ? `${I18nFunction({ id: 'Application' })} ${I18nFunction({ id: 'about' })} ${applicationDescription(Case, Item)} ${I18nFunction({ id: 'in' })} `
            : connectionDescription(Item, I18nFunction)}{' '}
          {Item.organizationName}
      </CaseSummary>
      <CaseStatus>
        <StatusBadge status={Item.status} />
      </CaseStatus>
    </Wrapper>
  )
}

const connectionDescription = (Item, I18nFunction) => {
  if (!Item?.caseType) return ''
  switch (camelize(Item?.caseType)) {
    case USER_OWNERSHIP_CASE:
    case RELATION_OWNERSHIP_CASE:
      return `${I18nFunction({ 
        id: 'connection_list_item.ownership_description', 
        stockCount: getOwnershipStockCount(Item),
        shareType: getOwnershipShareType(Item),
        stakePercentage: getOwnershipStakePercentage(Item)
      })} ${I18nFunction({ id: 'in' })} `;
    case USER_INDIRECT_OWNERSHIP_CASE:
    case RELATION_INDIRECT_OWNERSHIP_CASE:
      return `${I18nFunction({ 
        id: 'connection_list_item.ownership_description', 
        stockCount: '',
        shareType: 'indirect_ownership',
        stakePercentage: getOwnershipStakePercentage(Item)
      })} ${I18nFunction({ id: 'in' })} `;
    case USER_ROLE_CASE:
    case RELATION_ROLE_CASE:
      return `${I18nFunction({ id: 'is' })} ${Item.caseSubType} ${I18nFunction({ id: 'in' })}`;
    case USER_INDIRECT_ROLE_CASE:
    case RELATION_INDIRECT_ROLE_CASE:
      return `${I18nFunction({ 
        id: 'connection_list_item.ownership_description', 
        stockCount: '',
        shareType: 'indirect_role',
        stakePercentage: ''
      })} ${I18nFunction({ id: 'to' })} `;
    case USER_OTHER_CONNECTION_CASE:
    case RELATION_OTHER_CONNECTION_CASE:
      return `${I18nFunction({ id: 'is' })} ${getOtherConnectionDescription(Item)} ${I18nFunction({ id: 'in' })}`;
    default:
      return ''
  }
}

const applicationDescription = (Case, application) => {
  if (!Case?.caseType) return ''
  switch (camelize(Case?.caseType)) {
    case USER_OWNERSHIP_CASE:
    case RELATION_OWNERSHIP_CASE:
      return `${application.shareType}`
    case USER_ROLE_CASE:
    case RELATION_ROLE_CASE:
      return `${application.title}`
    default:
      return ''
  }
}

const RelationOfUser = styled.div`
  color: #aaa;
  top: 0.5em;
`

const Wrapper = styled.div`
  border-radius: 5px;
  position: relative;
  width: 100%;
  gap: 2%;

  color: ${({ theme: { text } }) => text};
  display: flex;
  align-items: center;
  padding: 1em 1.5em;
  margin-left: 1em;
  margin-right: 1em;
  background-color: ${(props) =>
    props.secondary
      ? props.theme.secondaryBackground
      : props.theme.primaryBackground};

  `


const Icon = styled.img`
  height: 2em;
  width: 2em;
`
const IconWrapper = styled.div`
  width: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CaseRisk = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: calc(100% + 1em);
  z-index: 4;
`

const CaseSummary = styled.div`     
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 5%;
`

const CaseStatus = styled.div`
  border-left: ${({ statusColor }) => `12px solid ${statusColor}`};
  width: 25%;

`


const mapDispatchToProps = { fetchRelation}

const mapStateToProps = (state, props) => {
  const users = state.users.byId || {}
  const relation = state.relations.byId[props.Item.relationId] || {}
  const cases = state.cases.byId || {}
  return {
    userName: users[props.Item.userId] && users[props.Item.userId].name,
    positionOfInfluence:
      users[props.Item.userId] && users[props.Item.userId].positionOfInfluence,
    relation,
    isRelationCase: props.Item.relationId ? true : false,
    Case: cases[props.Item.caseId],
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiApproveCasesListItem)
