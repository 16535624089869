export { default as sagas } from './sagas'

export const ADD_CONNECTION = 'ADD_CONNECTION'
export const APPLY_CONNECTION = 'APPLY_CONNECTION'

export const CHANGE_CONNECTION = 'CHANGE_CONNECTION'

export const CONFIRM_CONNECTION = 'CONFIRM_CONNECTION'
export const CONFIRM_CONNECTION_SUCCEEDED = 'CONFIRM_CONNECTION_SUCCEEDED'
export const CONFIRM_CONNECTION_FAILED = 'CONFIRM_CONNECTION_FAILED'

export const CREATE_CONNECTION = 'CREATE_CONNECTION'
export const CREATE_CONNECTION_SUCCEEDED = 'CREATE_CONNECTION_SUCCEEDED'
export const CREATE_CONNECTION_FAILED = 'CREATE_CONNECTION_FAILED'

export const CREATE_CONNECTION_APPLICATION = 'CREATE_CONNECTION_APPLICATION'
export const CREATE_CONNECTION_APPLICATION_SUCCEEDED =
  'CREATE_CONNECTION_APPLICATION_SUCCEEDED'
export const CREATE_CONNECTION_APPLICATION_FAILED =
  'CREATE_CONNECTION_APPLICATION_FAILED'

export const UPDATE_CONNECTION = 'UPDATE_CONNECTION'
export const UPDATE_CONNECTION_SUCCEEDED = 'UPDATE_CONNECTION_SUCCEEDED'
export const UPDATE_CONNECTION_FAILED = 'UPDATE_CONNECTION_FAILED'

export const REMOVE_CONNECTION = 'REMOVE_CONNECTION'
export const REMOVE_CONNECTION_SUCCEEDED = 'REMOVE_CONNECTION_SUCCEEDED'
export const REMOVE_CONNECTION_FAILED = 'REMOVE_CONNECTION_FAILED'

export const MASS_CONFIRM_INDIRECT_CONNECTIONS = 'MASS_CONFIRM_INDIRECT_CONNECTIONS'
export const MASS_CONFIRM_INDIRECT_CONNECTIONS_SUCCEEDED = 'MASS_CONFIRM_INDIRECT_CONNECTIONS_SUCCEEDED'
export const MASS_CONFIRM_INDIRECT_CONNECTIONS_FAILED = 'MASS_CONFIRM_INDIRECT_CONNECTIONS_FAILED'

export const CREATE_INDIRECT_CONNECTIONS = 'CREATE_INDIRECT_CONNECTIONS'
export const CREATE_INDIRECT_CONNECTIONS_SUCCEEDED = 'CREATE_INDIRECT_CONNECTIONS_SUCCEEDED'
export const CREATE_INDIRECT_CONNECTIONS_FAILED = 'CREATE_INDIRECT_CONNECTIONS_FAILED'

export const INVALIDATE_RELATION_PUBLIC_DATA = 'INVALIDATE_RELATION_PUBLIC_DATA'

export const addConnection = ({ id, connectionType, userId, relationId, ownershipType, directConnectionCaseId }) => ({
  type: ADD_CONNECTION,
  id,
  connectionType,
  ownershipType,
  directConnectionCaseId,
  userId,
  relationId,
})

export const applyConnection = ({
  id,
  connectionType,
  ownershipType,
  userId,
  prefilledProperties,
}) => ({
  type: APPLY_CONNECTION,
  id,
  connectionType,
  ownershipType,
  userId,
  prefilledProperties,
})

export const createConnectionApplication = ({
  userId,
  id,
  connectionType,
  caseId,
}) => ({
  type: CREATE_CONNECTION_APPLICATION,
  userId,
  id,
  connectionType,
  caseId,
})

export const changeConnection = ({ userId, id, connectionType, props }) => ({
  type: CHANGE_CONNECTION,
  userId,
  id,
  connectionType,
  props,
})

export const confirmConnection = ({ userId, id, connectionType, ownershipType }) => ({
  type: CONFIRM_CONNECTION,
  userId,
  id,
  connectionType,
  ownershipType,
})

export const removeConnection = ({ userId, id, connectionType, caseId }) => ({
  type: REMOVE_CONNECTION,
  userId,
  id,
  connectionType,
  caseId,
})

export const massConfirmIndirectConnections = ( userId, relationId, caseId, connectionType, selectedCaseIds ) => ({
  type: MASS_CONFIRM_INDIRECT_CONNECTIONS,
  userId,
  relationId,
  caseId,
  connectionType,
  selectedCaseIds,
})

export const createIndirectConnections = ({ userId, caseId }) => ({
  type: CREATE_INDIRECT_CONNECTIONS,
  userId,
  caseId
})