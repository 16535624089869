import React from 'react'
import styled, { css } from 'styled-components'
import Spinner from './SmallSpinner'
import Link from './Link'

const inactiveStyle = css`
  color: ${({ theme: { subtleText } }) => subtleText};
  background-color: ${({ textOnly, theme: { secondaryBackground } }) =>
    textOnly ? "transparent" : secondaryBackground};
  cursor: initial;
`

const getBackgroundColor = (theme, secondary, textOnly, deleteButton ) => {
  if (secondary) {
    return theme.secondaryBackground
  }
  if (deleteButton) { 
    return theme.delete
  }
  if (textOnly) { 
    return "transparent"
  }
  return theme.primary
}

const StyledButton = styled.button.attrs({
  type: 'button',
})`
  -webkit-appearance: initial !important;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  padding: ${({ small }) => (small ? '0.75em' : '1em')};
  font-size: ${({ small, textOnly, theme: { normalText, largeText } }) =>
    small || textOnly ? normalText : largeText};
  color: ${({ textOnly, theme: { secondary, inverseText } }) =>
    textOnly ? secondary : inverseText};
  background-color: ${({ secondary, theme, textOnly, deleteButton }) =>
    getBackgroundColor(theme, secondary, textOnly, deleteButton)};

  & > :not(:last-child) {
    margin-right: 0.25em;
  }

  ${({ inactive }) => inactive && inactiveStyle};

  &:disabled {
    cursor: initial;
  }
`

const Button = (props) => {
  const Component = props.subtle ? Link : StyledButton
  return (
    <Component
      to=""
      as="button"
      {...props}
      type={props.type || 'button'}
      disabled={
        props.pending ||
        !(props.onClick || props.to || props.href || props.type === 'submit') ||
        props.disabled
      }
      inactive={
        props.pending ||
        !(props.onClick || props.to || props.href || props.type === 'submit') ||
        props.inactive
      }
    >
      {props.pending ? (
        <Spinner color={props.subtle && 'props.subtle'} />
      ) : null}
      {props.children}
    </Component>
  )
}

export default Button
