import React from 'react'
import styled from 'styled-components'
import I18n from '../I18n'
import infoIcon from '../../icons/info.svg'

const CasePriorityWarningMarker = ({ Item, withTooltip, color }) => {
  
  const createTooltip = () => {
    return (
      <Tooltip>
        <I18n id='case_risk_title' />
        <ul>
          {Item?.positionOfInfluence && (
            <li><I18n id='case_risk_categories.position_of_influence' /></li>
          )}
          {Item?.isBoardMember && (
            <li><I18n id='has' /> <I18n id='BoardRole' /></li>
          )}
          {Item?.organizationIsSupplierForUserCompany && (
            <li><I18n id='case_risk_categories.organization_is_supplier' /></li>
          )}
          {Item?.organizationIsCustomerForUserCompany && (
            <li><I18n id='case_risk_categories.organization_is_client' /></li>
          )}
        </ul>
      </Tooltip>
    )
  }

  return (
    <Wrapper>
      <MarkerWrapper>
        <WarningIcon src={infoIcon}></WarningIcon>
        {createTooltip()}
      </MarkerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const MarkerWrapper = styled.div`
  display: inline-block;
  height: 1em;
  width: 1em;
  border-radius: 10px;
  margin: 2px;
`

const WarningIcon = styled.img`
  height: 1em;
  width: 1em;
  margin: 0 0em;
  filter: invert(42%) sepia(89%) saturate(979%) hue-rotate(0deg)
    brightness(104%) contrast(105%);
`

const Tooltip = styled.div`
  z-index: 5;
  display: none;
  position: absolute;
  margin: 0;
  padding: 1em;
  margin-left: 1em;
  width: 200px;
  height: auto;
  background-color: ${({ theme: { primary } }) => primary};
  color: ${({ theme: { inverseText } }) => inverseText};
  border-radius: 5px;
  border: 1px black solid;
  ${MarkerWrapper}:hover & {
    display: block;
  }
`

export default CasePriorityWarningMarker
