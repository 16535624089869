import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import ProffSelector from './ProffSelector'
import {
  getActiveProffPerson,
  getProffPersonSuggestions,
  getRelation,
  hasConfirmedProffSelection,
  isProffSuggestionsFetching,
} from '../../redux/selectors'

import {
  setRelationProffPersonId,
  setActiveProffPerson,
  unsetActiveProffPerson,
  fetchRelationProffPersonSuggestions,
} from '../../redux/actions'

const ProffSelectorModal = ({
  activeProffPerson,
  fetchRelationProffPersonSuggestions,
  proffSuggestions,
  isProffSuggestionsFetching,
  setRelationProffPersonId,
  setActiveProffPerson,
  unsetActiveProffPerson,
  onClose,
  relationId,
  relation,
}) => {
  useEffect(() => {
    !isProffSuggestionsFetching &&
      relationId &&
      fetchRelationProffPersonSuggestions(relationId)
  }, [fetchRelationProffPersonSuggestions])
  return (
        <ProffSelector
          activeProffPerson={activeProffPerson}
          currentUser={relation}
          proffSuggestions={proffSuggestions}
          setProffPersonId={setRelationProffPersonId}
          setActiveProffPerson={setActiveProffPerson}
          unsetActiveProffPerson={unsetActiveProffPerson}
          personType={'relations'}
          onClose={onClose}
          isProffSuggestionsFetching={isProffSuggestionsFetching}
        />
  )
}

const mapStateToProps = (state, props) => {
  return {
    activeProffPerson: getActiveProffPerson(state),
    proffSuggestions: getProffPersonSuggestions(state),
    isProffSuggestionsFetching: isProffSuggestionsFetching(state),
    hasConfirmedProffSelection: hasConfirmedProffSelection(state),
    relation: getRelation(state, props.relationId) || {},
  }
}

const mapDispatchToProps = {
  fetchRelationProffPersonSuggestions,
  setRelationProffPersonId,
  setActiveProffPerson,
  unsetActiveProffPerson,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProffSelectorModal)
