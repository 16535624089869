import { call, put, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  FETCH_PROJECT,
  FETCH_PROJECT_SUCCEEDED,
  FETCH_PROJECT_FAILED,
  FETCH_PROJECTS,
  FETCH_PROJECTS_SUCCEEDED,
  FETCH_PROJECTS_FAILED,
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCEEDED,
  CREATE_PROJECT_FAILED,
  UPDATE_PROJECT_INFO,
  UPDATE_PROJECT_INFO_SUCCEEDED,
  UPDATE_PROJECT_INFO_FAILED,
  ADD_PROJECT_USER,
  ADD_PROJECT_USER_SUCCEEDED,
  ADD_PROJECT_USER_FAILED,
  ADD_PROJECT_COMPANY,
  ADD_PROJECT_COMPANY_SUCCEEDED,
  ADD_PROJECT_COMPANY_FAILED,
  REMOVE_PROJECT_USER,
  REMOVE_PROJECT_USER_SUCCEEDED,
  REMOVE_PROJECT_USER_FAILED,
  REMOVE_PROJECT_COMPANY,
  REMOVE_PROJECT_COMPANY_SUCCEEDED,
  REMOVE_PROJECT_COMPANY_FAILED,
  COMMENT_ON_PROJECT,
  COMMENT_ON_PROJECT_SUCCEEDED,
  COMMENT_ON_PROJECT_FAILED,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCEEDED,
  DELETE_PROJECT_FAILED,
} from './index'
import * as schema from '../schema'
import { fetchSaga, fetchSagaPagination } from '../sagas'
import { get, post, remove, removeFromProject } from '../fetch'

function* goToProjects() {
  // yield put({ type: FETCH_ME })
  yield put(push('/projects'))
}

function* deleteProject({ projectId }) {
  try {
    const { errors } = yield call(() =>
      remove(`/projects/${encodeURIComponent(projectId)}`)
    )
    if (errors) {
      yield put({
        type: DELETE_PROJECT_FAILED,
        projectId,
        errors,
      })
    } else {
      yield put({
        type: DELETE_PROJECT_SUCCEEDED,
        projectId,
      })
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: DELETE_PROJECT_FAILED,
      projectId,
      errors: [{ detail: error.message }],
    })
  }
}


function* saga() {
  yield takeEvery(
    FETCH_PROJECTS,
    fetchSagaPagination({
      SUCCEEDED: FETCH_PROJECTS_SUCCEEDED,
      FAILED: FETCH_PROJECTS_FAILED,
      schema: schema.arrayOfProjects,
      fetch: ({
        pagination: {
          query,
          filters,
          sortOption,
          pageIndex,
          perPage,
        } }) =>
        get(
          `/projects?query=${query}&filters=${filters}&sort=${sortOption}&pageIndex=${pageIndex}&perPage=${perPage}`,
        ),
    }),
  )
  yield takeEvery(
    FETCH_PROJECT,
    fetchSaga({
      SUCCEEDED: FETCH_PROJECT_SUCCEEDED,
      FAILED: FETCH_PROJECT_FAILED,
      schema: schema.project,
      fetch: ({ projectId }) =>
        get(`/projects/${encodeURIComponent(projectId)}`),
    }),
  )
  yield takeEvery(
    CREATE_PROJECT,
    fetchSaga({
      SUCCEEDED: CREATE_PROJECT_SUCCEEDED,
      FAILED: CREATE_PROJECT_FAILED,
      schema: schema.project,
      fetch: ({ title, description, project_status, project_assessment_status, custom_data }) =>
        post(`/projects`, {
          title: title,
          description: description,
          project_status: project_status,
          assessment_status: project_assessment_status,
          custom_data: custom_data,
        }),
    }),
  )
  yield takeEvery(
    UPDATE_PROJECT_INFO,
    fetchSaga({
      SUCCEEDED: UPDATE_PROJECT_INFO_SUCCEEDED,
      FAILED: UPDATE_PROJECT_INFO_FAILED,
      schema: schema.project,
      fetch: ({ projectId, title, description, project_status, project_assessment_status }) =>
        post(`/projects/${encodeURIComponent(projectId)}/update_info`, {
          title: title,
          description: description,
          project_status: project_status,
          assessment_status: project_assessment_status
        }),
    }),
  )
  yield takeEvery(
    ADD_PROJECT_USER,
    fetchSaga({
      SUCCEEDED: ADD_PROJECT_USER_SUCCEEDED,
      FAILED: ADD_PROJECT_USER_FAILED,
      schema: schema.project,
      fetch: ({ projectId, selectedProjectUsers }) =>
        post(`/projects/${encodeURIComponent(projectId)}/add_users`, {
          users: selectedProjectUsers
        }),
    }),
  )
  yield takeEvery(
    ADD_PROJECT_COMPANY,
    fetchSaga({
      SUCCEEDED: ADD_PROJECT_COMPANY_SUCCEEDED,
      FAILED: ADD_PROJECT_COMPANY_FAILED,
      schema: schema.project,
      fetch: ({ projectId, selectedProjectCompanies }) =>
        post(`/projects/${encodeURIComponent(projectId)}/add_companies`, {
          companies: selectedProjectCompanies
        }),
    }),
  )
  yield takeEvery(
    REMOVE_PROJECT_USER,
    fetchSaga({
      SUCCEEDED: REMOVE_PROJECT_USER_SUCCEEDED,
      FAILED: REMOVE_PROJECT_USER_FAILED,
      schema: schema.project,
      fetch: ({ projectId, userId }) =>
        removeFromProject(`/projects/${encodeURIComponent(projectId)}/user/${encodeURIComponent(userId)}`),
    }),
  )
  yield takeEvery(
    REMOVE_PROJECT_COMPANY,
    fetchSaga({
      SUCCEEDED: REMOVE_PROJECT_COMPANY_SUCCEEDED,
      FAILED: REMOVE_PROJECT_COMPANY_FAILED,
      schema: schema.project,
      fetch: ({ projectId, organizationNumber }) =>
        removeFromProject(`/projects/${encodeURIComponent(projectId)}/company/${encodeURIComponent(organizationNumber)}`),
    }),
  )
  yield takeEvery(
    COMMENT_ON_PROJECT,
    fetchSaga({
      SUCCEEDED: COMMENT_ON_PROJECT_SUCCEEDED,
      FAILED: COMMENT_ON_PROJECT_FAILED,
      schema: schema.project,
      fetch: ({ projectId, comment }) =>
        post(`/projects/${projectId}/comments`, { comment }),
    }),
  )
  yield takeEvery(DELETE_PROJECT, deleteProject)
  yield takeEvery(DELETE_PROJECT_SUCCEEDED, goToProjects)
}

export default saga
