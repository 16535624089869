import { combineReducers } from 'redux'
import {
  RELATION,
  USER_OTHER_CONNECTION,
  USER_OWNERSHIP,
  USER_ROLE,
} from '../../connectionTypes'
import user, * as fromUser from './user'

export { default as sagas } from './sagas'

export const FETCH_ME = 'FETCH_ME'
export const FETCH_ME_SUCCEEDED = 'FETCH_ME_SUCCEEDED'
export const FETCH_ME_FAILED = 'FETCH_ME_FAILED'

export const COMPLETE_ONBOARDING = 'COMPLETE_ONBOARDING'
export const COMPLETE_ONBOARDING_SUCCEEDED = 'COMPLETE_ONBOARDING_SUCCEEDED'
export const COMPLETE_ONBOARDING_FAILED = 'COMPLETE_ONBOARDING_FAILED'

export const ACCEPTED_COOKIES = 'ACCEPTED_COOKIES'
export const ACCEPTED_COOKIES_SUCCEEDED = 'ACCEPTED_COOKIES_SUCCEEDED'
export const ACCEPTED_COOKIES_FAILED = 'ACCEPTED_COOKIES_FAILED'

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS'
export const FETCH_NOTIFICATIONS_SUCCEEDED = 'FETCH_NOTIFICATIONS_SUCCEEDED'
export const FETCH_NOTIFICATIONS_FAILED = 'FETCH_NOTIFICATIONS_FAILED'

export const SET_NOTIFICATIONS_PAGE = 'SET_NOTIFICATIONS_PAGE'
export const DEFAULT_NOTIFICATION_PAGE_INDEX = 1

export const CONFIRM_NO_CONNECTIONS = 'CONFIRM_NO_CONNECTIONS'
export const CONFIRM_NO_CONNECTIONS_SUCCEEDED =
  'CONFIRM_NO_CONNECTIONS_SUCCEEDED'
export const CONFIRM_NO_CONNECTIONS_FAILED = 'CONFIRM_NO_CONNECTIONS_FAILED'

export const CANCEL_CONFIRM_NO_CONNECTIONS = 'CANCEL_CONFIRM_NO_CONNECTIONS'
export const CANCEL_CONFIRM_NO_CONNECTIONS_SUCCEEDED =
  'CANCEL_CONFIRM_NO_CONNECTIONS_SUCCEEDED'
export const CANCEL_CONFIRM_NO_CONNECTIONS_FAILED =
  'CANCEL_CONFIRM_NO_CONNECTIONS_FAILED'

export const CONFIRM_FORM = 'CONFIRM_FORM'
export const CONFIRM_FORM_SUCCEEDED = 'CONFIRM_FORM_SUCCEEDED'
export const CONFIRM_FORM_FAILED = 'CONFIRM_FORM_FAILED'

export const SET_BIRTHDATE_AND_ADDRESS = 'SET_BIRTHDATE_AND_ADDRESS'
export const SET_BIRTHDATE_AND_ADDRESS_SUCCEEDED =
  'SET_BIRTHDATE_AND_ADDRESS_SUCCEEDED'
export const SET_BIRTHDATE_AND_ADDRESS_FAILED =
  'SET_BIRTHDATE_AND_ADDRESS_FAILED'

export const NOTIFICATIONS_STATUS_UPDATE = 'NOTIFICATIONS_STATUS_UPDATE'
export const NOTIFICATIONS_STATUS_UPDATE_SUCCEEDED =
  'NOTIFICATIONS_STATUS_UPDATE_SUCCEEDED'
export const NOTIFICATIONS_STATUS_UPDATE_FAILED =
  'NOTIFICATIONS_STATUS_UPDATE_FAILED'

export const fetchMe = () => ({
  type: FETCH_ME,
})

export const fetchNotifications = (notificationsPageIndex, onlyUnread) => ({
  type: FETCH_NOTIFICATIONS,
  notificationsPageIndex,
  onlyUnread,
})

export const setNotificationsPageIndex = (notificationsPageIndex) => ({
  type: SET_NOTIFICATIONS_PAGE,
  notificationsPageIndex,
})

export const completeOnboarding = () => ({
  type: COMPLETE_ONBOARDING,
})

export const acceptCookies = () => ({
  type: ACCEPTED_COOKIES,
})

export const confirmNoConnections = (connectionType) => ({
  type: CONFIRM_NO_CONNECTIONS,
  connectionType,
})

export const updateNotificationsStatus = ({ ids, read, seen }) => ({
  type: NOTIFICATIONS_STATUS_UPDATE,
  ids,
  read,
  seen,
})

export const cancelConfirmNoConnections = (connectionType) => ({
  type: CANCEL_CONFIRM_NO_CONNECTIONS,
  connectionType,
})

export const confirmForm = () => ({
  type: CONFIRM_FORM,
})

export const setBirthdateAndAddress = ({
  birthdate,
  address,
  postCode,
  postPlace,
}) => ({
  type: SET_BIRTHDATE_AND_ADDRESS,
  birthdate,
  address,
  postCode,
  postPlace,
})

const completeOnboardingPending = (state = false, action) => {
  switch (action.type) {
    case COMPLETE_ONBOARDING:
      return true

    case COMPLETE_ONBOARDING_SUCCEEDED:
    case COMPLETE_ONBOARDING_FAILED:
      return false

    default:
      return state
  }
}

const setBirthdateAndAddressPending = (state = false, action) => {
  switch (action.type) {
    case SET_BIRTHDATE_AND_ADDRESS:
      return true

    case SET_BIRTHDATE_AND_ADDRESS_SUCCEEDED:
    case SET_BIRTHDATE_AND_ADDRESS_FAILED:
      return false

    default:
      return state
  }
}

const confirmFormPending = (state = false, action) => {
  switch (action.type) {
    case CONFIRM_FORM:
      return true

    case CONFIRM_FORM_SUCCEEDED:
    case CONFIRM_FORM_FAILED:
      return false

    default:
      return state
  }
}

const notifications = (state = {}, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return state
    case FETCH_NOTIFICATIONS_SUCCEEDED:
    case NOTIFICATIONS_STATUS_UPDATE_SUCCEEDED: {
      const newNotifications = action.payload.result.notification.reduce(
        (acc, notification) => {
          acc[notification.id] = notification
          return acc
        },
        {},
      )

      return { ...state, ...newNotifications }
    }

    default:
      return state
  }
}

const notificationsPageIndex = (state = DEFAULT_NOTIFICATION_PAGE_INDEX, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS_PAGE:
      return action.notificationsPageIndex
    default:
      return state
  }
}
const notificationsLoading = (state = true, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return true
    case FETCH_NOTIFICATIONS_SUCCEEDED:
    case FETCH_NOTIFICATIONS_FAILED:
      return false

    default:
      return state
  }
}

const notificationPagination = (state = {}, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_SUCCEEDED:
      return { ...action.payload.result.pagination }
    default:
      return state
  }
}

const confirmPendingReducer =
  (connectionType) =>
  (state = false, action) => {
    if (action.connectionType !== connectionType) {
      return state
    }

    switch (action.type) {
      case CONFIRM_NO_CONNECTIONS:
        return true

      case CONFIRM_NO_CONNECTIONS_SUCCEEDED:
      case CONFIRM_NO_CONNECTIONS_FAILED:
        return false

      default:
        return state
    }
  }

const confirmNoConnectionsPending = combineReducers(
  [USER_ROLE, USER_OWNERSHIP, USER_OTHER_CONNECTION, RELATION].reduce(
    (reducers, connectionType) => ({
      ...reducers,
      [connectionType]: confirmPendingReducer(connectionType),
    }),
    {},
  ),
)

const cancelPendingReducer =
  (connectionType) =>
  (state = false, action) => {
    if (action.connectionType !== connectionType) {
      return state
    }

    switch (action.type) {
      case CANCEL_CONFIRM_NO_CONNECTIONS:
        return true

      case CANCEL_CONFIRM_NO_CONNECTIONS_SUCCEEDED:
      case CANCEL_CONFIRM_NO_CONNECTIONS_FAILED:
        return false

      default:
        return state
    }
  }

const cancelConfirmNoConnectionsPending = combineReducers(
  [USER_ROLE, USER_OWNERSHIP, USER_OTHER_CONNECTION, RELATION].reduce(
    (reducers, connectionType) => ({
      ...reducers,
      [connectionType]: cancelPendingReducer(connectionType),
    }),
    {},
  ),
)

export default combineReducers({
  user,
  completeOnboardingPending,
  setBirthdateAndAddressPending,
  confirmNoConnectionsPending,
  cancelConfirmNoConnectionsPending,
  confirmFormPending,
  notifications,
  notificationsPageIndex,
  notificationPagination,
  notificationsLoading,
})

export const get = (state) => fromUser.get(state.user)
export const isProjectAdmin = (state) => fromUser.isProjectAdmin(state.user)
export const isManager = (state) => fromUser.isManager(state.user)
export const getId = (state) => fromUser.getId(state.user)
export const isCurrentUserFetching = (state) => fromUser.isFetching(state.user)

export const hasCompletedOnboarding = (state) => {
  const hasConfirmedTermsAndConditions =
    fromUser.hasConfirmedTermsAndConditions(state.user)

  const hasConfirmedProffSelection = fromUser.hasConfirmedProffSelection(
    state.user,
  )
  const hasBirthdateAndAddress =
    state.user.birthdate &&
    state.user.address &&
    state.user.postCode &&
    state.user.postPlace
  return (
    hasConfirmedTermsAndConditions &&
    hasBirthdateAndAddress &&
    hasConfirmedProffSelection
  )
}

export const hasConfirmedBirthdateAndAddress = (state) =>
  fromUser.hasConfirmedBirthdateAndAddress(state.user)

export const hasConfirmedProffSelection = (state) =>
  fromUser.hasConfirmedProffSelection(state.user)

export const hasAcceptedCookies = (state) =>
  fromUser.hasAcceptedCookies(state?.user)

export const hasConfirmedTermsAndConditions = (state) =>
  fromUser.hasConfirmedTermsAndConditions(state.user)

export const hasConfirmedNoConnections = (state, connectionType) =>
  fromUser.hasConfirmedNoConnections(state.user, connectionType)

export const isCompleteOnboardingPending = (state) =>
  state.completeOnboardingPending

export const isSetBirthdateAndAddressPending = (state) =>
  state.setBirthdateAndAddressPending

export const isConfirmNoConnectionsPending = (state, connectionType) =>
  state.confirmNoConnectionsPending[connectionType]

export const isCancelConfirmNoConnectionsPending = (state, connectionType) =>
  state.cancelConfirmNoConnectionsPending[connectionType]

export const getNotifications = (state) => {
  return Object.values(state.notifications).sort((a, b) => {
    if (a.createdOn > b.createdOn) {
      return -1
    }
    if (a.createdOn < b.createdOn) {
      return 1
    }
    return 0
  })
}
export const getNotificationsPageIndex = (state) => {
  return state.notificationsPageIndex
}
export const getNotificationPagination = (state) => {
  return state.notificationPagination
}

export const getNotificationsLoading = (state) => {
  return state.notificationsLoading
}
