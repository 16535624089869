import React from 'react'
import { prettyDate } from '../../dateUtils'
import ListItem from './ListItem'
import roleIcon from '../../icons/role.svg'
import { USER_ROLE, PUBLIC_DATA_ROLE, RELATION_ROLE, RELATION_PUBLIC_DATA_ROLE } from '../../connectionTypes'

const RoleListItem = (props) => {

  const connectionType = () => {
    if (props.item?.userRole != null) {
      return USER_ROLE
    } else if (props.item?.userPublicDataRole != null) {
      return PUBLIC_DATA_ROLE
    }
    else if (props.item?.relationRole != null) {
      return RELATION_ROLE
    }
    else if (props.item?.relationPublicDataRole != null) {
      return RELATION_PUBLIC_DATA_ROLE
    }
  }

  const itemIsApplication = () => {
    return (!props.item?.userRole && !props.item?.userPublicDataRole) &&
    (!props.item?.relationRole && !props.item?.relationPublicDataRole)
  }

  return (
    <li>
      <ListItem
        {...props}
        checked={(props.item?.userRole != null || props.item?.relationRole != null || props.item?.roleApplications?.length > 0) && props.item?.allIndirectCasesConfirmed}
        image={roleIcon}
        title={props.organizationName}
        description={props.item?.userRole?.title || props.item?.userPublicDataRole?.title}
        from={prettyDate(props.item?.updatedOn)}
        connectionType={connectionType()}
        itemIsApplication={itemIsApplication()}
        Case={props.item}
      />
    </li>
  )
}

export default RoleListItem
