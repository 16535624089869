export const USER_OWNERSHIP_CASE = 'userOwnershipCase'
export const USER_INDIRECT_OWNERSHIP_CASE = 'userIndirectOwnershipCase'
export const USER_ROLE_CASE = 'userRoleCase'
export const USER_INDIRECT_ROLE_CASE = 'userIndirectRoleCase'
export const USER_OTHER_CONNECTION_CASE = 'userOtherConnectionCase'
export const RELATION_CASE = 'relationCase'
export const RELATION_OWNERSHIP_CASE = 'relationOwnershipCase'
export const RELATION_INDIRECT_OWNERSHIP_CASE = 'relationIndirectOwnershipCase'
export const RELATION_ROLE_CASE = 'relationRoleCase'
export const RELATION_INDIRECT_ROLE_CASE = 'relationIndirectRoleCase'
export const RELATION_OTHER_CONNECTION_CASE = 'relationOtherConnectionCase'