import { put, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  FETCH_ME,
  FETCH_ME_SUCCEEDED,
  FETCH_ME_FAILED,
  COMPLETE_ONBOARDING,
  COMPLETE_ONBOARDING_SUCCEEDED,
  COMPLETE_ONBOARDING_FAILED,
  ACCEPTED_COOKIES,
  ACCEPTED_COOKIES_SUCCEEDED,
  ACCEPTED_COOKIES_FAILED,
  CONFIRM_NO_CONNECTIONS,
  CONFIRM_NO_CONNECTIONS_SUCCEEDED,
  CONFIRM_NO_CONNECTIONS_FAILED,
  CANCEL_CONFIRM_NO_CONNECTIONS,
  CANCEL_CONFIRM_NO_CONNECTIONS_SUCCEEDED,
  CANCEL_CONFIRM_NO_CONNECTIONS_FAILED,
  CONFIRM_FORM,
  CONFIRM_FORM_SUCCEEDED,
  CONFIRM_FORM_FAILED,
  SET_BIRTHDATE_AND_ADDRESS,
  SET_BIRTHDATE_AND_ADDRESS_SUCCEEDED,
  SET_BIRTHDATE_AND_ADDRESS_FAILED,
  NOTIFICATIONS_STATUS_UPDATE,
  NOTIFICATIONS_STATUS_UPDATE_SUCCEEDED,
  NOTIFICATIONS_STATUS_UPDATE_FAILED,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCEEDED,
  FETCH_NOTIFICATIONS_FAILED,
} from './index'
import { objectOfNotifications, user } from '../schema'
import { fetchSaga } from '../sagas'
import { get, post } from '../fetch'
import {
  USER_ROLE,
  USER_OWNERSHIP,
  USER_OTHER_CONNECTION,
  RELATION,
} from '../../connectionTypes'

function* goToThanks() {
  yield put({ type: FETCH_ME })
  yield put(push('/thanks'))
}

function* saga() {
  yield takeEvery(CONFIRM_FORM_SUCCEEDED, goToThanks)
  yield takeEvery(
    CONFIRM_NO_CONNECTIONS,
    fetchSaga({
      SUCCEEDED: CONFIRM_NO_CONNECTIONS_SUCCEEDED,
      FAILED: CONFIRM_NO_CONNECTIONS_FAILED,
      schema: user,
      fetch: ({ connectionType }) => {
        if (connectionType === USER_ROLE) {
          return post(`/me/confirm_no_user_roles`)
        } else if (connectionType === USER_OWNERSHIP) {
          return post(`/me/confirm_no_user_ownerships`)
        } else if (connectionType === USER_OTHER_CONNECTION) {
          return post(`/me/confirm_no_user_other_connections`)
        } else if (connectionType === RELATION) {
          return post(`/me/confirm_no_relations`)
        }
      },
    }),
  )
  yield takeEvery(
    CANCEL_CONFIRM_NO_CONNECTIONS,
    fetchSaga({
      SUCCEEDED: CANCEL_CONFIRM_NO_CONNECTIONS_SUCCEEDED,
      FAILED: CANCEL_CONFIRM_NO_CONNECTIONS_FAILED,
      schema: user,
      fetch: ({ connectionType }) => {
        if (connectionType === USER_ROLE) {
          return post(`/me/cancel_confirm_no_user_roles`)
        } else if (connectionType === USER_OWNERSHIP) {
          return post(`/me/cancel_confirm_no_user_ownerships`)
        } else if (connectionType === USER_OTHER_CONNECTION) {
          return post(`/me/cancel_confirm_no_user_other_connections`)
        } else if (connectionType === RELATION) {
          return post(`/me/cancel_confirm_no_relations`)
        }
      },
    }),
  )
  yield takeEvery(
    COMPLETE_ONBOARDING,
    fetchSaga({
      SUCCEEDED: COMPLETE_ONBOARDING_SUCCEEDED,
      FAILED: COMPLETE_ONBOARDING_FAILED,
      schema: user,
      fetch: () => post('/me/complete_onboarding'),
    }),
  )
  yield takeEvery(
    ACCEPTED_COOKIES,
    fetchSaga({
      SUCCEEDED: ACCEPTED_COOKIES_SUCCEEDED,
      FAILED: ACCEPTED_COOKIES_FAILED,
      schema: user,
      fetch: () => post('/me/accepted_cookies'),
    }),
  )
  yield takeEvery(
    CONFIRM_FORM,
    fetchSaga({
      SUCCEEDED: CONFIRM_FORM_SUCCEEDED,
      FAILED: CONFIRM_FORM_FAILED,
      schema: user,
      fetch: () => post(`/me/confirm_form`),
    }),
  )
  yield takeEvery(
    SET_BIRTHDATE_AND_ADDRESS,
    fetchSaga({
      SUCCEEDED: SET_BIRTHDATE_AND_ADDRESS_SUCCEEDED,
      FAILED: SET_BIRTHDATE_AND_ADDRESS_FAILED,
      schema: user,
      fetch: ({ birthdate, address, postCode, postPlace }) =>
        post(`/me/birthdate_and_address`, {
          birthdate,
          address,
          postCode,
          postPlace,
        }),
    }),
  )
  yield takeEvery(
    FETCH_ME,
    fetchSaga({
      SUCCEEDED: FETCH_ME_SUCCEEDED,
      FAILED: FETCH_ME_FAILED,
      schema: user,
      fetch: () => get('/me'),
    }),
  )

  yield takeEvery(
    FETCH_NOTIFICATIONS,
    fetchSaga({
      SUCCEEDED: FETCH_NOTIFICATIONS_SUCCEEDED,
      FAILED: FETCH_NOTIFICATIONS_FAILED,
      schema: objectOfNotifications,
      fetch: ({notificationsPageIndex, onlyUnread}) => get(`/me/notifications?pageIndex=${notificationsPageIndex}&onlyUnread=${onlyUnread}`)
      }),
  )
  
  yield takeEvery(
    NOTIFICATIONS_STATUS_UPDATE,
    fetchSaga({
      SUCCEEDED: NOTIFICATIONS_STATUS_UPDATE_SUCCEEDED,
      FAILED: NOTIFICATIONS_STATUS_UPDATE_FAILED,
      schema: objectOfNotifications,
      fetch: ({ ids, read, seen }) =>
        post(`/me/notifications/update_status`, {
          ids,
          read,
          seen,
        }),
    }),
  )
}

export default saga
