import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  getProjects,
  shouldFetchProjects,
  getProjectPagination,
  isProjectsPending,
  isAdmin,
} from '../redux/selectors'
import { fetchProjects } from '../redux/actions'
import I18n, { useI18nFunction } from '../components/I18n'
import usePagination from '../hooks/usePagination'
import useSort from '../hooks/useSort'
import Page from '../components/Page'
import DataListHeader from '../components/DataList/DataListHeader'
import ProjectList from '../components/ProjectList'
import CreateProjectModal from '../components/CreateProjectModal'
import Button from '../components/Button'

const Projects = ({
  projects,
  fetchProjects,
  isProjectsPending,
  isAdmin,
  projectPagination,
}) => {
  const I18nFunction = useI18nFunction()

  const projectPageProps = {
    paginationProps: {
      perPageOptions: [10, 15, 25, 50, 100, I18nFunction( { id: 'pagination.fetch_all' } )],
    },
    searchInputProps: {
      placeholder: I18nFunction({ id: 'Search_for_projects' }),
    },
    sortProps: {
      placeholder: I18nFunction({ id: 'Sort_by' }),
      options: [
        {
          label: <I18n id="Created_on" />,
          key: 'created_on_desc',
          value: {
            iteratee: ({ createdOn }) => (createdOn ? createdOn : '1970-01-01'),
            order: 'desc',
          },
        },
        {
          label: <I18n id="Updated_on" />,
          key: 'updated_on_desc',
          value: {
            iteratee: ({ updatedOn }) => (updatedOn ? updatedOn : '1970-01-01'),
            order: 'desc',
          },
        },
      ],
    },
    multiSelectProps: {
      filters: [
        {
          key: 'conflictFilter',
          placeholder: I18nFunction({ id: 'filterType', payload: 'conflicts' }),
          options: [
            {
              label: I18nFunction({ id: 'has_conflicts' }),
              value: 'has_conflicts',
              key: 1,
            },
          ],
        },
      ],
    },
    listColumnProps: {
      columns: [
        {
          label: I18nFunction({ id: 'Title' }),
          key: 'title',
          width: '37%',
          align: 'start',
        },
        {
          label: I18nFunction({ id: 'Created_on' }),
          key: 'created_on',
          width: '15%',
          align: 'center',
        },
        {
          label: I18nFunction({ id: 'Number_of_users' }),
          key: 'numUsers',
          width: '12%',
          align: 'center',
        },
        {
          label: I18nFunction({ id: 'Number_of_companies' }),
          key: 'numCompanies',
          width: '12%',
          align: 'center',
        },
        {
          label: I18nFunction({ id: 'Number_of_conflicts' }),
          key: 'numConflicts',
          width: '12%',
          align: 'center',
        },
        {
          label: '',
          key: 'space',
          width: '12%',
        },
      ],
    },
  }

  const {
    query,
    pageIndex,
    perPage,
    paginationResult,
    filters,
    sortOption,
    dateRangeOption,
    dateRangeFrom,
    dateRangeTo,
    setQuery,
    setPageIndex,
    setPerPage,
    setQueryHandler,
    setFiltersHandler,
    setSortOptionHandler,
    setDateRangeOptionHandler,
    setDateRangeFromHandler,
    setDateRangeToHandler,
    setPerPageHandler,
  } = usePagination(fetchProjects, getProjectPagination, projectPagination)

  const { sort, setSort, sortFunction } = useSort(
    projectPageProps?.sortProps?.options[0],
  )

  const sortProjects = () => {
    if (
      projectPagination?.sortOption &&
      projectPagination?.sortOption != sort?.key
    ) {
      let idx = projectPageProps?.sortProps?.options.findIndex(
        (o) => o.key === projectPagination?.sortOption,
      )
      setSort(projectPageProps?.sortProps?.options[idx])
    }
    return sortFunction(projects)
  }

  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false)
  const onCreateProjectButtonClicked = () => {
    setShowCreateProjectModal(true)
  }

  return (
    <Page>
      {showCreateProjectModal && (
        <CreateProjectModal onClose={() => setShowCreateProjectModal(false)} />
      )}
      <Content>
        <h1>
          <I18n id="Projects" />
        </h1>
        <Options>
          <ActionButton
            small
            type="submit"
            onClick={() => onCreateProjectButtonClicked()}
          >
            <I18n id="Create_project" />
          </ActionButton>
          {/* denne implementeres i egen PR */}
          {/* <ActionButton
            small
            type="submit"
            onClick={() => console.log("Eksporter prosjekter")}
          >
            <I18n id="Export_projects_pdf" />
          </ActionButton> */}
        </Options>
        <DataListHeader
          pageProps={projectPageProps}
          query={query}
          pageIndex={pageIndex}
          perPage={perPage}
          paginationResult={paginationResult}
          filters={filters}
          sortOption={sortOption}
          dateRangeOption={dateRangeOption}
          dateRangeFrom={dateRangeFrom}
          dateRangeTo={dateRangeTo}
          setQuery={setQuery}
          setPageIndex={setPageIndex}
          setPerPage={setPerPage}
          setQueryHandler={setQueryHandler}
          setFiltersHandler={setFiltersHandler}
          setSortOptionHandler={setSortOptionHandler}
          setDateRangeOptionHandler={setDateRangeOptionHandler}
          setDateRangeFromHandler={setDateRangeFromHandler}
          setDateRangeToHandler={setDateRangeToHandler}
          setPerPageHandler={setPerPageHandler}
        />
        <Columns>
          {projectPageProps?.listColumnProps?.columns.map((column) => (
            <Column
              key={column?.key}
              width={column?.width}
              align={column?.align}
            >
              {column?.label}
            </Column>
          ))}
        </Columns>
        <ProjectList
          projects={sortProjects(projects)}
          pending={isProjectsPending}
        />
      </Content>
    </Page>
  )
}

const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  padding: 0.5em 0.5em 0.5em 0.5em;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
`

const ActionButton = styled(Button)`
  background: #0c3689;
  border: 2px solid #0c3689;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 12em;
`

const Content = styled.div`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
  padding: 0 6em;
`

const Columns = styled.div`
  padding: 1em 1.5em;
  width: 100%;
  font-weight: bold;
  display: flex;
`

const Column = styled.div`
  display: flex;
  width: ${({ width }) => width};
  align-items: center;
  justify-content: ${({ align }) => align};
`

const mapStateToProps = (state) => ({
  projects: getProjects(state),
  shouldFetchProjects: shouldFetchProjects(state),
  projectPagination: getProjectPagination(state),
  isProjectsPending: isProjectsPending(state),
  isAdmin: isAdmin(state),
})

const mapDispatchToProps = { fetchProjects }

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
