import React from 'react'
import styled from 'styled-components'
import ScrollPaginate from '../ScrollPaginate'
import NotificationItem from './NotificationItem'
import I18n from '../I18n'

const NotificationList = ({
  filteredNotifications,
  fetchNotifications,
  pagination,
  notificationsPageIndex,
  setNotificationsPageIndex,
  notificationsLoading,
  updateNotificationsStatus,
}) => {
  return (
    <ScrollPaginate
      fetchListItems={fetchNotifications}
      listItems={filteredNotifications}
      pagination={pagination}
      loading={notificationsLoading}
      pageIndex={notificationsPageIndex}
      setPageIndex={setNotificationsPageIndex}
      isMoreData={pagination?.pages > notificationsPageIndex}
      maxHeight={'300px'}
    >
      {filteredNotifications?.length > 0
        ? filteredNotifications.map((n) => (
            <NotificationItem
              key={n.id}
              updateNotificationsStatus={updateNotificationsStatus}
              notification={n}
            />
          ))
        : !notificationsLoading && (
            <NoNotificationsInfo>
              <I18n id="no_notifications" />
            </NoNotificationsInfo>
          )}
    </ScrollPaginate>
  )
}
const NoNotificationsInfo = styled.div`
  color: #aaa;
  font-size: 12px;
  text-align: center;
  width: 100%;
`
export default NotificationList
