import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import flagIcon from '../icons/red_flag.svg'
import I18n, { useI18nFunction } from './I18n'
import { prettyDate } from '../dateUtils'
import Button from './Button'
import { Input, TextAreaInput, ProjectStatusSelector } from './form'
import { updateProjectInfo } from '../redux/actions'
import useValidation from '../hooks/useValidation'
import useVisited from '../hooks/useVisited'
import * as schema from '../validation'
import deleteIcon from '../icons/trashcan.svg'

const ProjectHeader = ({ project, updateProjectInfo, setShowDeleteModal }) => {

  const I18nFunction = useI18nFunction()

  const [isEditing, setIsEditing] = useState({
    title: false,
    description: false,
    projectStatus: false,
    assessmentStatus: false,
  })
  
  const [tempProject, setTempProject] = useState({
    title: project?.title,
    description: project?.description,
    projectStatus: project?.projectStatus,
    assessmentStatus: project?.assessmentStatus,
  })
  
  const handleInputChange = (field, value) => {
    setTempProject(prevState => ({
      ...prevState,
      [field]: value
    }))
  }
  
  const toggleEditing = (field, isEditing) => {
    setIsEditing(prevState => ({
      ...prevState,
      [field]: isEditing
    }))
  }

  const updateProjectInfoField = (field) => {
    const updatedData = {
      title: field === 'title' ? tempProject.title : false,
      description: field === 'description' ? tempProject.description : false,
      projectStatus: field === 'projectStatus' ? tempProject.projectStatus : false,
      assessmentStatus: field === 'assessmentStatus' ? tempProject.assessmentStatus : false,
    }
    
    updateProjectInfo(project.id, updatedData.title, updatedData.description, updatedData.projectStatus, updatedData.assessmentStatus);
  }

  const { errors, validate } = useValidation(schema.projectForm(I18nFunction))

  useEffect(() => {
    const cancelablePromise = validate(tempProject)
    return cancelablePromise.cancel
  }, [validate, tempProject])

  const { visited, visitField, visitFields } = useVisited()

  const handleBlur = (e) => visitField(e.target.name)

  const hasErrors = Object.keys(errors).length > 0

  const handleSubmit = (field) => {
    if (hasErrors) {
      visitFields(Object.keys(errors))
    } else {
      updateProjectInfoField(field)
    }
  }

  return (
    <OuterWrapper>
      <Wrapper>
        <Content>
          <Project>
            <ProjectInfo>
              {isEditing.title ?
                <EditWrapper>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault()
                      if (tempProject.title) {
                        handleSubmit('title')
                        toggleEditing('title', !isEditing.title)
                      }
                    }}
                  >
                    <TitleForm>
                      <Input
                        name="title"
                        id="title"
                        value={tempProject.title}
                        onChange={(e) => handleInputChange('title', e.target.value)}
                        onBlur={handleBlur}
                        error={errors.title}
                        visited={visited.title}
                      />
                    </TitleForm>
                    <Button
                      subtle
                      type="submit"
                      disabled={!tempProject.title}
                      pending={false}
                    >
                      <I18n id="Save" />
                    </Button>
                  </Form>
                    <Button subtle onClick={() => toggleEditing('title', false)}>
                    <I18n id="Cancel" />
                  </Button>
                </EditWrapper>
                :
                <TextAndButton>
                  <Title>{project?.title}</Title>
                  <Button subtle onClick={() => toggleEditing('title', !isEditing.title)}>
                    <I18n id="Edit" />
                  </Button>
                </TextAndButton>
              }
              {isEditing.description ?
                <EditWrapper>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault()
                      if (tempProject.description) {
                        handleSubmit('description')
                        toggleEditing('description', !isEditing.description)
                      }
                    }}
                  >
                    <TitleForm>
                      <TextAreaInput
                        name="description"
                        id="description"
                        value={tempProject.description}
                        onChange={(e) => handleInputChange('description', e.target.value)}
                        onBlur={handleBlur}
                        error={errors.description}
                        visited={visited.description}
                        rows={'5'}
                        />
                    </TitleForm>
                    <Button
                      subtle
                      type="submit"
                      disabled={!tempProject.description}
                      pending={false}
                    >
                      <I18n id="Save" />
                    </Button>
                  </Form>
                    <Button subtle onClick={() => toggleEditing('description', false)}>
                    <I18n id="Cancel" />
                  </Button>
                </EditWrapper>
                :
                <TextAndButton>
                  <div>{project?.description}</div>
                  <Button subtle onClick={() => toggleEditing('description', !isEditing.description)}>
                    <I18n id="Edit" />
                  </Button>
                </TextAndButton>
              }
            </ProjectInfo>
          </Project>
          <Info>
            <Row>
              <Label>
                  <I18n id="project_header.project_assessment_status" />:{' '}
              </Label>
              {isEditing.assessmentStatus ?
                <EditWrapper>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault()
                      if (tempProject.assessmentStatus) {
                        handleSubmit('assessmentStatus')
                        toggleEditing('assessmentStatus', !isEditing.assessmentStatus)
                      }
                    }}
                  >
                    <TitleForm>
                      <ProjectStatusSelector
                        name="project_assessment_status"
                        id="project_assessment_status"
                        value={tempProject.assessmentStatus}
                        onChange={(e) => handleInputChange('assessmentStatus', e.target.value)}
                        onBlur={handleBlur}
                        error={errors.project_assessment_status}
                        visited={visited.project_assessment_status}
                        />
                    </TitleForm>
                    <Button
                      subtle
                      type="submit"
                      disabled={!tempProject.assessmentStatus}
                      pending={false}
                    >
                      <I18n id="Save" />
                    </Button>
                  </Form>
                  <Button subtle onClick={() => toggleEditing('assessmentStatus', false)}>
                    <I18n id="Cancel" />
                  </Button>
                </EditWrapper>
                  :
                  <TextAndButton>
                  <span>
                    {project?.assessmentStatus ? 
                      I18nFunction( { 
                        id:'project_header.status',
                        status: project.assessmentStatus
                      })
                      : '-'}
                  </span>
                    <Button subtle onClick={() => toggleEditing('assessmentStatus', !isEditing.assessmentStatus)}>
                      <I18n id="Edit" />
                    </Button>
                </TextAndButton>
                }
            </Row>
            <Row>
              <Label>
                <I18n id="project_header.number_of_users" />:
              </Label>
              <span>{project?.numberOfProjectUsers || '-'}</span>
            </Row>
            <Row>
              <Label>
                <I18n id="project_header.number_of_companies" />:
              </Label>
              <span>{project?.numberOfProjectCompanies || '-'}</span>
            </Row>
            <Row>
              <Label>
                <I18n id="project_header.number_of_conflicts" />:
              </Label>
              {project?.numberOfConflicts || '-'}
              {project?.numberOfConflicts > 0 && <FlagIcon src={flagIcon} alt="" />}
            </Row>
          </Info>
          <Info>
          <Row>
              <Label>
                  <I18n id="project_header.project_status" />:{' '}
              </Label>
              {isEditing.projectStatus ?
                <EditWrapper>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault()
                      if (tempProject.projectStatus) {
                        handleSubmit('projectStatus')
                        toggleEditing('projectStatus', !isEditing.projectStatus)
                      }
                    }}
                  >
                    <TitleForm>
                      <ProjectStatusSelector
                        name="project_status"
                        id="project_status"
                        value={tempProject.projectStatus}
                        onChange={(e) => handleInputChange('projectStatus', e.target.value)}
                        onBlur={handleBlur}
                        error={errors.project_status}
                        visited={visited.project_status}
                        />
                    </TitleForm>
                    <Button
                      subtle
                      type="submit"
                      disabled={!tempProject.projectStatus}
                      pending={false}
                    >
                      <I18n id="Save" />
                    </Button>
                  </Form>
                    <Button subtle onClick={() => toggleEditing('projectStatus', false)}>
                    <I18n id="Cancel" />
                  </Button>
                </EditWrapper>
                  :
                  <TextAndButton>
                    <span>
                      {project?.projectStatus ? 
                        I18nFunction( { 
                          id:'project_header.status',
                          status: project.projectStatus
                        })
                        : '-'}
                    </span>
                    <Button subtle onClick={() => toggleEditing('projectStatus', !isEditing.projectStatus)}>
                      <I18n id="Edit" />
                    </Button>
                </TextAndButton>
                }
            </Row>
            <Row>
              <Label>
                <I18n id="project_header.custom_data.case_number" />:
              </Label>
              <span>{project?.customData?.caseNumber || '-'}</span>
            </Row>
            <Row>
              <Label>
                <I18n id="project_header.created_on" />:
              </Label>
              <span>{prettyDate(project?.createdOn) || '-'}</span>
            </Row>
            <Row>
              <Label>
                <I18n id="project_header.updated_on" />:{' '}
              </Label>
              <span>{prettyDate(project?.updatedOn) || '-'}</span>
            </Row>
          </Info>
          <DeleteButtonWrapper>
            <Button
              subtle
              disabled={!project}
              onClick={(e) => {
                e.preventDefault()
                setShowDeleteModal(true)
              }}
            >
              <DeleteIcon src={deleteIcon} />
            </Button>
          </DeleteButtonWrapper>
        </Content>
      </Wrapper>
    </OuterWrapper>
  )
}

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5em;
`

const Label = styled.span`
  color: ${({ theme: { subtleText } }) => subtleText};
`

const Title = styled.h1`
  margin: 0;
`

const TextAndButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25em;
`

const FlagIcon = styled.img`
  height: ${({ theme: { normalText } }) => normalText};
  margin-left: 0.25em;
`

const ProjectInfo = styled.div`
  padding-left: 1em;
  & > * {
    margin: 0.33em 0;
  }
`

const Project = styled.div`
  display: flex;

  & > :not(:last-child) {
    margin-right: 1.5em;
  }
`
const OuterWrapper = styled.div`
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
  padding-top: 1.5em;
`

const Wrapper = styled.div`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
`

const DeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  gap: 1em;
`

const EditWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`

const TitleForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
`

const Content = styled.div`
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
  display: flex;
  border-radius: 8px;

  & > :not(:last-child) {
    width: 32%;
    margin: 1.5em 0;
    padding: 0.25em 1.5em;
  }

  & > :last-child {
    width: 4%;
    padding: 0.5em;
  }

  & > :not(:first-child):not(:last-child) {
    border-left: solid 0.33em ${({ theme: { primary } }) => primary};
  }
`
const DeleteIcon = styled.img`
  height: 1.5em;
`

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  updateProjectInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHeader)