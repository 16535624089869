const staticContent = {
  en: {
    client_organization_name: 'Sign in',
    title: 'Integrity Monitor',
    footer_copy_right: {
      all_rights_reserved: 'KPMG. All Rights Reserved.',
    },

    Screen_too_small: "Integrity Monitor unfortunately only support screens of width 1024px or more. Please use a wider screen or zoom out in your browser.",

    Footer_About: 'About',
    Footer_Faq: 'FAQ',
    Footer_Privacy: 'Privacy Statement',

    Welcome: 'Welcome to Integrity Monitor',
    Click_log_in: 'Click the button to log in with Active Directory.',
    Log_in: 'Log in with Microsoft',
    Log_out: 'Log out',
    Not_found: 'Page not found. :(',
    Not_available: 'Not available',
    Insufficient_permissions: 'You do not have permission to view this page.',
    Download_employee_guide: "Employee Guide"
  },
  no: {
    client_organization_name: 'Logg inn',
    title: 'Habilitetsregisteret',
    footer_copy_right: {
      all_rights_reserved: 'KPMG. Alle rettigheter forbeholdt.',
    },

    Screen_too_small: "Habilitetsregisteret støtter dessverre kun skjermer som er bredere enn 1024px. Vennligst benytt en bredere skjerm eller zoom ut i nettleseren.",

    Footer_About: 'Om',
    Footer_Faq: 'FAQ',
    Footer_Privacy: 'Personvernerklæring',

    Welcome: 'Velkommen til Habilitetsregisteret',
    Click_log_in: 'Klikk på knappen for å logge inn med Active Directory.',
    Log_in: 'Logg inn med Microsoft',
    Log_out: 'Logg ut',
    Not_found: 'Siden finnes ikke. :(',
    Not_available: 'Ikke tilgjengelig',
    Insufficient_permissions: 'Du har ikke tilgang til å se denne siden.',
    Download_employee_guide: "Ansatt brukerveiledning",
  },
};

export default staticContent;
