import { RELATION_OWNERSHIP_CASE, RELATION_ROLE_CASE } from '../caseTypes'
import * as fromContent from './appContent'
import * as fromApplications from './applications'
import * as fromAppSettings from './appSettings'
import * as fromAuth from './auth'
import * as fromCases from './cases'
import * as fromCompanies from './companies'
import * as fromConnectionsForm from './connectionsForm'
import * as fromCurrentUser from './currentUser'
import * as fromErrors from './errors'
import * as fromEvents from './events'
import * as fromProffSelector from './proffSelector'
import * as fromProjects from './projects'
import * as fromRelations from './relations'
import * as fromReminder from './reminder'
import * as fromSuggestions from './suggestions'
import * as fromUsers from './users'

export const getContent = (state) => fromContent.getContent(state.appContent)
export const getLanguage = (state) => fromContent.getLanguage(state.appContent)
export const getOnboardingSlides = (state) =>
  fromContent.getOnboardingSlides(state.appContent)
export const isContentPending = (state) =>
  fromContent.isContentPending(state.appContent)

export const getEventSettings = (state) =>
  fromAppSettings.getEventSettings(state.appSettings)

export const getAppSettings = (state) =>
  fromAppSettings.getAppSettings(state.appSettings)

export const appSettingsIsLoading = (state) =>
  fromAppSettings.appSettingsIsLoading(state.appSettings)
export const appSettingsHasLoaded = (state) =>
  fromAppSettings.appSettingsHasLoaded(state.appSettings)
export const appSettingsHaveUpdates = (state) =>
  fromAppSettings.appSettingsHaveUpdates(state.appSettings)

export const isAdmin = (state) => fromAuth.isAdmin(state.auth)
export const isAuthPending = (state) => fromAuth.isAuthPending(state.auth)
export const isAuthenticated = (state) => fromAuth.isAuthenticated(state.auth)
export const isLogOutPending = (state) => fromAuth.isLogOutPending(state.auth)

export const getUserEvents = (state, userId) =>
  fromEvents.getUserEvents(state.events, userId)
export const isUserEventsFetching = (state, userId) =>
  fromEvents.isUserEventsFetching(state.events, userId)
export const shouldFetchUserEvents = (state, userId) =>
  fromEvents.shouldFetchUserEvents(state.events, userId)

export const getUser = (state, userId) => fromUsers.getUser(state.users, userId)
export const userHasConfirmedNoConnections = (state, userId, connectionType) =>
  fromUsers.hasConfirmedNoConnections(state.users, userId, connectionType)
export const getUserList = (state) => fromUsers.getUserList(state.users)
export const isUserListFetching = (state) =>
  fromUsers.isUserListFetching(state.users)
export const shouldFetchUser = (state, userId) =>
  fromUsers.shouldFetchUser(state.users, userId)
export const isUserFetching = (state, userId) =>
  fromUsers.isUserFetching(state.users, userId)
export const isSetBirthdatePending = (state, userID) =>
  fromUsers.isSetBirthdatePending(state.users, userID)
export const isSetEmploymentEndDatePending = (state, userId) =>
  fromUsers.isSetEmploymentEndDatePending(state.users, userId)
export const isSetAddressPending = (state, userId) =>
  fromUsers.isSetAddressPending(state.users, userId)
export const isSetPhonePending = (state, userId) =>
  fromUsers.isSetPhonePending(state.users, userId)
export const isSetPositionOfInfluencePending = (state, userId) =>
  fromUsers.isSetPositionOfInfluencePending(state.users, userId)
export const isSetPositionOfInfluenceDescriptionPending = (state, userId) =>
  fromUsers.isSetPositionOfInfluenceDescriptionPending(state.users, userId)
export const isUserProfileDataFetching = (state, userId) =>
  fromUsers.isUserProfileDataFetching(state.users, userId)
export const shouldFetchUserProfileData = (state, userId) =>
  fromUsers.shouldFetchUserProfileData(state.users, userId)

export const getUserPagination = (state) =>
  fromUsers.getUserPagination(state.users)

export const getCurrentUser = (state) => fromCurrentUser.get(state.currentUser)
export const getNotifications = (state) =>
  fromCurrentUser.getNotifications(state.currentUser)
export const isProjectAdmin = (state) => fromCurrentUser.isProjectAdmin(state.currentUser)
export const getNotificationsPageIndex = (state) =>
  fromCurrentUser.getNotificationsPageIndex(state.currentUser)
export const getNotificationPagination = (state) =>
  fromCurrentUser.getNotificationPagination(state.currentUser)
export const getNotificationsLoading = (state) =>
  fromCurrentUser.getNotificationsLoading(state.currentUser)
export const isManager = (state) => fromCurrentUser.isManager(state.currentUser)
export const getCurrentUserId = (state) =>
  fromCurrentUser.getId(state.currentUser)
export const hasCompletedOnboarding = (state) =>
  fromCurrentUser.hasCompletedOnboarding(state.currentUser)

export const hasAcceptedCookies = (state) =>
  fromCurrentUser.hasAcceptedCookies(state.currentUser)

export const hasConfirmedTermsAndConditions = (state) =>
  fromCurrentUser.hasConfirmedTermsAndConditions(state.currentUser)
export const hasConfirmedBirthdateAndAddress = (state) =>
  fromCurrentUser.hasConfirmedBirthdateAndAddress(state.currentUser)
export const hasConfirmedProffSelection = (state) =>
  fromCurrentUser.hasConfirmedProffSelection(state.currentUser)
export const currentUserhasConfirmedNoConnections = (state, connectionType) =>
  fromCurrentUser.hasConfirmedNoConnections(state.currentUser, connectionType)
export const isCompleteOnboardingPending = (state) =>
  fromCurrentUser.isCompleteOnboardingPending(state.currentUser)
export const isSetBirthdateAndAddressPending = (state) =>
  fromCurrentUser.isSetBirthdateAndAddressPending(state.currentUser)
export const isConfirmNoConnectionsPending = (state, connectionType) =>
  fromCurrentUser.isConfirmNoConnectionsPending(
    state.currentUser,
    connectionType,
  )
export const isCancelConfirmNoConnectionsPending = (state, connectionType) =>
  fromCurrentUser.isCancelConfirmNoConnectionsPending(
    state.currentUser,
    connectionType,
  )

export const isCurrentUserFetching = (state) =>
  fromCurrentUser.isCurrentUserFetching(state.currentUser)

export const getSuggestions = (state, query) =>
  fromSuggestions.getSuggestions(state.suggestions, query)

export const getCase = (state, id) => fromCases.getCase(state.cases, id)
export const shouldFetchCase = (state, id) =>
  fromCases.shouldFetchCase(state.cases, id)
export const getCaseFetchStatus = (state, id) =>
  fromCases.getCaseFetchStatus(state.cases, id)
export const isIndirectCasesPending = (state, id) =>
  fromCases.isIndirectCasesPending(state.cases, id)
export const getSelectedCase = (state) => fromCases.getSelectedCase(state.cases)

export const getUserCasesOfType = (state, userId, caseType) => {
  return fromCases.getUserCasesOfType(state.cases, userId, caseType)
}

export const isProfileRegistrationCompleted = (state, userId) => {
  return fromUsers.isProfileRegistrationCompleted(state.users)
}

export const getCaseStatistics = (state) =>
  fromUsers.getCaseStatistics(state.users)

export const shouldFetchRelation = (state, relationId) =>
  fromRelations.shouldFetchRelation(state.relations, relationId)
export const getUserRelations = (state, userId) =>
  fromRelations.getUserRelations(state.relations, userId)
export const hasConfirmedRelationProffSelection = (state, relationId) =>
  fromRelations.hasConfirmedRelationProffSelection(state.relations, relationId)

export const getRelationCases = (state, relationId) =>
  fromCases.getRelationCases(state.cases, relationId)

export const getAllUserRelationCases = (state, userId) =>
  fromCases.getAllUserRelationCases(state.cases, userId)

export const isRelationCasesPending = (state, userId) => {
 return (
   fromCases.isRelationCasesPending(state.cases, userId) ||
   fromCases.isCasesPending(state.cases)
 )
 
}
export const getRelation = (state, relationId) =>
  fromRelations.getRelation(state.relations, relationId)

export const isCommentOnCasePending = (state, caseId) =>
  fromCases.isCommentOnCasePending(state.cases, caseId)
export const getAllCases = (state) => fromCases.getAllCases(state.cases)
export const isCasesPending = (state) => fromCases.isCasesPending(state.cases)
export const shouldFetchCases = (state) =>
  fromCases.shouldFetchCases(state.cases)

export const getCasePagination = (state) =>
  fromCases.getCasePagination(state.cases)

export const getProfileCasePagination = (state) =>
  fromUsers.getProfileCasePagination(state.users)
export const getProfileCaseIndex = (state) =>
  fromUsers.getProfileCaseIndex(state.users)

export const isPostBoardStatementPending = (state) =>
  fromCases.isPostBoardStatementPending(state.cases)

//cases

export const getAllApplications = (state) =>
  fromApplications.getAllApplications(state.applications)
export const isApplicationsPending = (state) =>
  fromApplications.isApplicationsPending(state.applications)
export const shouldFetchApplications = (state) =>
  fromApplications.shouldFetchApplications(state.applications)
export const getApplicationPagination = (state) =>
  fromApplications.getApplicationPagination(state.applications)

export const getCompany = (state, companyId) =>
  fromCompanies.getCompany(state.companies, companyId)
export const getCompanyConnections = (state, companyId) =>
  fromCompanies.getCompanyConnections(state.companies, companyId)
export const getCompanies = (state) =>
  fromCompanies.getCompanies(state.companies)
export const shouldFetchCompanies = (state) =>
  fromCompanies.shouldFetchCompanies(state.companies)
export const shouldFetchCompanyConnections = (state, companyId) =>
  fromCompanies.shouldFetchCompanyConnections(state.companies, companyId)
export const shouldFetchCompany = (state, companyId) =>
  fromCompanies.shouldFetchCompany(state.companies, companyId)
export const isCompaniesPending = (state) =>
  fromCompanies.isCompaniesPending(state.companies)
export const isCompanyConnectionsPending = (state, companyId) =>
  fromCompanies.isCompanyConnectionsPending(state.companies, companyId)
export const isCompanyPending = (state, companyId) =>
  fromCompanies.isCompanyPending(state.companies, companyId)
export const isPostSuppliersPending = (state) =>
  fromCompanies.isPostSuppliersPending(state.companies)
export const isPostCustomersPending = (state) =>
  fromCompanies.isPostCustomersPending(state.companies)
export const getCompanyPagination = (state) =>
  fromCompanies.getCompanyPagination(state.companies)

export const getProject = (state, projectId) =>
  fromProjects.getProject(state.projects, projectId)
export const getProjects = (state) => fromProjects.getProjects(state.projects)
export const shouldFetchProjects = (state) =>
  fromProjects.shouldFetchProjects(state.projects)
export const shouldFetchProject = (state, projectId) =>
  fromProjects.shouldFetchProject(state.projects, projectId)
export const isProjectsPending = (state) =>
  fromProjects.isProjectsPending(state.projects)
export const isProjectPending = (state, projectId) =>
  fromProjects.isProjectPending(state.projects, projectId)
export const getProjectPagination = (state) =>
  fromProjects.getProjectPagination(state.projects)
export const getProjectCompanyForm = (state) =>
  fromProjects.getProjectCompanyForm(state.projects)
export const getSelectedProjectCompanies = (state, projectId) =>
  fromProjects.getSelectedProjectCompanies(state.projects, projectId)
export const getProjectUserForm = (state) =>
  fromProjects.getProjectUserForm(state.projects)
export const getSelectedProjectUsers = (state, projectId) =>
  fromProjects.getSelectedProjectUsers(state.projects, projectId)

export const getLatestSupplierUpdateLog = (state) =>
  fromCompanies.getLatestSupplierUpdateLog(state.companies)
export const getLatestSupplierFileUploadEvent = (state) =>
  fromCompanies.getLatestSupplierFileUploadEvent(state.companies)
export const getLatestCustomerUpdateLog = (state) =>
  fromCompanies.getLatestCustomerUpdateLog(state.companies)
export const getLatestCustomerFileUploadEvent = (state) =>
  fromCompanies.getLatestCustomerFileUploadEvent(state.companies)

export const getActiveProffPerson = (state) =>
  fromProffSelector.getActiveProffPerson(state.proffSelector)
export const getProffPersonSuggestions = (state) =>
  fromProffSelector.getProffPersonSuggestions(state.proffSelector)
export const isProffSuggestionsFetching = (state) =>
  fromProffSelector.isProffSuggestionsFetching(state.proffSelector)
export const isSetProffPersonIdPending = (state) =>
  fromProffSelector.isSetProffPersonIdPending(state.proffSelector)

export const getActiveConnection = (state) =>
  fromConnectionsForm.getActiveConnection(state.connectionsForm)
export const getActiveConnectionHasUpdates = (state) =>
  fromConnectionsForm.getActiveConnectionHasUpdates(state.connectionsForm)
export const hasUnsavedChanges = (state) =>
  fromConnectionsForm.hasUnsavedChanges(state.connectionsForm)
export const isConnectionFormPending = (state) =>
  fromConnectionsForm.isPending(state.connectionsForm)

export const getErrorMessages = (state) =>
  fromErrors.getErrorMessages(state.errors)
export const getUserSessionExpired = (state) =>
  fromErrors.getUserSessionExpired(state.errors)

export const getCompanyCases = (state, companyId) =>
  Object.values(state.cases.byId)
    .filter((Case) => Case.organizationNumber === companyId)
    .map((Case) => {
      if (
        [RELATION_ROLE_CASE, RELATION_OWNERSHIP_CASE].includes(Case.caseType)
      ) {
        const relation = getRelation(state, Case.relationId)
        const user = getUser(state, relation?.userId)
        return {
          ...Case,
          relationName: `${relation?.firstName} ${relation?.lastName}`,
          relationType: relation?.relationType,
          userName: user ? user.name : '',
          userId: user ? user.id : '',
        }
      } else {
        const user = getUser(state, Case.userId)
        return {
          ...Case,
          userName: user ? user.name : '',
          userId: user ? user.id : '',
        }
      }
    })

export const isReminderFetching = (state) =>
  fromReminder.isFetcing(state.reminder)

export const isCaseRelevant = (c) => fromCases.isCaseRelevant(c)