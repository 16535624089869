import { combineReducers } from 'redux'
import byId, * as fromById from './byId'
import allIds, * as fromAllIds from './allIds'
import {
  RELATION_CASE,
  USER_OTHER_CONNECTION_CASE,
  USER_OWNERSHIP_CASE,
  USER_ROLE_CASE,
} from '../../caseTypes'
import { ROUTER_LOCATION_CHANGE } from '../connectionsForm'

export { default as sagas } from './sagas'

export const FETCH_USER_NAMES = 'FETCH_USER_NAMES'
export const FETCH_USER_NAMES_SUCCEEDED = 'FETCH_USER_NAMES_SUCCEEDED'
export const FETCH_USER_NAMES_FAILED = 'FETCH_USER_NAMES_FAILED'

export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USERS_SUCCEEDED = 'FETCH_USERS_SUCCEEDED'
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED'

export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCEEDED = 'FETCH_USER_SUCCEEDED'
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED'

export const FETCH_USER_PROFILE_CASES = 'FETCH_USER_PROFILE_CASES'
export const FETCH_USER_PROFILE_CASES_SUCCEEDED =
  'FETCH_USER_PROFILE_CASES_SUCCEEDED'
export const FETCH_USER_PROFILE_CASES_FAILED = 'FETCH_USER_PROFILE_CASES_FAILED'
export const FETCH_USER_PROFILE_DATA = 'FETCH_USER_PROFILE_DATA'
export const FETCH_USER_PROFILE_DATA_SUCCEEDED =
  'FETCH_USER_PROFILE_DATA_SUCCEEDED'
export const FETCH_USER_PROFILE_DATA_FAILED = 'FETCH_USER_PROFILE_DATA_FAILED'
export const RESET_PAGINATION = 'RESET_PAGINATION'
export const SET_PROFILE_CASE_INDEX = 'SET_PROFILE_CASE_INDEX'

export const SET_BIRTHDATE = 'SET_BIRTHDATE'
export const SET_BIRTHDATE_SUCCEEDED = 'SET_BIRTHDATE_SUCCEEDED'
export const SET_BIRTHDATE_FAILED = 'SET_BIRTHDATE_FAILED'

export const SET_EMPLOYMENT_END_DATE = 'SET_EMPLOYMENT_END_DATE'
export const SET_EMPLOYMENT_END_DATE_SUCCEEDED =
  'SET_EMPLOYMENT_END_DATE_SUCCEEDED'
export const SET_EMPLOYMENT_END_DATE_FAILED = 'SET_EMPLOYMENT_END_DATE_FAILED'

export const SET_ADDRESS = 'SET_ADDRESS'
export const SET_ADDRESS_SUCCEEDED = 'SET_ADDRESS_SUCCEEDED'
export const SET_ADDRESS_FAILED = 'SET_ADDRESS_FAILED'

export const SET_PHONE = 'SET_PHONE'
export const SET_PHONE_SUCCEEDED = 'SET_PHONE_SUCCEEDED'
export const SET_PHONE_FAILED = 'SET_PHONE_FAILED'

export const SET_POSITION_OF_INFLUENCE = 'SET_POSITION_OF_INFLUENCE'
export const SET_POSITION_OF_INFLUENCE_SUCCEEDED =
  'SET_POSITION_OF_INFLUENCE_SUCCEEDED'
export const SET_POSITION_OF_INFLUENCE_FAILED =
  'SET_POSITION_OF_INFLUENCE_FAILED'

export const SET_POSITION_OF_INFLUENCE_DESCRIPTION =
  'SET_POSITION_OF_INFLUENCE_DESCRIPTION'
export const SET_POSITION_OF_INFLUENCE_DESCRIPTION_SUCCEEDED =
  'SET_POSITION_OF_INFLUENCE_DESCRIPTION_SUCCEEDED'
export const SET_POSITION_OF_INFLUENCE_DESCRIPTION_FAILED =
  'SET_POSITION_OF_INFLUENCE_DESCRIPTION_FAILED'

  export const fetchUserNames = (
    pagination = { query: '', pageIndex: 1, perPage: 20 },
  ) => ({
    type: FETCH_USER_NAMES,
    pagination,
  })

  export const fetchUsers = (
  pagination = { query: '', pageIndex: 1, perPage: 20 },
) => ({
  type: FETCH_USERS,
  pagination,
})

export const fetchUser = (userId) => ({
  type: FETCH_USER,
  userId,
})

export const fetchUserProfileData = (userId) => ({
  type: FETCH_USER_PROFILE_DATA,
  userId,
})
export const fetchUserProfileCases = (
  userId,
  caseType,
  pageIndex = 1,
  perPage = 5,
) => ({
  type: FETCH_USER_PROFILE_CASES,
  userId,
  caseType,
  pagination: {
    pageIndex,
    perPage,
  },
})

export const resetPagination = () => ({ type: RESET_PAGINATION })

export const setProfileCaseIndex =(profileCaseIndex)=>({
  type: SET_PROFILE_CASE_INDEX,
  profileCaseIndex
})

const profileCaseIndex = (state = 1, action) => {
  switch (action.type) {
    case SET_PROFILE_CASE_INDEX:
      return action.profileCaseIndex
    case RESET_PAGINATION:
    case ROUTER_LOCATION_CHANGE:
        return 1
    default:
      return state
  }
}

const profileCasePagination = (
  state = { pageIndex: 1, perPage: 5, total: 0 },
  action,
) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_CASES_SUCCEEDED:
      return {
        ...action.payload.pagination,
      }
    case RESET_PAGINATION:
    case ROUTER_LOCATION_CHANGE:
    case FETCH_USER_PROFILE_DATA:
      return { pageIndex: 1, perPage: 5, total: 0 }

    default:
      return state
  }
}
export const setBirthdate = (userId, birthdate) => ({
  type: SET_BIRTHDATE,
  userId,
  birthdate,
})

export const setEmploymentEndDate = (userId, employmentEndDate) => ({
  type: SET_EMPLOYMENT_END_DATE,
  userId,
  employmentEndDate,
})

export const setAddress = (userId, address, postCode, postPlace) => ({
  type: SET_ADDRESS,
  userId,
  address,
  postCode,
  postPlace,
})

export const setPhone = (userId, phone) => ({
  type: SET_PHONE,
  userId,
  phone,
})

export const setPositionOfInfluence = (userId, positionOfInfluence) => ({
  type: SET_POSITION_OF_INFLUENCE,
  userId,
  positionOfInfluence,
})

export const setPositionOfInfluenceDescription = (
  userId,
  positionOfInfluenceDescription,
) => ({
  type: SET_POSITION_OF_INFLUENCE_DESCRIPTION,
  userId,
  positionOfInfluenceDescription,
})

const setBirthdateStatus = (state = {}, action) => {
  switch (action.type) {
    case SET_BIRTHDATE:
      return {
        ...state,
        [action.userId]: 'pending',
      }
    case SET_BIRTHDATE_SUCCEEDED:
      return {
        ...state,
        [action.userId]: 'succeeded',
      }
    case SET_BIRTHDATE_FAILED:
      return {
        ...state,
        [action.userId]: 'failed',
      }
    default:
      return state
  }
}

const setEmploymentEndDateStatus = (state = {}, action) => {
  switch (action.type) {
    case SET_EMPLOYMENT_END_DATE:
      return {
        ...state,
        [action.userId]: 'pending',
      }

    case SET_EMPLOYMENT_END_DATE_SUCCEEDED:
      return {
        ...state,
        [action.userId]: 'succeeded',
      }

    case SET_EMPLOYMENT_END_DATE_FAILED:
      return {
        ...state,
        [action.userId]: 'failed',
      }

    default:
      return state
  }
}

const setAddressStatus = (state = {}, action) => {
  switch (action.type) {
    case SET_ADDRESS:
      return {
        ...state,
        [action.userId]: 'pending',
      }
    case SET_ADDRESS_SUCCEEDED:
      return {
        ...state,
        [action.userId]: 'succeeded',
      }
    case SET_ADDRESS_FAILED:
      return {
        ...state,
        [action.userId]: 'failed',
      }
    default:
      return state
  }
}

const setPhoneStatus = (state = {}, action) => {
  switch (action.type) {
    case SET_PHONE:
      return {
        ...state,
        [action.userId]: 'pending',
      }
    case SET_PHONE_SUCCEEDED:
      return {
        ...state,
        [action.userId]: 'succeeded',
      }
    case SET_PHONE_FAILED:
      return {
        ...state,
        [action.userId]: 'failed',
      }
    default:
      return state
  }
}

const setPositionOfInfluenceStatus = (state = {}, action) => {
  switch (action.type) {
    case SET_POSITION_OF_INFLUENCE:
      return {
        ...state,
        [action.userId]: 'pending',
      }

    case SET_POSITION_OF_INFLUENCE_SUCCEEDED:
      return {
        ...state,
        [action.userId]: 'succeeded',
      }

    case SET_POSITION_OF_INFLUENCE_FAILED:
      return {
        ...state,
        [action.userId]: 'failed',
      }

    default:
      return state
  }
}

const setPositionOfInfluenceDescriptionStatus = (state = {}, action) => {
  switch (action.type) {
    case SET_POSITION_OF_INFLUENCE_DESCRIPTION:
      return {
        ...state,
        [action.userId]: 'pending',
      }

    case SET_POSITION_OF_INFLUENCE_DESCRIPTION_SUCCEEDED:
      return {
        ...state,
        [action.userId]: 'succeeded',
      }

    case SET_POSITION_OF_INFLUENCE_DESCRIPTION_FAILED:
      return {
        ...state,
        [action.userId]: 'failed',
      }

    default:
      return state
  }
}

export const pagination = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCEEDED:
      return {
        ...action.payload.pagination,
      }
    default:
      return state
  }
}

export const caseStatistics = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_DATA_SUCCEEDED:
      return {
        ...action.payload.result.caseStatistics,
      }
    default:
      return state
  }
}

export const getUser = (state, userId) => fromById.getUser(state.byId, userId)
export const shouldFetchUser = (state, userId) =>
  fromById.shouldFetchUser(state.byId, userId)
export const isUserFetching = (state, userId) =>
  fromById.isUserFetching(state.byId, userId)
export const isSetBirthdatePending = (state, userId) =>
  state.setBirthdateStatus[userId] === 'pending'
export const isSetEmploymentEndDatePending = (state, userId) =>
  state.setEmploymentEndDateStatus[userId] === 'pending'
export const isSetAddressPending = (state, userId) =>
  state.setAddressStatus[userId] === 'pending'
export const isSetPhonePending = (state, userId) =>
  state.setPhoneStatus[userId] === 'pending'

export const isSetPositionOfInfluencePending = (state, userId) =>
  state.setPositionOfInfluenceStatus[userId] === 'pending'

export const isSetPositionOfInfluenceDescriptionPending = (state, userId) =>
  state.setPositionOfInfluenceDescriptionPending[userId] === 'pending'

export const hasConfirmedNoConnections = (state, userId, connectionType) =>
  fromById.hasConfirmedNoConnections(state.byId, userId, connectionType)

export const isUserProfileDataFetching = (state, userId) =>
  fromById.isUserProfileDataFetching(state.byId, userId)
export const shouldFetchUserProfileData = (state, userId) =>
  fromById.shouldFetchUserProfileData(state.byId, userId)
export const getCaseStatistics = (state) => state.caseStatistics
export const isProfileRegistrationCompleted = (state, userId) =>
  state.caseStatistics[RELATION_CASE]?.confirmed &&
  state.caseStatistics[USER_OTHER_CONNECTION_CASE]?.confirmed &&
  state.caseStatistics[USER_OWNERSHIP_CASE]?.confirmed &&
  state.caseStatistics[USER_ROLE_CASE]?.confirmed
export const getUserList = (state) => {
  const ids = fromAllIds.getIds(state.allIds)

  if (!ids) return undefined

  return ids.map((id) => fromById.getUser(state.byId, id))
}
export const isUserListFetching = (state) => fromAllIds.isFetching(state.allIds)

export const getUserPagination = (state) => state.pagination

export const getProfileCasePagination = (state) => {
  return state.profileCasePagination
}

export const getProfileCaseIndex = (state) => {
  return state.profileCaseIndex
}

export default combineReducers({
  byId,
  allIds,
  setBirthdateStatus,
  setEmploymentEndDateStatus,
  setAddressStatus,
  setPhoneStatus,
  setPositionOfInfluenceStatus,
  setPositionOfInfluenceDescriptionStatus,
  pagination,
  caseStatistics,
  profileCasePagination,
  profileCaseIndex
})
