import { get } from '../fetch'
import { fetchSagaPagination, takeLatestWithCancel } from '../sagas'

import {
  FETCH_ALL_APPLICATIONS,
  FETCH_ALL_APPLICATIONS_SUCCEEDED,
  FETCH_ALL_APPLICATIONS_FAILED,
} from './index'

import * as schema from '../schema'
import { ROUTER_LOCATION_CHANGE } from '../connectionsForm'

function* saga() {
  yield takeLatestWithCancel(
    FETCH_ALL_APPLICATIONS,
    ROUTER_LOCATION_CHANGE,
    fetchSagaPagination,
    {
      SUCCEEDED: FETCH_ALL_APPLICATIONS_SUCCEEDED,
      FAILED: FETCH_ALL_APPLICATIONS_FAILED,
      schema: schema.applicationResponse,
      fetch: ({
        pagination: {
          query,
          filters,
          sortOption,
          dateRangeOption,
          dateRangeFrom,
          dateRangeTo,
          pageIndex,
          perPage,
        },
      }) =>
        get(
          `/applications?query=${query}&filters=${filters}&sort=${sortOption}&dateRange=${dateRangeOption}&from=${dateRangeFrom}&to=${dateRangeTo}&pageIndex=${pageIndex}&perPage=${perPage}`,
        ),
    },
  )



}

export default saga
