import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import I18n, { useI18nFunction } from '../I18n'
import Button from '../Button'
import { OrganizationInput, Input, CountrySelector } from '../form'
import {
  updateProjectCompanyForm,
  clearProjectCompanyForm,
  selectProjectCompany,
} from '../../redux/actions'
import { getProjectCompanyForm } from '../../redux/selectors'
import useValidation from '../../hooks/useValidation'
import useVisited from '../../hooks/useVisited'
import * as schema from '../../validation'

const ProjectCompanyForm = ({
  pending,
  projectId,
  updateProjectCompanyForm,
  projectCompanyForm,
  clearProjectCompanyForm,
  selectProjectCompany,
  selectedProjectCompanies,
}) => {
  const I18nFunction = useI18nFunction()

  const { errors, validate } = useValidation(
    schema.projectCompanyForm(I18nFunction),
  )

  useEffect(() => {
    const cancelablePromise = validate(projectCompanyForm)
    return cancelablePromise.cancel
  }, [validate, projectCompanyForm])

  const { visited, visitField, visitFields } = useVisited()

  const handleBlur = (e) => visitField(e.target.name)

  const handleChange = (e) => {
    updateProjectCompanyForm({ [e.target.name]: e.target.value })
  }

  let hasErrors = Object.keys(errors).length > 0

  const handleSubmit = () => {
    if (hasErrors) {
      visitFields(Object.keys(errors))
    } else {
      if (
        !selectedProjectCompanies?.find(
          (company) =>
            company?.organizationNumber ===
            projectCompanyForm?.organizationNumber,
        )
      ) {
        selectProjectCompany(projectId, projectCompanyForm)
      }
      clearProjectCompanyForm()
      visited['organizationName'] = false
      visited['organizationNumber'] = false
    }
  }

  return (
    <FormWrapper>
      <SubHeader>
        <I18n id="Select_company" />
      </SubHeader>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Name>
            <I18n id="connection_form.labels.country" />
          </Name>
          <InputWrapper>
            <CountrySelector
              name="country"
              value={projectCompanyForm?.country}
              visited={visited.country}
              error={errors.country}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>
        </Row>
        <Row>
          <Name>
            <I18n id="connection_form.labels.organization_name" />
          </Name>
          <InputWrapper>
            {projectCompanyForm?.country === 'NO' ? (
              <OrganizationInput
                type="text"
                name="organizationName"
                country="NO"
                value={projectCompanyForm?.organizationName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.organizationName}
                visited={visited.organizationName}
              />
            ) : (
              <Input
                type="text"
                name="organizationName"
                value={projectCompanyForm?.organizationName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.organizationName}
                visited={visited.organizationName}
              />
            )}
          </InputWrapper>
        </Row>
        <Row>
          <Name>
            <I18n id="connection_form.labels.organization_number" />
          </Name>
          <InputWrapper>
            <Input
              name="organizationNumber"
              id="organizationNumber"
              value={projectCompanyForm?.organizationNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.organizationNumber}
              visited={visited.organizationNumber}
            />
          </InputWrapper>
        </Row>
        <Actions>
          <Button
            type="submit"
            small
            onClick={() => handleSubmit()}
            inactive={hasErrors}
            pending={pending}
          >
            <I18n id="Add" />
          </Button>
        </Actions>
      </Form>
    </FormWrapper>
  )
}

const Form = styled.form`
  margin: 0 auto;
`

const FormWrapper = styled.div`
  width: 100%;
  height: 40%;
  background-color: ${(props) => props.theme.pageBackground};
  border-radius: 5px;
  padding: 1em;
  margin: 0em 0em;
`

const SubHeader = styled.h2`
  margin: 0;
  margin-bottom: 0.5em;
  color: ${(props) => props.theme.text};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding-top: 1em;
`

const Name = styled.div`
  display: flex;
  width: 25%;
  align-items: center;
  color: ${({ theme: { subtleText } }) => subtleText};
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: 1em;
`
const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1em 0em;
`

const mapStateToProps = (state, props) => ({
  projectCompanyForm: getProjectCompanyForm(state),
})

const mapDispatchToProps = {
  updateProjectCompanyForm,
  clearProjectCompanyForm,
  selectProjectCompany,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCompanyForm)
