import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import checkmarkIcon from '../../icons/checkmark_circled_filled.svg'
import attentionIcon from '../../icons/circled_alert_icon.svg'
import caretRight from '../../icons/caret-right_light_gray.svg'
import caretRightActive from '../../icons/caret-right_gray.svg'
import I18n from '../I18n'
import { useI18nFunction } from '../I18n'
import { setActiveConnection, unsetActiveConnection } from '../../redux/actions'
import { getActiveConnectionDataFromClickedListItem } from '../ConnectionForm/utils'
import { getActiveConnection } from '../../redux/selectors'
import {
  USER_OWNERSHIP,
  PUBLIC_DATA_OWNERSHIP,
  RELATION_OWNERSHIP,
  RELATION_PUBLIC_DATA_OWNERSHIP,
} from '../../connectionTypes'

const IndirectOwnershipListItem = ({
  Case,
  isRole,
  activeConnection,
  setActiveConnection,
  unsetActiveConnection,
  active,
  disabledInteraction,
  selectedCases,
  toggleCase
  }) => {

    const I18nFunction = useI18nFunction()

    const getConnectionType = () => {
      if (Case?.userOwnership != null) {
        return USER_OWNERSHIP
      } else if (Case?.userPublicDataOwnership != null) {
        return PUBLIC_DATA_OWNERSHIP
      } else if (Case?.relationOwnership != null) {
        return RELATION_OWNERSHIP
      } else if (Case?.relationPublicDataOwnership != null) {
        return RELATION_PUBLIC_DATA_OWNERSHIP
      }
    }

    const connectionType = getConnectionType()
    const checked = [USER_OWNERSHIP, RELATION_OWNERSHIP].includes(connectionType)

    const onClick = (e) => {
      const activeConnectionData = getActiveConnectionDataFromClickedListItem(
        Case,
      )
  
      if (!disabledInteraction) {
        unsetActiveConnection()
        active
          ? unsetActiveConnection()
          : setActiveConnection({
              id: activeConnectionData.id,
              caseId: Case?.id,
              relationId: Case?.relationId,
              connectionType: connectionType,
              directConnectionCaseId: Case?.directConnectionCaseId,
              parentCaseId: Case?.parentCaseId,
              connection: activeConnectionData,
            })
      }
    }

    const getSharePercentage = () => {
      const ownershipData =
        Case.userOwnership || Case.userPublicDataOwnership || Case.relationOwnership || Case.relationPublicDataOwnership
      if (ownershipData?.stakePercentage != null) {
        return I18nFunction( { 
          id:'share_percentage',
          share: parseFloat(ownershipData.stakePercentage).toFixed(2)
        })
      } 
      else {
        return ""
      }
    }

    return (
    <li>
      <Wrapper>
        <ToggleWrapper>
          <input
            type="checkbox"
            checked={!!selectedCases[Case.id]}
            onChange={() => {
              toggleCase(Case.id)
              unsetActiveConnection()
            }}
          />
        </ToggleWrapper>
        <CaseNumber>
          #{Case.id}
        </CaseNumber>
        <Description className={[
            active && 'active',
            !checked && 'attention',
            !!selectedCases[Case.id] && 'toggled',
            disabledInteraction && 'disabledInteraction',
          ]}
          onClick={onClick}>
          <Organization>
            <OrganizationName>
              {Case.organizationName}
            </OrganizationName>
            <OrganizationNumber>
              {Case.organizationNumber}
            </OrganizationNumber >
          </Organization>
          <StakePercentage>
            {!isRole && getSharePercentage()}
          </StakePercentage>
          <Status>
            <CheckedOrAttentionMarker
                    src={checked ? checkmarkIcon : attentionIcon}
                    title={
                      checked ? (
                        <I18n id="Confirmed" />
                      ) : (
                        <I18n id="Waiting_for_confirmation" />
                      )
                    }
                  />
          </Status>
          <Action>
            <CaretRight src={active ? caretRightActive : caretRight}/>
          </Action>
        </Description>
      </Wrapper>
    </li>
  )}

const Wrapper = styled.div`
  min-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`

const ToggleWrapper = styled.div`
  display: flex;
  width: 4%;
  align-items: center;
`

const CaseNumber = styled.div`
  width: 9%
  color: ${({ theme: { subtleText } }) => subtleText};  
`

const Description = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 87%;
  box-shadow: rgb(219 219 219) 0px 2px 5px;
  background-color: ${({ theme }) => theme.primaryBackground};
  padding: 0.8em;
  padding-left: 1em;
  padding-right: 1em;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.listItemHover};
  }

  &.active {
    background-color: ${({ theme }) => theme.listItemHover};
  }
  &.attention {
  }
  &.disabledInteraction {
    background-color: ${({ theme }) => theme.pageBackground};
  }
`

const Organization = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  align-items: center;
  gap: 0.5em;
`
const OrganizationName = styled.div`
`
const OrganizationNumber = styled.div`
  color: ${({ theme: { lightText } }) => lightText};
`
const StakePercentage = styled.div`
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 6%
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 2%;
`

const CheckedOrAttentionMarker = styled.img`
  height: 1.5em;
  width: 1.5em;
`

const CaretRight = styled.img`
  height: 1.5em;
  width: 1.5em;
`

const mapStateToProps = (state, props) => {
  const activeConnection = getActiveConnection(state)
  const active = (activeConnection?.caseId === props.Case.id)
  
  return {
    active: active,
    activeConnection: activeConnection
  }
}

const mapDispatchToProps = { setActiveConnection, unsetActiveConnection }

export default connect(mapStateToProps, mapDispatchToProps)(IndirectOwnershipListItem)

