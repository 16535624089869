import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import bellIcon from '../../icons/bell.svg'
import {
  fetchNotifications,
  setNotificationsPageIndex,
  updateNotificationsStatus,
} from '../../redux/actions'
import { DEFAULT_NOTIFICATION_PAGE_INDEX } from '../../redux/currentUser'
import {
  getNotifications,
  getNotificationsLoading,
  getNotificationsPageIndex,
  getNotificationPagination,
  getUserSessionExpired,
} from '../../redux/selectors'
import I18n from '../I18n'
import NotificationList from './NotificationList'
const NotificationCenter = ({
  notifications,
  updateNotificationsStatus,
  userSessionExpired,
  fetchNotifications,
  selected,
  onToolClicked,
  Backdrop,
  setNotificationsPageIndex,
  notificationsPageIndex,
  pagination,
  notificationsLoading,
}) => {
  const [readFilter, setReadFilter] = useState(false)
  const [allNotifications, setAllNotifications] = useState([])
  const [filteredNotifications, setFilteredNotifications] = useState([])
  const isOpen = selected

  useEffect(() => {
    setAllNotifications(notifications)
  }, [notifications])
  useEffect(() => {
    filterNotificationsByRead(readFilter)
  }, [allNotifications])
  useEffect(() => {
    fetchNotifications(DEFAULT_NOTIFICATION_PAGE_INDEX, readFilter)
  }, [readFilter])

  const unseenNotificationIds = () =>
    allNotifications.filter((n) => !n.seen).map((n) => n.id)

  const unreadNotificationIds = () =>
    allNotifications.filter((n) => !n.read).map((n) => n.id)

  const onMarkAllReadClicked = () => {
    unreadNotificationIds().length > 0 &&
      updateNotificationsStatus({ ids: unreadNotificationIds(), read: true })
    setAllNotifications(
      allNotifications.map((n) => {
        return {
          ...n,
          read: true,
        }
      }),
    )
  }

  const onToggleNotificationCenter = () => {
    onToolClicked()
    const newOpenStatus = !isOpen
    if (newOpenStatus === true) {
      unseenNotificationIds().length > 0 &&
        updateNotificationsStatus({ ids: unseenNotificationIds(), seen: true })
      setAllNotifications(
        allNotifications.map((n) => {
          return {
            ...n,
            seen: true,
          }
        }),
      )
    }
  }

  const filterNotificationsByRead = (shouldFilter) => {
    setReadFilter(shouldFilter)
    setFilteredNotifications(
      shouldFilter ? allNotifications.filter((n) => !n.read) : allNotifications,
    )
  }

  return (
    <Wrapper>
      <Bell onClick={() => onToggleNotificationCenter()}>
        <img src={bellIcon}></img>

        {allNotifications.filter((l) => !l.seen)?.length > 0 && (
          <NotificationCountWrapper>
            <NotificationCount>
              {allNotifications.filter((l) => !l.seen).length < 100
                ? allNotifications.filter((l) => !l.seen).length
                : '99'}
            </NotificationCount>
          </NotificationCountWrapper>
        )}
      </Bell>
      {isOpen && (
        <>
          <Backdrop />
          <Dropdown>
            <NotificationsHeader>
              <NotificationsHeaderHeader>
                <NotificationsTitle>
                  <img src={bellIcon}></img>
                  <span>
                    <I18n id="notification_title" />{' '}
                  </span>
                </NotificationsTitle>
                <NotificationsFilters>
                  <FilterOption
                    onClick={() => filterNotificationsByRead(false)}
                    className={!readFilter ? 'active' : ''}
                  >
                    <I18n id="All" />
                  </FilterOption>
                  <FilterOption
                    onClick={() => filterNotificationsByRead(true)}
                    className={readFilter ? 'active' : ''}
                  >
                    <I18n id="Unread" />
                  </FilterOption>
                </NotificationsFilters>
              </NotificationsHeaderHeader>
              {filteredNotifications?.length > 0 && (
                <NotificationOptions onClick={onMarkAllReadClicked}>
                  <I18n id="Mark_all_read" />
                </NotificationOptions>
              )}
            </NotificationsHeader>

            <NotificationList
              filteredNotifications={filteredNotifications}
              fetchNotifications={fetchNotifications}
              pagination={pagination}
              notificationsPageIndex={notificationsPageIndex}
              setNotificationsPageIndex={setNotificationsPageIndex}
              updateNotificationsStatus={updateNotificationsStatus}
              notificationsLoading={notificationsLoading}
              readFilter={readFilter}
            ></NotificationList>
          </Dropdown>
        </>
      )}
    </Wrapper>
  )
}

const NotificationOptions = styled.div`
  width: fit-content;
  background-color: white;
  padding: 0.5em 1.2em;
  color: ${({ theme: { secondary } }) => secondary};
  &:hover {
    cursor: pointer;
  }
`

const NotificationsHeaderHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`

const NotificationsHeader = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const NotificationsFilters = styled.div`
  display: flex;
  padding: 0.5em 1em;
`

const FilterOption = styled.div`
  display: flex;
  margin: 0 0.5em;
  border-radius: 8px;
  padding: 0.2em;
  cursor: pointer;
  color: #666;
  font-weight: bold;

  &.active {
    color: ${({ theme: { inverseText } }) => inverseText};
    background-color: ${({ theme: { secondary } }) => secondary};
  }
`

const NotificationsTitle = styled.h1`
  font-size: ${({ theme: { largeText } }) => largeText};
  color: black;
  padding: 1em;
  margin: 0;
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
    color: #666;
  }
  img {
    width: 1.5em;
    height: 1.5em;
  }

  * {
    margin: 0 0.2em;
  }
`

const Wrapper = styled.div`
  z-index: 1;
  position: relative;
  margin-left: auto;
  align-self: stretch;
  align-items: center;
  display: flex;
`

const Bell = styled.div`
  position: relative;
  cursor: pointer;
  img {
    width: 1.8em;
    height: 1.8em;
  }
  transform: translateY(0.125em);
  &:hover {
    opacity: 0.8;
  }
`

const NotificationCount = styled.div`
  font-size: 10px;
`

const NotificationCountWrapper = styled.div`
  position: absolute;
  top: -5%;
  right: -15%;
  width: 1em;
  height: 1em;
  line-height: 1em;
  background-color: #ff9800;
  border-radius: 50%;
  padding: 0.6em;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Dropdown = styled.div`
  background-color: white;
  display: block;
  box-shadow: rgb(219 219 219) 0px 2px 8px;
  border-radius: 5px;
  top: 100%;
  right: 0;
  width: 25vw;
  position: absolute;
  margin-top: 1em;
  max-height: 150vh;

  & > :not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  overflow-y: auto;
  overflow-x: hidden;

  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const mapDispatchToProps = {
  updateNotificationsStatus,
  fetchNotifications,
  setNotificationsPageIndex,
}

const mapStateToProps = (state) => ({
  notifications: getNotifications(state) || [],
  notificationsPageIndex: getNotificationsPageIndex(state),
  pagination: getNotificationPagination(state),
  userSessionExpired: getUserSessionExpired(state),
  notificationsLoading: getNotificationsLoading(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter)
