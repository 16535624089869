import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Page from '../components/Page'
import CaseHeader from '../components/CaseHeader'
import Button from '../components/Button'
import I18n from '../components/I18n'
import ChangeCaseStatusModal from '../components/ChangeCaseStatusModal'
import ManagerStatementModal from '../components/ManagerStatementModal'
import BoardStatementModal from '../components/BoardStatementModal'
import CaseDetails from '../components/CaseDetails'
import CaseNotFound from './CaseNotFound'

import {
  fetchUser,
  fetchCase,
  fetchCompany,
  fetchRelation,
} from '../redux/actions'
import {
  getUser,
  shouldFetchUser,
  isUserFetching,
  isAdmin,
  isManager,
  getCase,
  shouldFetchCase,
  getCaseFetchStatus,
  getCompany,
  isCompanyPending,
  getRelation,
  getCurrentUserId,
  shouldFetchRelation,
} from '../redux/selectors'

const Case = ({
  user,
  shouldFetchRelation,
  relation,
  Case,
  company,
  caseId,
  shouldFetchUser,
  fetchUser,
  shouldFetchCase,
  fetchCase,
  fetchCompany,
  fetchRelation,
  pending,
  isAdmin,
  isManager,
  currentUserId,
}) => {
  const [showChangeCaseStatusModal, setShowChangeCaseStatusModal] =
    useState(false)

  const [showManagerStatementModal, setShowManagerStatementModal] =
    useState(false)

  const [showBoardStatementModal, setShowBoardStatementModal] = useState(false)

  const [latestDocumentUpload, setLatestDocumentUpload] = useState({})

  const getLatestDocumentUpload = (Case) => {
    if (Array.isArray(Case?.documentUploads) && Case?.documentUploads?.length) {
      const caseUploads = Case?.documentUploads?.filter(
        (c) =>
          !c?.applicationId &&
          c?.documentAction != 'case_document_download_api',
      )
      if (Array.isArray(caseUploads) && caseUploads?.length) {
        let latest = caseUploads?.reduce((prev, current) => {
          return prev?.id > current?.id ? prev : current
        })
        return latest
      }
    }
  }
  useEffect(() => {
    let latest = getLatestDocumentUpload(Case)
    setLatestDocumentUpload(latest)
  }, [Case])

  useEffect(() => {
    caseId && shouldFetchCase && fetchCase(caseId)
  }, [shouldFetchCase, fetchCase, caseId])

  useEffect(() => {
    Case.userId && shouldFetchUser && fetchUser(Case.userId)
  }, [shouldFetchUser, fetchUser, Case.userId])

  useEffect(() => {
    shouldFetchRelation && fetchRelation(Case.relationId, Case.userId)
  }, [fetchRelation, shouldFetchRelation])

  useEffect(() => {
    Case.organizationNumber && fetchCompany(Case.organizationNumber)
  }, [fetchCompany, Case.organizationNumber])
  const onChangeStatusButtonClicked = () => setShowChangeCaseStatusModal(true)
  const onAddManagerStatementButtonClicked = () =>
    setShowManagerStatementModal(true)
  const onAddBoardStatementButtonClicked = () =>
    setShowBoardStatementModal(true)

  const showManagerStatementAction = () => {
    if (
      process.env.REACT_APP_MANAGER_STATEMENT_ENABLED?.toLowerCase() ===
        'true' &&
      (isManager || isAdmin) &&
      currentUserId != user.id
    ) {
      return (
        <CaseActionButton
          small
          type="submit"
          onClick={() => onAddManagerStatementButtonClicked()}
        >
          <span>
            {Case.managerStatement ? (
              <I18n id="change_manager_statement" />
            ) : (
              <I18n id="provide_manager_statement" />
            )}
          </span>
        </CaseActionButton>
      )
    }
  }

  const showBoardStatementAction = () => {
    if (
      process.env.REACT_APP_MANAGER_STATEMENT_ENABLED?.toLowerCase() ===
        'true' &&
      isAdmin &&
      currentUserId != user.id) {
      return (
        <CaseActionButton
          small
          type="submit"
          onClick={() => onAddBoardStatementButtonClicked()}
        >
          <span>
            {Case.boardStatement ? (
              <I18n id="change_board_statement" />
            ) : (
              <I18n id="provide_board_statement" />
            )}
          </span>
        </CaseActionButton>
      )
    }
  }

  const showChangeCaseStatusAction = () => {
    if (isAdmin && currentUserId != user.id) {
      return (
        <CaseActionButton
          small
          type="submit"
          onClick={() => onChangeStatusButtonClicked()}
        >
          <span>
            <I18n id="Edit" /> Status{' '}
          </span>
        </CaseActionButton>
      )
    }
  }

  return (Case.fetchStatus != 'failed' ? (
    <Page pending={pending}>
      {showChangeCaseStatusModal && (
        <ChangeCaseStatusModal
          Case={Case}
          onClose={() => setShowChangeCaseStatusModal(false)}
        />
      )}
      {showManagerStatementModal && (
        <ManagerStatementModal
          caseId={Case.id}
          status={Case.status}
          managerStatement={Case.managerStatement}
          onClose={() => setShowManagerStatementModal(false)}
        ></ManagerStatementModal>
      )}
      {showBoardStatementModal && (
        <BoardStatementModal
          caseId={Case.id}
          status={Case.status}
          boardStatement={Case.boardStatement}
          latestDocumentUpload={latestDocumentUpload}
          onClose={() => setShowBoardStatementModal(false)}
        ></BoardStatementModal>
      )}
      <CaseHeader
        isAdmin={isAdmin}
        user={user}
        Case={Case}
        company={company}
        relation={relation}
      ></CaseHeader>
      <CaseActionsWrapper>
        <CaseActions>
          {showManagerStatementAction()}
          {showBoardStatementAction()}
          {showChangeCaseStatusAction()}
        </CaseActions>
      </CaseActionsWrapper>
      <Content>
        <BottomContent>
          <CaseDetails
            user={user}
            Case={Case}
            company={company}
            relation={relation}
            isAdmin={isAdmin}
            isManager={isManager}
          ></CaseDetails>
        </BottomContent>
      </Content>
    </Page>
    ) : (
        <CaseNotFound/>
    )
  )
}

const mapStateToProps = (state, props) => {
  const Case = getCase(state, props.caseId) || {}
  const user = getUser(state, Case.userId) || {}
  const company = getCompany(state, Case.organizationNumber) || {}
  const relation = getRelation(state, Case.relationId) || {}

  return {
    shouldFetchCase: shouldFetchCase(state, props.caseId),
    Case,
    user,
    relation,
    company,
    isAdmin: isAdmin(state),
    isManager: isManager(state),
    pending:
      getCaseFetchStatus(state, props.caseId) != 'succeeded' ||
      (Case && Case.userId && isUserFetching(state, Case.userId)) ||
      (Case &&
        Case.organizationNumber &&
        isCompanyPending(state, Case.organizationNumber)),
    shouldFetchUser: shouldFetchUser(state, Case.userId),
    shouldFetchRelation:
      Case.relationId &&
      Case.userId &&
      shouldFetchRelation(state, Case.relationId),
    currentUserId: getCurrentUserId(state),
  }
}

const mapDispatchToProps = {
  fetchUser,
  fetchCase,
  fetchCompany,
  fetchRelation,
}

export default connect(mapStateToProps, mapDispatchToProps)(Case)

const CaseActionButton = styled(Button)`
  background: #0c3689;
  border: 2px solid #0c3689;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 13em;
`

const CaseActionsWrapper = styled.div`
  background-color: ${(props) => props.theme.secondaryBackground};
  padding: 0.5em;
`

const CaseActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};

  & > * {
    margin: 0 0.5em;
  }
`
const BottomContent = styled.div`
  display: flex;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
  padding-bottom: 5em;
`