import React, { useState }  from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getCase } from '../redux/selectors'
import CardDataList from './CardDataList'
import {
  RELATION_OTHER_CONNECTION_CASE,
  RELATION_OWNERSHIP_CASE,
  RELATION_INDIRECT_OWNERSHIP_CASE,
  USER_OTHER_CONNECTION_CASE,
  USER_OWNERSHIP_CASE,
  USER_INDIRECT_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  USER_INDIRECT_ROLE_CASE,
  RELATION_ROLE_CASE,
  RELATION_INDIRECT_ROLE_CASE
} from '../caseTypes'
import { fields as ownershipFields } from './ConnectionForm/OwnershipForm'
import { fields as roleFields } from './ConnectionForm/RoleForm'
import { fields as otherConnectionFields } from './ConnectionForm/OtherConnectionForm'
import { capitalize } from '../utils'
import { getDateWithoutTime } from '../dateUtils'
import DataListCompare from './DataListCompare'
import I18n from './I18n'
import { useI18nFunction } from './I18n'
import { createdOn, updatedOn } from './ConnectionForm/fields'
import { Link } from 'react-router-dom'

const CaseConnectionDetails = ({ Case, application, directConnectionCase, isIndirect }) => {
  switch (Case.caseType) {
    case USER_OWNERSHIP_CASE:
    case USER_INDIRECT_OWNERSHIP_CASE:
    case USER_INDIRECT_ROLE_CASE:
      return (
        <OwnershipDetails
          userData={Case.userOwnership}
          publicData={Case.userPublicDataOwnership}
          application={application}
          Case={Case}
          directConnectionCase={directConnectionCase}
          isIndirect={isIndirect}
        ></OwnershipDetails>
      )
    case RELATION_OWNERSHIP_CASE:
    case RELATION_INDIRECT_OWNERSHIP_CASE:
    case RELATION_INDIRECT_ROLE_CASE:
      return (
        <OwnershipDetails
          userData={Case.relationOwnership}
          publicData={Case.relationPublicDataOwnership}
          directConnectionCase={directConnectionCase}
          isIndirect={isIndirect}
        ></OwnershipDetails>
      )
    case USER_ROLE_CASE:
      return (
        <RoleDetails
          userData={Case.userRole}
          publicData={Case.userPublicDataRole}
          application={application}
          Case={Case}
        ></RoleDetails>
      )
    case RELATION_ROLE_CASE:
      return (
        <RoleDetails
          userData={Case.relationRole}
          publicData={Case.relationPublicDataRole}
        ></RoleDetails>
      )
    case USER_OTHER_CONNECTION_CASE:
      return (
        <OtherConnectionDetails
          userData={Case.userOtherConnection}
          application={application}
        ></OtherConnectionDetails>
      )
    case RELATION_OTHER_CONNECTION_CASE:
      return (
        <OtherConnectionDetails
          userData={Case.relationOtherConnection}
        ></OtherConnectionDetails>
      )
    default:
      return <p>No data available</p>
  }
}

const OtherConnectionDetails = ({ userData, application }) => {
  return (
    <React.Fragment>
      <DatalistWrapper>
        <Title>
          <I18n id="Other_connection" />
        </Title>
        <CardDataList
          userData={userData}
          application={application}
          data={otherConnectionFields.map(({ key, label }) => [
            label,
            userData?.[key] ? i18nConnectionValue(key, userData[key]) : undefined,
          ])}
        />
      </DatalistWrapper>
    </React.Fragment>
  )
}

const RoleDetails = ({ userData, publicData, application, Case }) => {
  const roleOrder = [
    'updatedOn',
    'roleType',
    'title',
    'postPlace',
    'monthlyReward',
    'isCompanyRegistered',
    'country',
    'organizationName',
    'organizationNumber',
    'publicDataTitle',
    'monthlyTimeCommitment',
    'fromDate',
    'toDate',
    'comment',
    'createdOn',
  ]

  const fields = [...roleFields, createdOn(), updatedOn()].reduce(
    (map, obj) => {
      map[obj.key] = obj
      return map
    },
    {},
  )
  const expandedDisplayedFields = roleOrder.map((e) => fields[e])
  const shortDisplayFields = expandedDisplayedFields.slice(0, 5)

  const [showMore, setshowMore] = useState(false)
  const onClick = () => setshowMore((showMore) => !showMore)
  return (
    <React.Fragment>
      <DatalistWrapper>
        <Title>
          {Case ? (
            <I18n id="ConnectionType" caseType={Case.caseType} />
          ) : (
            <I18n id="Other_connection" />
          )}
        </Title>
        <DataListCompare
          userData={userData}
          publicData={publicData}
          application={application}
          shortData={shortDisplayFields.map(({ key, label }) => [
            label,
            userData?.[key] ? i18nConnectionValue(key, userData[key]) : undefined,
            publicData?.[key] ? i18nConnectionValue(key, publicData[key]) : undefined,
            application?.[key]
              ? i18nConnectionValue(key, application[key])
              : undefined,
          ])}
          data={expandedDisplayedFields.map(({ key, label }) => [
            label,
            userData?.[key] ? i18nConnectionValue(key, userData[key]) : undefined,
            publicData?.[key] ? i18nConnectionValue(key, publicData[key]) : undefined,
            application?.[key]
              ? i18nConnectionValue(key, application[key])
              : undefined,
          ])}
          showMore={showMore}
        />
        <MoreInfo onClick={onClick}>
          {showMore ? <I18n id="ShowLess" /> : <I18n id="ShowMore" />}
        </MoreInfo>
      </DatalistWrapper>
    </React.Fragment>
  )
}

const OwnershipDetails = ({ userData, publicData, application, Case, directConnectionCase, isIndirect }) => {
  const ownershipOrder = [
    'updatedOn',
    'isCompanyListed',
    'stockCount',
    'country',
    'organizationName',
    'organizationNumber',
    'shareType',
    'ownershipStatus',
    'isCompanyRegistered',
    'fromDate',
    'toDate',
    'comment',
    'createdOn',
  ]

  const fields = [...ownershipFields, createdOn(), updatedOn()].reduce(
    (map, obj) => {
      map[obj.key] = obj
      return map
    },
    {},
  )
  const expandedDisplayedFields = ownershipOrder.map((e) => fields[e])
  const shortDisplayFields = expandedDisplayedFields.slice(0, 4)

  const defaultShowMore =
    process.env.REACT_APP_APPLICATIONS_ENABLED?.toLowerCase() === 'true'
      ? false
      : true

  const [showMore, setshowMore] = useState(defaultShowMore)
  const onClick = () => setshowMore((showMore) => !showMore)

  const I18nFunction = useI18nFunction()

  return (
    <React.Fragment>
      <DatalistWrapper>
        {Case && (
          <Header>
            <Title>
              <I18n id="ConnectionType" caseType={Case.caseType} />
            </Title>
            {isIndirect && directConnectionCase?.id &&
            <DirectCase>
              <DirectCaseLink/>
                <DirectName>
                  {I18nFunction( { 
                    id:'indirect_case',
                    parent_case_name: directConnectionCase.organizationName
                  })}
                </DirectName>
              <DirectCaseLink>
                (<CaseLink to={`/cases/${directConnectionCase.id}`}>
                  <I18n id="Go_to_case" />
                </CaseLink>)
              </DirectCaseLink>
            </DirectCase>
            }
          </Header>
        )}
        <DataListCompare
          userData={userData}
          publicData={publicData}
          application={application}
          shortData={shortDisplayFields.map(({ key, label }) => [
            label,
            userData?.[key] ? i18nConnectionValue(key, userData[key]) : undefined,
            publicData?.[key] ? i18nConnectionValue(key, publicData[key]) : undefined,
            application?.[key]
              ? i18nConnectionValue(key, application[key])
              : undefined,
          ])}
          data={expandedDisplayedFields.map(({ key, label }) => [
            label,
            userData?.[key] ? i18nConnectionValue(key, userData[key]) : undefined,
            publicData?.[key] ? i18nConnectionValue(key, publicData[key]) : undefined,
            application?.[key]
              ? i18nConnectionValue(key, application[key])
              : undefined,
          ])}
          showMore={showMore}
        />
        <MoreInfo onClick={onClick}>
          {showMore ? <I18n id="ShowLess" /> : <I18n id="ShowMore" />}
        </MoreInfo>
      </DatalistWrapper>
    </React.Fragment>
  )
}

const i18nConnectionValue = (key, value) => {
  switch (key) {
    case 'roleType':
      return <I18n id={`connection_form.role_types.${value}`} />
    case 'shareType':
      return <I18n id={`connection_form.share_type.${value}`} />
    case 'ownershipStatus':
      return <I18n id={`connection_form.ownership_status.${value}`} />
    case 'country':
      return <I18n country={value} />
    case 'isResidentAbroad':
    case 'isCompanyListed':
    case 'isSupplier':
    case 'isClient':
      return value && <I18n id={capitalize(value)} />
    case 'updatedOn':
      return value && getDateWithoutTime(value)
    case 'createdOn':
      return value && getDateWithoutTime(value)
    case 'fromDate':
      return value && getDateWithoutTime(value)
    case 'toDate':
      return value && getDateWithoutTime(value)
    default:
      return value
  }
}

const DatalistWrapper = styled.div`
  overflow-x: auto;
  word-break: break-word;

  margin-bottom: 10px;

  padding: 1.5em;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};

  border-radius: 10px;
`
const MoreInfo = styled.div`
  color: ${({ theme }) => theme.secondary};
  margin: 0;
  padding-top: 1em;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  &:disabled {
    cursor: initial;
  }
`

const Header = styled.div`
  margin-bottom: 1em;
`

const Title = styled.div`
  font-size: ${(props) => props.theme.largeText};
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.5em;
`

const DirectName = styled.div`
  font-size: ${(props) => props.theme.normalText};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  font-weight: bold;
`

const DirectCaseLink = styled.div`
  font-size: ${(props) => props.theme.normalText};
  font-weight: bold;
`

const DirectCase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
  gap: 0.5em;
`

const CaseLink = styled(Link)`
  color: ${({ theme: { secondary } }) => secondary};
  text-decoration: none;
`

const mapStateToProps = (state, props) => {
  return {
    directConnectionCase: getCase(state, props.Case.directConnectionCaseId)
  }
}


export default connect(mapStateToProps)(CaseConnectionDetails)
