import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import CaseCompanyCard from './CaseCompanyCard'
import CaseUserCard from './CaseUserCard'
import CaseRelationCard from './CaseRelationCard'
import CaseHistory from '../components/CaseHistory/index'
import {
  RELATION_INDIRECT_OWNERSHIP_CASE,
  USER_INDIRECT_OWNERSHIP_CASE,
  USER_INDIRECT_ROLE_CASE,
  RELATION_INDIRECT_ROLE_CASE
} from '../caseTypes'
import CaseConnectionDetails from './CaseConnectionDetails'
import { getLatestApplication } from './caseProps'
import { RELATION } from '../connectionTypes'
import CaseActiveApplicationItem from './CaseActiveApplicationItem'
import IndirectCases from './IndirectCases'

const CaseDetails = ({ Case, user, relation, company, isAdmin, isManager }) => {
  const [application, setApplication] = useState(null)

  const isIndirect = [
    RELATION_INDIRECT_OWNERSHIP_CASE,
    USER_INDIRECT_OWNERSHIP_CASE,
    USER_INDIRECT_ROLE_CASE,
    RELATION_INDIRECT_ROLE_CASE].includes(Case.caseType)

  useEffect(() => {
    if (Case.id) {
      const application = getLatestApplication(Case)
      if (application) {
        setApplication(application)
      }
    }
  }, [Case])
  return (
    <Content>
      <FirstColumn>
        {!Case?.caseType?.includes(RELATION) && <CaseUserCard user={user} />}
        <CaseCompanyCard Case={Case} company={company} />
      </FirstColumn>
      <MiddleColumn>
        {Case?.caseType?.includes(RELATION) ? (
          <>
            <CaseRelationCard relation={relation} />
            <CaseConnectionDetails Case={Case} application={application} isIndirect={isIndirect} />
          </>
        ) : (
          <CaseConnectionDetails Case={Case} application={application} isIndirect={isIndirect} />
        )}
        {!isIndirect && <IndirectCases Case={Case} />}
      </MiddleColumn>
      <LastColumn>
        <CaseHistory Case={Case}></CaseHistory>
        {!Case.caseType?.includes('relation') &&
          process.env.REACT_APP_APPLICATIONS_ENABLED?.toLowerCase() ===
            'true' && (
            <CaseActiveApplicationItem
              Case={Case}
              isAdmin={isAdmin}
              isManager={isManager}
            ></CaseActiveApplicationItem>
          )}
      </LastColumn>
    </Content>
  )
}

const Content = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.5em;
`

const FirstColumn = styled.div`
  width: 25%;
  margin: 0 1em 1em 0;
`

const LastColumn = styled.div`
  width: 30%;
  margin: 0 1em 1em 0;
`
const MiddleColumn = styled.div`
  width: 45%;
  margin: 0 1em 1em 0;
`

export default CaseDetails
