import React from 'react'
import styled from 'styled-components'
import Page from '../components/Page'
import Button from '../components/Button'
import { height } from '../components/Header'
import { footerHeight } from '../components/Footer'
import I18n from '../components/I18n'
import microsoft_icon from '../icons/microsoft_logo.svg'

const Login = () => {
  const handleSignIn = () => {
    window.location.href = "/login"    
  }

  return (
    <Page>
      <Wrapper>
        <Content>
          <SigninCard>
            <h1>
              <I18n id="Welcome" />
            </h1>
            <Button onClick={handleSignIn}>
              <Icon src={microsoft_icon}/>
              <I18n id="Log_in" />
            </Button>
          </SigninCard>
        </Content>
        </Wrapper>
    </Page>
  )
}

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - ${height} - ${footerHeight});
`

const Content = styled.div`
  margin: auto;
  text-align: center;
  font-size: ${(props) => props.theme.largeText};
  padding-bottom: 3em;
`

/* Card for the sign-in form */
const SigninCard = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`

const Icon = styled.img`
  margin-right: 10px;
  height: 22px;
  width: auto;
`

export default Login
