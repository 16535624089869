import { get, post } from '../fetch'
import { fetchSaga } from '../sagas'
import * as schema from '../schema'

import { takeEvery } from 'redux-saga/effects'
import { relation_proff, user } from '../schema'
import {
  FETCH_PROFF_PERSON_SUGGESTIONS,
  FETCH_PROFF_PERSON_SUGGESTIONS_FAILED,
  FETCH_PROFF_PERSON_SUGGESTIONS_SUCCEEDED,
  FETCH_RELATION_PROFF_PERSON_SUGGESTIONS,
  FETCH_RELATION_PROFF_PERSON_SUGGESTIONS_FAILED,
  FETCH_RELATION_PROFF_PERSON_SUGGESTIONS_SUCCEEDED,
  SET_PROFF_PERSON,
  SET_PROFF_PERSON_FAILED,
  SET_PROFF_PERSON_SUCCEEDED,
  SET_RELATION_PROFF_PERSON,
  SET_RELATION_PROFF_PERSON_FAILED,
  SET_RELATION_PROFF_PERSON_SUCCEEDED,
} from './index'

function* saga() {
  yield takeEvery(
    FETCH_PROFF_PERSON_SUGGESTIONS,
    fetchSaga({
      SUCCEEDED: FETCH_PROFF_PERSON_SUGGESTIONS_SUCCEEDED,
      FAILED: FETCH_PROFF_PERSON_SUGGESTIONS_FAILED,
      schema: schema.arrayOfProffPersonResult,
      fetch: ({ userId }) => get(`/users/${userId}/person_suggestions`),
    }),
  )

  yield takeEvery(
    FETCH_RELATION_PROFF_PERSON_SUGGESTIONS,
    fetchSaga({
      SUCCEEDED: FETCH_RELATION_PROFF_PERSON_SUGGESTIONS_SUCCEEDED,
      FAILED: FETCH_RELATION_PROFF_PERSON_SUGGESTIONS_FAILED,
      schema: schema.arrayOfProffPersonResult,
      fetch: ({ relationId }) =>
        get(`/relations/${relationId}/person_suggestions`),
    }),
  )

  yield takeEvery(
    SET_PROFF_PERSON,
    fetchSaga({
      SUCCEEDED: SET_PROFF_PERSON_SUCCEEDED,
      FAILED: SET_PROFF_PERSON_FAILED,
      schema: user,
      fetch: ({ userId, proffShareholderId, proffPersonId }) =>
        post(`/users/${userId}/set_person`, {
          proffShareholderId: proffShareholderId,
          proffPersonId: proffPersonId,
        }),
    }),
  )

  yield takeEvery(
    SET_RELATION_PROFF_PERSON,
    fetchSaga({
      SUCCEEDED: SET_RELATION_PROFF_PERSON_SUCCEEDED,
      FAILED: SET_RELATION_PROFF_PERSON_FAILED,
      schema: relation_proff,
      fetch: ({ relationId, proffShareholderId, proffPersonId }) =>
        post(`/relations/${relationId}/set_person`, {
          proffShareholderId: proffShareholderId,
          proffPersonId: proffPersonId,
        }),
    }),
  )
}

export default saga
