import React from 'react'
import checkmarkIcon from '../icons/checkmark_circled_filled.svg'
import attentionIcon from '../icons/circled_alert_icon.svg'
import styled from 'styled-components'
import { useI18nFunction } from './I18n'
import Spinner from './SmallSpinner'
import useDelaySetFalse from '../hooks/useDelaySetFalse'

const TabContent = ({ title, completed, tabStats, image, isUserProfileDataFetching}) => {
  const I18nFunction = useI18nFunction()
  const delayedLoadingIndicator = useDelaySetFalse(isUserProfileDataFetching,250)
  return (
    <>
      <Logo src={image} alt="" />

      <span>
        {title} {delayedLoadingIndicator? <Spinner color={'secondary'}/>: `(${tabStats?.totalCases})`}
      </span>
      <img
        src={tabStats?.confirmed ? checkmarkIcon : attentionIcon}
        style={{ height: 20 }}
        title={`${I18nFunction({ id: 'Registration' })} ${
          completed ? '' : I18nFunction({ id: 'not' })
        } ${I18nFunction({ id: 'completed' })}`}
      />
    </>
  )
}

const Logo = styled.img`
  margin: 4px;
  width: 32px;
  height: 32px;
`

export default TabContent
