import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import I18n from './I18n'
import { deleteProject } from '../redux/actions'
import Button from './Button'

const DeleteProjectModal = ({ project, onClose, deleteProject }) => {

  const handleConfirmDelete = (e) => { 
    e.preventDefault()
    deleteProject(project?.id)
  }

  return (
    <Overlay onClick={onClose}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
          <Header>
            <HeaderText>
              <I18n id="Delete_project" />
            </HeaderText>
            <Exit>
              <ExitButton onClick={onClose}>
                ×
              </ExitButton>
            </Exit>
          </Header>
        <Body>
          <Text>
            <I18n id="Info_delete_project" />
          </Text>
          <Actions>
            <Button subtle onClick={onClose} small>
              <I18n id="Cancel" />
            </Button>
            <Button
              deleteButton
              small
              onClick={(e) => handleConfirmDelete(e)}>
              <I18n id="Delete" />
            </Button>
          </Actions>
        </Body>
      </ContentWrapper>
    </Overlay>
  )
}

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`

const ContentWrapper = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  width: 400px;
  -moz-box-shadow: 0px 0px 18px 6px #ccc;
  -webkit-box-shadow: 0px 0px 18px 6px #ccc;
  box-shadow: 0px 0px 18px 6px #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`


const Header = styled.div`
  background-color: ${(props) => props.theme.pageBackground};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; 
  padding: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const HeaderText = styled.h1`
  margin: 0;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  gap: 1.5em;
`

const Text = styled.div`
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.5em;
`

const Exit = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 10%;
`

const ExitButton = styled.button`
  background-color: transparent;
  margin: 0;
  border: none;
  color: ${({ theme: { lightText } }) => lightText};
  font-size: 2em;
  cursor: pointer;
  display: inline-block;
  margin: 0px;
`

const mapDispatchToProps = {
  deleteProject,
}

export default connect(null, mapDispatchToProps)(DeleteProjectModal)
