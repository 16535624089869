import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Dropdown, MenuItem } from '../Header/Dropdown'
import deleteIcon from '../../icons/trashcan.svg'
import I18n from './../I18n'
import ConfirmActionModal from './ConfirmActionModal'
import { selectCase } from '../../redux/actions'

const FormOptions = ({ deleteConnection, Case, selectCase }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const onDeleteConnection = () => {
    setModalOpen(false)
    deleteConnection()
    if (!Case?.caseType?.toLowerCase().includes("indirect")){
      selectCase(null)
    }
  }

  const caseHasPublicData =
    Case?.userPublicDataOwnership ||
    Case?.userPublicDataRole ||
    Case?.relationPublicDataOwnership ||
    Case?.relationPublicDataRole

  return (
    <Wrapper>
      {modalOpen && (
        <ConfirmActionModal
          cancel={() => setModalOpen(false)}
          confirm={onDeleteConnection}
        />
      )}
      <OptionsButton onClick={() => setIsOpen(!isOpen)} src={deleteIcon} />
      {isOpen && (
        <Dropdown>
          <MenuItem
            onClick={() => setModalOpen(true)}
            disabled={caseHasPublicData}
            inactive={caseHasPublicData}
            title={
              caseHasPublicData
                ? <I18n id='connection_form.cannot_delete_public_data_case' />
                : <I18n id='Delete' />
            }
          >
            {!caseHasPublicData && <Icon src={deleteIcon} />}
            <span>
              <I18n id="Delete" />
            </span>
          </MenuItem>
        </Dropdown>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  align-self: stretch;
`

const Icon = styled.img`
  height: 2em;
  width: 2em;
`
const OptionsButton = styled.img`
  height: 2em;
  width: 2em;
  opacity: 0.75;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`
const mapDispatchToProps = {
  selectCase
}

export default connect(null, mapDispatchToProps)(FormOptions)
