import React, { useState }  from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Button from '../components/Button'
import I18n from './I18n'
import { useI18nFunction } from './I18n'
import useToggleCases from '../hooks/useToggleCases'
import MultiApproveCasesModal from '../components/MultiApproveCasesModal'
import CasePriorityWarningMarker from './CaseListItem/CasePriorityWarningMarker'
import { StatusBadge } from '../CaseStatus'
import {
    getUserCasesOfType,
    isIndirectCasesPending,
    getCurrentUserId,
  } from '../redux/selectors'
import {
  USER_OWNERSHIP_CASE,
  USER_INDIRECT_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  USER_INDIRECT_ROLE_CASE,
  RELATION_OWNERSHIP_CASE,
  RELATION_INDIRECT_OWNERSHIP_CASE,
  RELATION_ROLE_CASE,
  RELATION_INDIRECT_ROLE_CASE,
} from '../caseTypes'
import SmallSpinner from './SmallSpinner'

const IndirectCases = ({
    userId,
    currentUserId,
    Case,
    indirectCases,
    pending,
  }) => {

  const isRole = [USER_ROLE_CASE, USER_INDIRECT_ROLE_CASE, RELATION_ROLE_CASE, RELATION_INDIRECT_ROLE_CASE].includes(Case?.caseType)
  
  const I18nFunction = useI18nFunction()

  const { toggleAllCases, selectedCaseIds, selectedCases, toggleCase, clearAllCases } =
  useToggleCases(indirectCases)

  const buttonDisabled = selectedCaseIds.length === 0 ? 1 : 0

  const handleToggleAll = () => {
    toggleAllCases()
  }

  const approveIndirect = () => {
    setShowMultiApproveCasesModal(true) 
  }

  const showRiskWarning = (item) => {
    if (item) {
      return item.positionOfInfluence ||
          item.organizationIsCustomerForUserCompany ||
          item.organizationIsSupplierForUserCompany ||
          item.isClient ||
          item.isSupplier ||
          item.isBoardMember
    }
    else {
      return false
    }
  }

  const renderIndirectTitle = () => {
    let id
    if (isRole) {
      id = indirectCases.length ? "indirect_case.indirect_connections" : "indirect_case.no_indirect_connections";
    } else {
      id = indirectCases.length ? "indirect_case.indirect_ownerships" : "indirect_case.no_indirect_ownerships";
    }
    return <I18n id={id}/>
  }

  const getSharePercentage = (indirectCase) => {
    const ownershipData =
      indirectCase.userOwnership || indirectCase.userPublicDataOwnership || indirectCase.relationOwnership || indirectCase.relationPublicDataOwnership
    if (ownershipData?.stakePercentage != null) {
      return I18nFunction( { 
        id:'share_percentage',
        share: parseFloat(ownershipData.stakePercentage).toFixed(2)
      })
    } 
    else {
      return ""
    }
  }

  const [showMultiApproveCasesModal, setShowMultiApproveCasesModal] = useState(false)

  const isOwnCase = userId == currentUserId
  

  return(
      <Wrapper>
        {indirectCases.length ?
        <div>
          {showMultiApproveCasesModal && !isOwnCase && (
              <MultiApproveCasesModal
                selectedCaseIds={selectedCaseIds}
                setShowMultiApproveCasesModal={setShowMultiApproveCasesModal}
                cases={indirectCases}
                clearAllCases={clearAllCases}
              />
            )}
          <Columns>
            {pending ? (
              <IndirectLoading>
                <SmallSpinner size={'3em'} color={'secondary'} />
                <LoadingText>
                  <I18n id="indirect_ownership_list.loading_indirect_ownership"/>
                </LoadingText>
              </IndirectLoading>
              ):(
              <>
                <TitleAndOptions>
                  <TitleSpace/>
                  <Title>
                    {renderIndirectTitle()}
                  </Title>
                  <Options>
                    {!isOwnCase &&
                    <ActionButton
                      small
                      inactive={buttonDisabled}
                      disabled={buttonDisabled}
                      onClick={approveIndirect}
                    >
                      <I18n id="multi_approve_cases.approve_selected" />
                    </ActionButton>}
                  </Options>
                </TitleAndOptions>
                {indirectCases.length > 0 && (
                  <ListWrapper>
                    <IndirectListHeader>
                      <ToggleAllButtonWrapper>
                        {!isOwnCase &&
                        <ToggleAllButton onClick={handleToggleAll}>
                          {selectedCaseIds.length === 0 ? (
                            <I18n id="Select_all" />
                          ) : (
                            <I18n id="Remove_selection" />
                          )}
                        </ToggleAllButton>}
                      </ToggleAllButtonWrapper>
                      <HeaderOrganizationName>
                        <I18n id="indirect_ownership_list.list_columns.name"/>
                      </HeaderOrganizationName>
                      <HeaderSpace/>
                      <HeaderSharePercentage>
                        {!isRole && 
                          <I18n id="indirect_ownership_list.list_columns.indirect_share"/>
                        }
                      </HeaderSharePercentage>
                      <HeaderStatus>
                        <I18n id="indirect_ownership_list.list_columns.status"/>
                      </HeaderStatus>
                      <HeaderLink/>
                    </IndirectListHeader>
                    <Scrollbox>
                      <List>
                        {indirectCases?.map((item, index) => (
                          <ListItem key={Case.id + item.id}>
                            <ToggleWrapper>
                              {!isOwnCase &&
                                <input
                                  type="checkbox"
                                  checked={!!selectedCases[item.id]}
                                  onChange={() => {toggleCase(item.id)}}
                                />
                              }
                            </ToggleWrapper>
                            <ItemInfo>
                              <OrganizationName>
                                {item.organizationName}
                              </OrganizationName>
                              <CaseRisk>
                                {showRiskWarning(item) && (
                                  <CasePriorityWarningMarker Item={item.caseId ? Case : item} />
                                )}
                              </CaseRisk>
                              <SharePercentage>
                                {getSharePercentage(item)}
                              </SharePercentage>
                              <CaseStatus>
                                <StatusBadge small status={item.status} />
                              </CaseStatus>
                              <CaseLink
                                to={`/cases/${item.caseId ? item.caseId : item.id}`}
                                secondary={'yes'}
                              >
                                <LinkText>
                                  <I18n id="Go_to_case" />
                                </LinkText>
                              </CaseLink>
                            </ItemInfo>
                          </ListItem>
                        ))}
                      </List>
                    </Scrollbox>
                  </ListWrapper>
                )}
              </>)
            }
          </Columns>
        </div>
        :
        <TitleAndOptions>
          <TitleSpace/>
            <Title>
              {renderIndirectTitle()}
            </Title>
          <TitleSpace/>
        </TitleAndOptions>
        }
      </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow-x: auto;
  word-break: break-word;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  border-radius: 10px;

  padding: 1.5em;
  padding-left: 0.5em;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
`
const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`

const TitleAndOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const TitleSpace = styled.div`
  width: 30%
`

const Title = styled.div`
  font-size: ${(props) => props.theme.largeText};
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  font-weight: bold;
  width: 40%
`

const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
`

const ActionButton = styled(Button)`
  background: #0c3689;
  border: 2px solid #0c3689;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 10em;
`


const ListWrapper = styled.div`
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground}; 
  padding-top: 0.5em;
  padding-bottom: 1em;
  border-radius: 7px;
`

const Scrollbox = styled.div`
  margin: 0;
  position: relative;
  padding: 0em;
  padding-bottom: 4em;
  max-height: 200px;
  overflow: auto;
  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const List = styled.ul`
  list-style: none;
  padding-top: 0px;
  padding-left: 0px;
  height: 100%;

  & > :not(:last-child) {
    margin-bottom: 0.7em;
  }
`

const IndirectListHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
`

const ToggleAllButtonWrapper = styled.div`
  width: 12%;
  color: ${({ theme: { secondary } }) => secondary};
`

const ToggleAllButton = styled.div`
  cursor: pointer;
`

const HeaderOrganizationName = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 25%;
`

const HeaderSpace = styled.div`
  display: flex;
  justify-content: space-around;
  width: 10%;  
`

const HeaderSharePercentage = styled.div`
  display: flex;
  width: 11%;
  justify-content: center;
  text-align: center;
`

const HeaderStatus = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 34%;
`

const HeaderLink = styled.div`
  width: 8%;
`

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  with: 100%;
  align-items: center;
  padding-left: 1.5em;
  padding-right: 0.5em;
`

const LoadingText = styled.div`
  margin-right: 2em;
`

const ItemInfo = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  height: 3.5em;
  width: 95%;
  gap: 2%;
  color: ${({ theme: { text } }) => text};
  display: flex;
  align-items: center;
  padding: 0.7em;
  background-color: ${({ theme: { pageBackground } }) => pageBackground}; 
  `

const ToggleWrapper = styled.div`
  width: 5%;
`

const OrganizationName = styled.div`
  display: flex;
  width: 37%;
  overflow: hidden;
`

const CaseRisk = styled.div`
  display: flex;
  justify-content: space-around;
  width: 5%;  
`

const SharePercentage = styled.div`
  display:inline-block;
  position: relative;
  width: 15%;
  overflow: auto;
  white-space: nowrap;
  text-align: center;
`

const CaseStatus = styled.div`
  padding: 1em 0em;
  width: 36%;
`

const CaseLink = styled(Link)`
  display: flex;
  justify-content: space-around;
  text-decoration: none;
  width: 7%;
`

const LinkText = styled.div`
  flex-grow: 0;
  flex-basis: initial;
  text-align: center;
  color: ${({ theme: { secondary } }) => secondary};
  font-size: ${({ theme: { normalText } }) => normalText};
`
const IndirectLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 2em;
  padding: 2em;
  color: ${({ theme }) => theme.secondary};
`

const mapStateToProps = (state, props) => {
    const userId = props.Case?.userId
    const currentUserId = getCurrentUserId(state)

    let indirectCaseType
    switch (props.Case?.caseType){
      case USER_OWNERSHIP_CASE:
        indirectCaseType = USER_INDIRECT_OWNERSHIP_CASE
        break
      case USER_ROLE_CASE:
        indirectCaseType = USER_INDIRECT_ROLE_CASE
        break
      case RELATION_OWNERSHIP_CASE:
        indirectCaseType = RELATION_INDIRECT_OWNERSHIP_CASE
        break
      case RELATION_ROLE_CASE:
        indirectCaseType = RELATION_INDIRECT_ROLE_CASE
        break
    }

    return {
      userId: userId,
      currentUserId: currentUserId,
      pending: isIndirectCasesPending(state, props.Case.id),
      indirectCaseType: indirectCaseType,
      indirectCases: getUserCasesOfType(
        state,
        userId,
        indirectCaseType).filter((Case) =>
            Case.directConnectionCaseId == props.Case.id
          ),
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(IndirectCases)
