import React from 'react'
import { Select } from '../index'
import countries from './countries.json'
import { useSelector } from 'react-redux'
import { getLanguage } from '../../../redux/selectors'

const CountrySelector = (props) => {
  const language = useSelector((state) => getLanguage(state) )
  const countryCodes = countries[language] || countries.no

  return (
    <Select
      options={Object.entries(countryCodes).map(([code, name]) => ({
        label: name,
        value: code,
      }))}
      {...props}
      onChange={({ value }, { name }) =>
        props.onChange({ target: { name, value } })
      }
      disabled={props.disabled}
      value={
        props.value !== undefined && {
          label: countryCodes[props.value],
          value: props.value,
        }
      }
    />
  )
}

export default CountrySelector
