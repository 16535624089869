import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Page from '../components/Page'
import I18n, {  useI18nFunction } from '../components/I18n'
import usePagination from '../hooks/usePagination'
import DataListHeader from '../components/DataList/DataListHeader'
import DataListColumns from '../components/DataList/DataListColumns'
import DataList from '../components/DataList/DataList'
import useSort from '../hooks/useSort'

import {
  getAllCases,
  shouldFetchCases,
  shouldFetchApplications,
  getAllApplications,
  isApplicationsPending,
  getApplicationPagination,
} from '../redux/selectors'
import { fetchAllApplications } from '../redux/actions'
import { APPROVED, IN_PROGRESS, NOT_APPROVED } from '../CaseStatus'

const Applications = ({
  fetchAllApplications,
  applications,
  pending,
  applicationPagination,
}) => {
  const I18nFunction = useI18nFunction();
  const applicationPageProps = {
    paginationProps: {
      perPageOptions: [10, 15, 25, 50, 100, I18nFunction( { id: 'pagination.fetch_all' } )],
    },
    searchInputProps: {
      placeholder: I18nFunction( { id: 'Search_for_cases' } ),
    },
    sortProps: {
      placeholder: I18nFunction({ id: 'Sort_by' }),
      options: [
        {
          label: I18nFunction({ id: 'Created_on_desc' }),
          key: 'created_on_desc',
          value: {
            iteratee: 'createdOn',
            order: 'desc',
          },
        },
        {
          label: I18nFunction({ id: 'Created_on_asc' }),
          key: 'created_on_asc',
          value: {
            iteratee: 'createdOn',
            order: 'asc',
          },
        },
        {
          label: I18nFunction({ id: 'Updated_on_desc' }),
          key: 'updated_on_desc',
          value: {
            iteratee: 'updatedOn',
            order: 'desc',
          },
        },
        {
          label: I18nFunction({ id: 'Updated_on_asc' }),
          key: 'updated_on_asc',
          value: {
            iteratee: 'updatedOn',
            order: 'asc',
          },
        },
      ],
    },
    multiSelectProps: {
      filters: [
        {
          key: 'caseStatusFilter',
          placeholder: I18nFunction( { id: 'filterType',  payload: 'list' } ), 
          options: [
            {
              label: I18nFunction({ id: 'case_statuses.in_progress' }),
              value: IN_PROGRESS,
              key: 1,
            },
            {
              label: I18nFunction({ id: 'case_statuses.approved' }),
              value: APPROVED,
              key: 2,
            },
            {
              label: I18nFunction({ id: 'case_statuses.not_approved' }),
              value: NOT_APPROVED,
              key: 3,
            },
          ],
        },
        {
          key: 'caseRiskFilter',
          placeholder: I18nFunction( { id: 'filterType',  payload: 'toggle' } ), 
          options: [
            {
              label: I18nFunction({ id: 'case_risk_categories.organization_is_supplier' }),
              value: 'is_supplier_for_employee_org',
              key: 1,
            },
            {
              label: I18nFunction({ id: 'case_risk_categories.organization_is_client' }),
              value: 'is_customer_for_employee_org',
              key: 2,
            },
            {
              label: I18nFunction({ id: 'case_risk_categories.position_of_influence' }),
              value: 'position_of_influence',
              key: 3,
            },
            {
              label: I18nFunction({ id: 'case_risk_categories.is_board_member' }),
              value: 'is_board_member',
              key: 4,
            },
            // Not yes implemented in back-end
            // {
            //   label: I18nFunction({ id: 'case_risk_categories.no_risk' }),
            //   value: 'no_risk',
            //   key: 5,
            // },
          ],
        },
      ],
    },
    dateRangeFilterProps: {
      dateType: {
        label: I18nFunction({ id: 'Time_period' }),
        placeholder: I18nFunction({ id: 'Time_period_select' }),
        filtrable: true,
        type: 'dateType',
        options: [
          {
            label: I18nFunction({ id: 'Updated_on' }),
            key: 'updated_on',
            value: {},
          },
          {
            label: I18nFunction({ id: 'Created_on' }),
            key: 'created_on',
            value: {},
          },
        ],
      },
      fromDate: {
        label: I18nFunction({ id: 'filterList_properties.caseFromDate' }),
        placeholder: I18nFunction({ id: 'Date_placeholder' }),
        propertyName: 'createdOn',
        id: 'fromDate',
        value: new Date(),
        type: 'range',
      },
      toDate: {
        label: I18nFunction({ id: 'filterList_properties.caseToDate' }),
        placeholder: I18nFunction({ id: 'Date_placeholder' }),
        buttonDescription: I18nFunction({ id: 'Reset_data_filter' }),
        propertyName: 'createdOn',
        id: 'toDate',
        value: new Date(),
        type: 'range',
      },
    },
    listColumnProps: {
      columns: [
        {
          label: I18nFunction({ id: 'CaseId' }),
          key: 'id',
          width: '6%',
        },
        {
          label: I18nFunction({ id: 'Created_on' }),
          key: 'createdOn',
          width: '9%',
        },
        {
          label: I18nFunction({ id: 'Updated_on' }),
          key: 'updatedOn',
          width: '9%',
        },
        {
          label: I18nFunction({ id: 'case_has_risks' }),
          key: 'risk',
          width: '55%',
        },
        {
          label: 'Status',
          key: 'status',
          width: '15%',
        },
      ],
    },
  }

  const {
    query,
    pageIndex,
    perPage,
    paginationResult,
    filters,
    sortOption,
    dateRangeOption,
    dateRangeFrom,
    dateRangeTo,
    setQuery,
    setPageIndex,
    setPerPage,
    setQueryHandler,
    setFiltersHandler,
    setSortOptionHandler,
    setDateRangeOptionHandler,
    setDateRangeFromHandler,
    setDateRangeToHandler,
    setPerPageHandler,
  } = usePagination(
    fetchAllApplications,
    getApplicationPagination,
    applicationPagination,
  )

  const { sort, setSort, sortFunction } = useSort(
    applicationPageProps?.sortProps?.options[0],
  )

  const sortApplications = () => {
    if (
      applicationPagination?.sortOption &&
      applicationPagination?.sortOption != sort?.key
    ) {
      let idx = applicationPageProps?.sortProps?.options.findIndex(
        (o) => o.key === applicationPagination?.sortOption,
      )
      setSort(applicationPageProps?.sortProps?.options[idx])
    }
    return sortFunction(applications)
  }

  return (
    <Page>
      <Content>
        <h1>
          <I18n id="Applications" />
        </h1>
        <DataListHeader
          pageProps={applicationPageProps}
          query={query}
          pageIndex={pageIndex}
          perPage={perPage}
          paginationResult={paginationResult}
          filters={filters}
          sortOption={sortOption}
          dateRangeOption={dateRangeOption}
          dateRangeFrom={dateRangeFrom}
          dateRangeTo={dateRangeTo}
          setQuery={setQuery}
          setPageIndex={setPageIndex}
          setPerPage={setPerPage}
          setQueryHandler={setQueryHandler}
          setFiltersHandler={setFiltersHandler}
          setSortOptionHandler={setSortOptionHandler}
          setDateRangeOptionHandler={setDateRangeOptionHandler}
          setDateRangeFromHandler={setDateRangeFromHandler}
          setDateRangeToHandler={setDateRangeToHandler}
          setPerPageHandler={setPerPageHandler}
        />
        <DataListColumns props={applicationPageProps?.listColumnProps} />
        <DataList data={sortApplications()} pending={pending} />
      </Content>
    </Page>
  )
}

const Content = styled.div`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
  padding: 0 6em;
`

const mapStateToProps = (state) => ({
  cases: getAllCases(state),
  applications: getAllApplications(state),
  shouldFetchApplications: shouldFetchApplications(state),
  shouldFetchCases: shouldFetchCases(state),
  pending: isApplicationsPending(state),
  applicationPagination: getApplicationPagination(state),
})

const mapDispatchToProps = { fetchAllApplications }

export default connect(mapStateToProps, mapDispatchToProps)(Applications)
