import { 
    FETCH_USER_NAMES,
    FETCH_USER_NAMES_SUCCEEDED,
    FETCH_USER_NAMES_FAILED,
    FETCH_USERS,
    FETCH_USERS_SUCCEEDED,
    FETCH_USERS_FAILED 
  } from './index'

const allIds = (
  state = {
    ids: undefined,
    fetching: false,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_USER_NAMES:
    case FETCH_USERS:
      return {
        ids: undefined,
        fetching: true,
      }

    case FETCH_USER_NAMES_SUCCEEDED:
    case FETCH_USERS_SUCCEEDED:
      return {
        ids: action.payload.result,
        fetching: false,
      }

    case FETCH_USER_NAMES_FAILED:
    case FETCH_USERS_FAILED:
      return {
        ids: undefined,
        fetching: false,
      }

    default:
      return state
  }
}

export const getIds = (state) => state.ids
export const isFetching = (state) => state.fetching

export default allIds
