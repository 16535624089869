import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import checkmarkIcon from '../../icons/checkmark_circled_filled.svg'
import attentionIcon from '../../icons/circled_alert_icon.svg'
import caretRight from '../../icons/caret-right_light_gray.svg'
import caretRightActive from '../../icons/caret-right_gray.svg'
import I18n from '../I18n'
import { setActiveConnection } from '../../redux/actions'
import {
  getActiveConnection,
  hasConfirmedRelationProffSelection,
} from '../../redux/selectors'
import { getActiveConnectionDataFromClickedListItem } from '../ConnectionForm/utils'
import {
  RELATION,
  USER_OWNERSHIP,
  RELATION_OWNERSHIP,
  USER_ROLE,
  RELATION_ROLE,
  USER_OTHER_CONNECTION,
  RELATION_OTHER_CONNECTION,
} from '../../connectionTypes'
import CaseInfo from './CaseInfo'

const SelectedCase = ({ 
  Case,
  setActiveConnection,
  unsetActiveConnection,
  active,
  disabledInteraction,
  relation,
  hasConfirmedProffSelection,
  connectionType
}) => {

  const checked = connectionType == RELATION 
  ? hasConfirmedProffSelection 
  : [
      USER_OWNERSHIP, 
      RELATION_OWNERSHIP, 
      USER_ROLE, 
      RELATION_ROLE, 
      USER_OTHER_CONNECTION, 
      RELATION_OTHER_CONNECTION
    ].includes(connectionType)

  const onClick = (e) => {
    const activeConnectionData = getActiveConnectionDataFromClickedListItem(
      Case,
      relation,
    )
                                            
    if (!disabledInteraction) {
      unsetActiveConnection()
      active
        ? unsetActiveConnection()
        : setActiveConnection({
            id: activeConnectionData.id,
            caseId: relation ? undefined : Case?.id,
            relationId: Case?.relationId,
            connectionType: connectionType,
            connection: activeConnectionData,
          })
    }
  }

  return (
    Case && (
      <Wrapper>
        <CaseInfo Case={Case} connectionType={connectionType}/>
        <Status
          className={[active && 'active', !checked && 'attention']} 
          onClick={onClick}>
          <CheckedOrAttentionMarker
                  src={checked ? checkmarkIcon : attentionIcon}
                  title={
                    checked ? (
                      <I18n id="Confirmed" />
                    ) : (
                      <I18n id="Waiting_for_confirmation" />
                    )
                  }
                />
            
          <CaseLink to={''}>
            <LinkText>
              {checked ?  <I18n id="Edit" /> :  <I18n id="Confirm" />}
            </LinkText>
          </CaseLink>
          <CaretRight src={active ? caretRightActive : caretRight}/>
        </Status>
      </Wrapper>

    )
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  gap: 2em;
`

const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  padding: 1em;

  cursor: pointer;
  &:hover {
    background-color: #e9e9e9;
  }
  &.active {
    background-color: #e9e9e9;
  }
`

const CheckedOrAttentionMarker = styled.img`
  height: 2.5em;
  width: 2.5em;
`

const CaretRight = styled.img`
  height: 1.5em;
  width: 1.5em;
`

const CaseLink = styled(Link)`
  text-decoration: none;
`
const LinkText = styled.div`
  padding-left: 0.7em;
  padding-right: 0.2em;
  flex-grow: 0;
  flex-basis: initial;
  white-space: nowrap;
  color: ${({ theme: { secondary } }) => secondary};
  font-size: ${({ theme: { largeText } }) => largeText};
`

const mapStateToProps = (state, props) => {
  const activeConnection = getActiveConnection(state)
  const active = ((props.Case?.caseType !== RELATION && activeConnection?.caseId === props.Case?.id) 
                || (props.Case?.relationType && activeConnection?.id === props.Case?.id))

  const relation = props.Case?.relationType ? props.Case : null
  const hasConfirmedProffSelection = relation?.id ? hasConfirmedRelationProffSelection(state, relation.id) : false
  
  return {
    active: active,
    relation: relation,
    hasConfirmedProffSelection: hasConfirmedProffSelection
  }
}


const mapDispatchToProps = { setActiveConnection }

export default connect(mapStateToProps, mapDispatchToProps)(SelectedCase)