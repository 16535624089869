import React from 'react'
import styled from 'styled-components'

const SuggestionList = ({
  suggestions,
  active,
  setHover,
  unsetHover,
  select,
}) => (
  <List>
    {suggestions.map((suggestion, index) => (
      <Suggestion
        key={suggestion.organizationNumber}
        active={active === index}
        onMouseEnter={() => setHover(index)}
        onMouseLeave={unsetHover}
        onMouseDown={(e) => {
          e.preventDefault()
          select(suggestion)
        }}
      > 
        {
          ['AS', 'ASA', 'ANS', 'DA', 'ENK', 'FKF', 'IKS', 'KF', 'KS', 'PRE', 'SA', 'SE', 'SF', ''].includes(suggestion.organizationCode) 
            ? suggestion.organizationName
            : (
              <span>
                {suggestion.organizationName} 
                <SuggestionOrgCode> ({suggestion.organizationCode})</SuggestionOrgCode>
              </span>
            )
        }  
      </Suggestion>
    ))}
  </List>
)

const Suggestion = styled.li`
  background-color: ${({
  active,
  theme: { secondaryBackground, primaryBackground },
}) => (active ? secondaryBackground : primaryBackground)};
  padding: 0.25em;
`

const SuggestionOrgCode = styled.span`
  color: ${({ theme: { lightText } }) => lightText};  
`

const List = styled.ul`
  border: 1px solid ${({ theme: { seconday } }) => seconday};
  z-index: 1;
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};

  & > :not(:last-child) {
    margin-bottom: 0.15em;
  }
`

export default SuggestionList
