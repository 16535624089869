import React from 'react'
import { SimpleSelect as Select } from '../../form'
import projectStatus from './projectStatus.json'
import projectAssessmentStatus from './projectAssessmentStatus.json'
import { useSelector } from 'react-redux'
import { getLanguage } from '../../../redux/selectors'

const ProjectStatusSelector = (props) => {
  const language = useSelector((state) => getLanguage(state) )
  let status
  if (props.name == "project_assessment_status") {
    status = projectAssessmentStatus[language] || projectAssessmentStatus.no
  }
  else if (props.name == "project_status") {
    status = projectStatus[language] || projectStatus.no
  } else {
    status = projectStatus[language] || projectStatus.no
  }

  return (
    <Select
      {...props}
        options={Object.entries(status).map(([code, name]) => ({
        label: name,
        value: code,
      }))}
      disabled={props.disabled}
      value={props.value}
    />
  )
}

export default ProjectStatusSelector
