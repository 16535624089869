import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { RELATION, USER_OWNERSHIP } from '../../connectionTypes'
import checkmarkIcon from '../../icons/checkmark_circled_filled.svg'
import {
  addConnection,
  applyConnection,
  cancelConfirmNoConnections,
  confirmNoConnections,
  setProfileCaseIndex,
  unsetActiveConnection,
} from '../../redux/actions'
import {
  currentUserhasConfirmedNoConnections,
  getActiveConnection,
  getProfileCasePagination,
  getProfileCaseIndex,
  getSelectedCase,
  isCancelConfirmNoConnectionsPending,
  isConfirmNoConnectionsPending,
  isCaseRelevant,
} from '../../redux/selectors'
import AddConnectionButton from '../AddConnectionButton'
import Button from '../Button'
import ConnectionModal from '../ConnectionModal/ConnectionModal'
import I18n, { useI18nFunction } from '../I18n'
import InfoBox from '../InfoBox'
import ProfileCaseList from './ProfileCaseList'

const ProfileCaseSection = ({
  isCurrentUsersProfile,
  connectionFormTabName,
  userId,
  connectionType,
  addButtonText,
  applyForButtonText,
  confirmNoConnectionsButtonText,
  confirmedNoConnectionsButtonText,
  activeConnection,
  unsetActiveConnection,
  confirmNoConnections,
  hasConfirmedNoConnections,
  previousItems,
  currentItems,
  caseType,
  applicationItems,
  notRelevantItems,
  isConfirmNoConnectionsPending,
  cancelConfirmNoConnections,
  isCancelConfirmNoConnectionsPending,
  hasTransientConnection,
  hasApplicationComponents,
  items,
  applyConnection,
  addConnection,
  selectedCase,
  ownershipType,
  isCasesPending,
  casePagination,
  profileCaseIndex,
  setProfileCaseIndex
}) => {
  const I18nFunction = useI18nFunction()

  const CURRENT = 'Current'
  const PREVIOUS = 'Previous'
  const APPLICATIONS = 'Applications'
  const NOT_RELEVANT_TAB = 'Not_relevant'

  let sectionTabs = [
    {
      type: CURRENT,
      renderedItems: currentItems,
      condition: true,
    },
    {
      type: PREVIOUS,
      renderedItems: previousItems,
      condition: caseType !== RELATION,
    },
    {
      type: APPLICATIONS,
      renderedItems: applicationItems,
      condition: hasApplicationComponents,
    },
    {
      type: NOT_RELEVANT_TAB,
      renderedItems: notRelevantItems,
      condition: caseType!==RELATION && process.env.REACT_APP_SET_RELEVANT_CONNECTION_ENABLED?.toLowerCase() ===
      'true',
    },
  ]
  const [tabIndex, setTabIndex] = useState(0)
  const [showConnectionModal, setShowConnectionModal] = useState(false)

  useEffect(() => {
    activeConnection && setShowConnectionModal(true)
  }, [activeConnection])

  useEffect(() => {
    setShowConnectionModal(Boolean(selectedCase))
  }, [selectedCase])

  const onChangeTab = (tabIndex) => {
    setTabIndex(tabIndex)
    unsetActiveConnection()
  }

  return (
    <Wrapper>
      <SubMenuBar>
        <RegistrationTypeTabs>
          {sectionTabs
            .filter((t) => t.condition)
            .map((tab, index) => (
              <RegistrationTypeTabOption
                key={index}
                className={index === tabIndex ? 'active' : ''}
                onClick={() => onChangeTab(index)}
              >
                {I18nFunction({ id: `${tab.type}` })} (
                {tab.renderedItems.length})
              </RegistrationTypeTabOption>
            ))}
        </RegistrationTypeTabs>
      </SubMenuBar>

      <Layout>
        <ConnectionListWrapper>
          <ProfileCaseList
            items={sectionTabs[tabIndex].renderedItems}
            numberOfTotalItems = {items.length}
            isCurrentUsersProfile={isCurrentUsersProfile}
            activeConnection={activeConnection}
            caseType={caseType}
            userId={userId}
            isCasesPending={isCasesPending}
            casePagination={casePagination}
            pageIndex={profileCaseIndex}
            setPageIndex={setProfileCaseIndex}
            editable
          />
        </ConnectionListWrapper>
        {isCurrentUsersProfile && (
          <FormWrapper>
            {showConnectionModal && (
              <ConnectionModal
                userId={userId}
                unsetActiveConnection={unsetActiveConnection}
                activeConnection={activeConnection}
                item={sectionTabs[tabIndex].renderedItems}
                isCurrentUsersProfile={isCurrentUsersProfile}
                setShowConnectionModal={setShowConnectionModal}
              />
            )}

            <>
              {(!hasConfirmedNoConnections || items.length > 0) && (
                <CenterFlex>
                  <AddConnectionButton
                    connectionType={connectionType}
                    ownershipType={ownershipType}
                    userId={userId}
                    hasTransientConnection={hasTransientConnection}
                    action={
                      sectionTabs[tabIndex].type !== APPLICATIONS
                        ? addConnection
                        : applyConnection
                    }
                  >
                    {sectionTabs[tabIndex].type !== APPLICATIONS
                      ? addButtonText
                      : applyForButtonText}
                  </AddConnectionButton>
                </CenterFlex>
              )}

              {!hasConfirmedNoConnections && items.length === 0 && (
                <CenterFlex>
                  <I18n id="Or" />
                </CenterFlex>
              )}

              <CenterFlex>
                {items.length == 0 && (
                  <ButtonWrapper>
                    {hasConfirmedNoConnections && (
                      <Checkmark src={checkmarkIcon} alt="" />
                    )}
                    <Button
                      secondary={!hasConfirmedNoConnections ? 1 : 0}
                      onClick={
                        hasConfirmedNoConnections
                          ? undefined
                          : () => confirmNoConnections(connectionType)
                      }
                      pending={isConfirmNoConnectionsPending}
                    >
                      {hasConfirmedNoConnections
                        ? confirmedNoConnectionsButtonText
                        : confirmNoConnectionsButtonText}
                    </Button>
                  </ButtonWrapper>
                )}
                {hasConfirmedNoConnections && items.length === 0 && (
                  <CancelButton
                    subtle
                    onClick={() => cancelConfirmNoConnections(connectionType)}
                    pending={isCancelConfirmNoConnectionsPending}
                  >
                    <I18n id="Withdraw_confirmation" />
                  </CancelButton>
                )}
              </CenterFlex>

              <InfoBox>
                <I18n
                  id={`connection_form.tabs.${connectionFormTabName}.renderInfo`}
                />
              </InfoBox>
            </>
          </FormWrapper>
        )}
      </Layout>
    </Wrapper>
  )
}

const FormWrapper = styled.div`
  flex: 0.8;
  width: 35%;
`

const CenterFlex = styled.div`
  margin: 2em;
  display: flex;
  justify-content: center;
`

const SubMenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`
const RegistrationTypeTabs = styled.div`
  display: flex;
  align-items: center;
`
const RegistrationTypeTabOption = styled.div`
  cursor: pointer;
  font-size: ${({ theme: { largeText } }) => largeText};
  font-weight: bold;

  color: ${({ theme: { subtleText } }) => subtleText};
  border-bottom: 2px solid
    ${({ theme: { secondaryBackground } }) => secondaryBackground};

  padding: 0.25em 0.5em;
  margin-right: 1em;
  &:hover {
    border-bottom: 3px solid
      ${({ theme: { secondaryBackground } }) => secondaryBackground};
  }
  &.active {
    color: ${({ theme: { text } }) => text};
    border-bottom: 3px solid ${({ theme: { primary } }) => primary};
  }
`

const CancelButton = styled(Button)`
  margin-top: 1em;
`

const ConnectionListWrapper = styled.div`
  flex: 1;
  min-width: 60%;
  position: relative;
`

const ButtonWrapper = styled.div`
  position: relative;
`

const Checkmark = styled.img`
  position: absolute;
  left: -2.5em;
  top: 50%;
  transform: translate(0, -50%);
`

const Layout = styled.div`
  padding-top: 1em;
  display: flex;
  gap: 2em;
`
const Wrapper = styled.div`
  padding-top: 1em;
  display: flex;
  flex-direction: column;
`

const mapStateToProps = (state, props) => {
  const { caseType, connectionKeys, items } = props

  let applicationItems = []
  let previousItems = []
  let currentItems = []
  let notRelevantItems = []

  if (caseType != RELATION) {
    items
      .sort(sortByUpdatedOn)
      .sort((a, b) => sortByWhetherUserConfirmed(a, b, connectionKeys))

    let nonApplicationItems = items?.filter(
      (c) => c[connectionKeys.publicData] || c[connectionKeys.userRegistered],
    )
    if (process.env.REACT_APP_SET_RELEVANT_CONNECTION_ENABLED?.toLowerCase() ==='true') {
        notRelevantItems = nonApplicationItems.filter(
        (c) => !isCaseRelevant(c) )
    }
    else{
      // all is relevant, no non-relevant items
      notRelevantItems = []
    }
      // Filter out non-relevant items before filtering previous and current
      const relevantItems = nonApplicationItems.filter(
      (c) => !notRelevantItems.includes(c))

    previousItems = relevantItems.filter((c) =>
      hasEnded(c[connectionKeys.userRegistered]),
    )
    currentItems = relevantItems.filter(
      (c) => !hasEnded(c[connectionKeys.userRegistered]),
    ) 
    applicationItems = items?.filter(
      (c) => !c[connectionKeys.publicData] && !c[connectionKeys.userRegistered],
    )
  }
    
  else {
    currentItems = items.sort(sortByUpdatedOn)
  }

  let ownershipType =
    props.connectionType === USER_OWNERSHIP ? 'user_ownership' : undefined
  return {
    previousItems: previousItems,
    currentItems: currentItems,
    applicationItems: applicationItems,
    notRelevantItems: notRelevantItems,
    activeConnection: getActiveConnection(state),
    selectedCase: getSelectedCase(state),
    ownershipType: ownershipType,
    hasConfirmedNoConnections: currentUserhasConfirmedNoConnections(
      state,
      props.connectionType,
    ),
    isConfirmNoConnectionsPending: isConfirmNoConnectionsPending(
      state,
      props.connectionType,
    ),
    isCancelConfirmNoConnectionsPending: isCancelConfirmNoConnectionsPending(
      state,
      props.connectionType,
    ),
    casePagination: getProfileCasePagination(state),
    profileCaseIndex: getProfileCaseIndex(state),
  }
}

const mapDispatchToProps = {
  unsetActiveConnection,
  confirmNoConnections,
  cancelConfirmNoConnections,
  applyConnection,
  addConnection,
  setProfileCaseIndex
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCaseSection)

const hasEnded = (userRegisteredConnection) =>
  Date.parse(userRegisteredConnection?.toDate) < Date.now()

const sortByUpdatedOn = (a, b) => {
  if (a.updatedOn > b.updatedOn) return -1
  if (a.updatedOn < b.updatedOn) return 1
  return 0
}

const sortByWhetherUserConfirmed = (a, b, connectionKeys) => {
  if (!a[connectionKeys.userRegistered] && b[connectionKeys.userRegistered])
    return -1
  if (a[connectionKeys.userRegistered] && !b[connectionKeys.userRegistered])
    return 1
  return 0
}
