import {
  FETCH_ALL_CASES,
  FETCH_ALL_CASES_SUCCEEDED,
  FETCH_ALL_CASES_FAILED,
  FETCH_CASE,
  FETCH_CASE_SUCCEEDED,
  FETCH_CASE_FAILED,
  FETCH_INDIRECT_CASES,
  FETCH_INDIRECT_CASES_FAILED,
  FETCH_INDIRECT_CASES_SUCCEEDED,
  FETCH_USER_RELATION_CASES_SUCCEEDED,
  COMMENT_ON_CASE,
  COMMENT_ON_CASE_FAILED,
  COMMENT_ON_CASE_SUCCEEDED,
  UPDATE_CASE_STATUS_SUCCEEDED,
  MULTI_APPROVE_CASES_SUCCEEDED,
  UPDATE_MANAGER_STATEMENT_SUCCEEDED,
  UPDATE_BOARD_STATEMENT_SUCCEEDED,
  UPDATE_APPLICATION_STATUS_SUCCEEDED,
  UPDATE_APPLICATION_BOARD_STATEMENT_SUCCEEDED,
  UPDATE_APPLICATION_MANAGER_STATEMENT_SUCCEEDED,
  POST_BOARD_STATEMENT_SUCCEEDED,
  POST_APPLICATION_BOARD_STATEMENT_SUCCEEDED,
} from './index'
import {
  FETCH_USER_PROFILE_CASES_SUCCEEDED,
  FETCH_USER_PROFILE_DATA,
  FETCH_USER_PROFILE_DATA_FAILED,
  FETCH_USER_PROFILE_DATA_SUCCEEDED,
} from '../users'
import { camelize } from 'humps'
import {
  RELATION_OWNERSHIP_CASE,
  RELATION_INDIRECT_OWNERSHIP_CASE,
  RELATION_ROLE_CASE,
  RELATION_INDIRECT_ROLE_CASE,
  USER_OWNERSHIP_CASE,
  USER_INDIRECT_OWNERSHIP_CASE,
  USER_INDIRECT_ROLE_CASE,
  USER_ROLE_CASE,
} from '../../caseTypes'
import {
  CREATE_CONNECTION_APPLICATION_SUCCEEDED,
  CREATE_CONNECTION_SUCCEEDED,
  UPDATE_CONNECTION_SUCCEEDED,
  REMOVE_CONNECTION_SUCCEEDED,
  MASS_CONFIRM_INDIRECT_CONNECTIONS_SUCCEEDED,
  CREATE_INDIRECT_CONNECTIONS,
  CREATE_INDIRECT_CONNECTIONS_SUCCEEDED,
  CREATE_INDIRECT_CONNECTIONS_FAILED,
} from '../connections'
import { FETCH_COMPANY_CONNECTIONS_SUCCEEDED } from '../companies'
import { FETCH_ALL_APPLICATIONS_SUCCEEDED } from '../applications'

const byId = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CASE_STATUS_SUCCEEDED:
    case UPDATE_MANAGER_STATEMENT_SUCCEEDED:
    case UPDATE_APPLICATION_MANAGER_STATEMENT_SUCCEEDED:
    case UPDATE_BOARD_STATEMENT_SUCCEEDED:
    case UPDATE_APPLICATION_BOARD_STATEMENT_SUCCEEDED:
    case UPDATE_APPLICATION_STATUS_SUCCEEDED:
      return Object.entries(action.payload.entities.case || {}).reduce(
        (state, [id, Case]) => ({
          ...state,
          [id]: {
            ...state[id],
            ...Case,
            fetchStatus: 'succeeded',
            caseType: camelize(Case.caseType),
          },
        }),
        state,
      )

    case FETCH_ALL_CASES:
    case FETCH_ALL_CASES_FAILED:
    case FETCH_USER_PROFILE_DATA:
    case FETCH_USER_PROFILE_DATA_FAILED:
      return {
        ...state,
      }

    case FETCH_ALL_APPLICATIONS_SUCCEEDED:
    case FETCH_ALL_CASES_SUCCEEDED:
      return {
        ...action.payload.entities?.case,
      }
    case FETCH_USER_PROFILE_DATA_SUCCEEDED:
    case FETCH_USER_PROFILE_CASES_SUCCEEDED:
    case FETCH_USER_RELATION_CASES_SUCCEEDED:
      return Object.entries(action.payload.entities.case || {}).reduce(
        (state, [id, Case]) => ({
          ...state,
          [id]: {
            ...state[id],
            ...Case,
            caseType: camelize(Case.caseType),
          },
        }),
        state,
      )
    case FETCH_INDIRECT_CASES:
    case CREATE_INDIRECT_CONNECTIONS:
      return {
        ...state,
        [action.caseId]: {
          ...(state[action.caseId] || {}),
          indirectCasesFetchStatus: 'fetching',
        },
      }
    case FETCH_INDIRECT_CASES_FAILED:
    case CREATE_INDIRECT_CONNECTIONS_FAILED:
      return {
        ...state,
        [action.caseId]: {
          ...(state[action.caseId] || {}),
          indirectCasesFetchStatus: 'failed',
        },
      }

    case FETCH_INDIRECT_CASES_SUCCEEDED:
      if (!action.payload.entities.case) {
        return {
          ...state,
          [action.caseId]: {
            ...(state[action.caseId] || {}),
            indirectCasesFetchStatus: 'succeeded',
          },
        }
      }
      return Object.entries(action.payload.entities.case || {}).reduce(
        (state, [id, Case]) => ({
          ...state,
          [id]: {
            ...state[id],
            ...Case,
            caseType: camelize(Case.caseType),
          },
          [action.caseId]: {
            ...(state[action.caseId] || {}),
            indirectCasesFetchStatus: 'succeeded',
          },
        }),
        state,
      )
    case CREATE_INDIRECT_CONNECTIONS_SUCCEEDED:
      if (!action.payload.entities.case) {
        return {
          ...state,
          [action.caseId]: {
            ...(state[action.caseId] || {}),
            indirectCasesFetchStatus: 'succeeded',
          },
        }
      }
      return Object.entries(action.payload.entities.case || {}).reduce(
        (state, [id, Case]) => ({
          ...state,
          [id]: {
            ...state[id],
            ...Case,
            caseType: camelize(Case.caseType),
          },
          [action.caseId]: {
            ...(state[action.caseId] || {}),
            indirectCasesFetchStatus: 'succeeded',
            allIndirectCasesConfirmed: false,
          },
        }),
        state,
      )

    case FETCH_CASE:
      return {
        ...state,
        [action.caseId]: {
          ...(state[action.caseId] || {}),
          fetchStatus: 'fetching',
        },
      }
    case FETCH_CASE_FAILED:
      return {
        ...state,
        [action.caseId]: {
          ...(state[action.caseId] || {}),
          fetchStatus: 'failed',
        },
      }

    case CREATE_CONNECTION_APPLICATION_SUCCEEDED:
    case CREATE_CONNECTION_SUCCEEDED:
    case UPDATE_CONNECTION_SUCCEEDED:
    case POST_APPLICATION_BOARD_STATEMENT_SUCCEEDED:
    case POST_BOARD_STATEMENT_SUCCEEDED:
    case FETCH_CASE_SUCCEEDED:
    case MASS_CONFIRM_INDIRECT_CONNECTIONS_SUCCEEDED:
    case MULTI_APPROVE_CASES_SUCCEEDED:
      return Object.entries(action.payload.entities.case || {}).reduce(
        (state, [id, Case]) => ({
          ...state,
          [id]: {
            ...state[id],
            ...Case,
            fetchStatus: 'succeeded',
            caseType: camelize(Case.caseType),
          },
        }),
        state,
      )

    case COMMENT_ON_CASE:
      return {
        ...state,
        [action.caseId]: {
          ...(state[action.caseId] || {}),
          commentOnCaseStatus: 'pending',
        },
      }
    case COMMENT_ON_CASE_FAILED:
      return {
        ...state,
        [action.caseId]: {
          ...(state[action.caseId] || {}),
          commentOnCaseStatus: 'failed',
        },
      }
    case COMMENT_ON_CASE_SUCCEEDED:
      return Object.entries(action.payload.entities.case || {}).reduce(
        (state, [id, Case]) => ({
          ...state,
          [id]: {
            ...state[id],
            ...Case,
            caseType: camelize(Case.caseType),
            commentOnCaseStatus: 'succeeded',
          },
        }),
        state,
      )
    case REMOVE_CONNECTION_SUCCEEDED: {
      let newState = { ...state }
      const descendantsCaseIds = newState[action.caseId]?.descendantsCaseIds
      descendantsCaseIds.forEach(
        (descendantId) => delete newState[descendantId],
      )
      delete newState[action.caseId]
      return newState
    }

    case FETCH_COMPANY_CONNECTIONS_SUCCEEDED:
      var caseArr = []
      Object.entries(action.payload.entities)
        .filter(([entityKey]) =>
          [
            USER_OWNERSHIP_CASE,
            USER_INDIRECT_OWNERSHIP_CASE,
            RELATION_OWNERSHIP_CASE,
            RELATION_INDIRECT_OWNERSHIP_CASE,
            USER_ROLE_CASE,
            USER_INDIRECT_ROLE_CASE,
            RELATION_ROLE_CASE,
            RELATION_INDIRECT_ROLE_CASE,
          ].includes(entityKey),
        )
        .forEach((el) => {
          for (const [id, Case] of Object.entries(el[1])) {
            caseArr.push([id, Case])
          }
        })
      return caseArr.reduce(
        (state, [id, Case]) => ({
          ...state,
          [id]: {
            ...state[id],
            ...Case,
            fetchStatus: 'succeeded',
            caseType: camelize(Case.caseType),
          },
        }),
        state,
      )

    default:
      return state
  }
}

export const getCase = (state, id) => state[id] || {}
export const shouldFetchCase = (state, id) => !['succeeded', 'fetching', 'failed'].includes(getCase(state, id)?.fetchStatus)
export const getCaseFetchStatus = (state, id) => getCase(state, id)?.fetchStatus
export const isIndirectCasesPending = (state, id) => ['fetching', 'not fetched'].includes((getCase(state, id)?.indirectCasesFetchStatus))
export const getAllCases = (state) => Object.values(state)
export const getUserCasesOfType = (state, userId, caseType) => Object.values(state).filter(c => c.userId === userId && c.caseType === caseType)
export const getAllUserRelationCases = (state, userId) => Object.values(state).filter(c => c.caseType?.toLowerCase().includes('relation') && c.userId === userId)
export const getRelationCases = (state, relationId) => Object.values(state).filter(c => c.relationId === relationId)
export const isCommentOnCasePending = (state, caseId) => getCase(state, caseId)?.commentOnCaseStatus === 'pending'

export default byId
