import React from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import {
  isAdmin,
  isAuthenticated,
  getCurrentUser,
  isManager,
  isProjectAdmin,
} from '../../redux/selectors'
import Toolbar from './Toolbar'
import no from '../../icons/logo_no.svg'
import en from '../../icons/logo_en.svg'
import { useSelector } from 'react-redux'
import I18n from '../I18n'
import { getLanguage } from '../../redux/selectors'

const logos = {
  no,
  en,
}

const Header = ({ pending, isAdmin, isProjectAdmin, isManager, isAuthenticated, currentUser }) => {
  const language = useSelector((state) => getLanguage(state) )

  return (
    <Wrapper>
      <HeaderContent>
        <Link to="/">
          <Logo src={logos[language]} alt="" />
        </Link>
        {!pending && isAuthenticated && currentUser && (
          <Menu>
            {(isAdmin || isManager || isProjectAdmin) && (
              <>
                <MenuItem to={`/profile`}>
                  <I18n id="Profile" />
                </MenuItem>

                {isAdmin ||
                (isManager &&
                  process.env.REACT_APP_MANAGER_SHOW_EMLOYEES_ENABLED?.toLowerCase() ===
                    'true') ? (
                  <MenuItem exact to="/users">
                    <I18n id="Users" />
                  </MenuItem>
                ) : (
                  <></>
                )}

                {isAdmin && (
                  <MenuItem to="/companies">
                    <I18n id="Companies" />
                  </MenuItem>
                )}

                {isAdmin ||
                (isManager &&
                  process.env.REACT_APP_MANAGER_SHOW_EMLOYEES_ENABLED?.toLowerCase() ===
                    'true') ? (
                  <MenuItem to="/cases">
                    <I18n id="Cases" />
                  </MenuItem>
                ) : (
                  <></>
                )}

                {isAdmin && process.env.REACT_APP_APPLICATIONS_ENABLED?.toLowerCase() ===
                  'true' && (
                  <MenuItem to="/applications">
                    <I18n id="Applications" />
                  </MenuItem>
                )}

              </>
            )}
            {(isProjectAdmin && process.env.REACT_APP_PROJECTS_ENABLED?.toLowerCase() ===
                'true' && (
                <MenuItem to="/projects">
                  <I18n id="Projects" />
                </MenuItem>
              )
            )}
          </Menu>
        )}

        <Toolbar pending={pending}></Toolbar>
      </HeaderContent>
    </Wrapper>
  )
}

export const height = '3.6em'

const Wrapper = styled.header`
  background-color: ${({ theme: { primary } }) => primary};
`

const HeaderContent = styled.div`
  position: relative;
  height: ${height};
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  color: ${({ theme: { inverseText } }) => inverseText};
  padding: 0 0.75em;
`

const Logo = styled.img`
  margin-right: 4em;
  width: 10em;
`

const Menu = styled.nav`
  display: flex;
  height: 100%;
`

const MenuItem = ({ children, ...rest }) => (
  <MenuItemStyle {...rest}>
    <span>{children}</span>
  </MenuItemStyle>
)

const MenuItemStyle = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding: 0 1.5em;
  white-space: nowrap;
  font-size: ${({ theme: { largeText } }) => largeText};

  &:nth-child(1) {
    margin-right: 2em;
  }

  &.active {
    color: #b1d5f2;
    text-decoration: underline;
  }
  &:hover {
    color: #b1d5f2;
  }

  @media (max-width: 1100px) {
    font-size: ${({ theme: { normalText } }) => normalText};
  }
`

const mapStateToProps = (state) => ({
  isManager: isManager(state),
  isAdmin: isAdmin(state),
  isProjectAdmin: isProjectAdmin(state),
  isAuthenticated: isAuthenticated(state),
  currentUser: getCurrentUser(state),
})

export default connect(mapStateToProps)(Header)
