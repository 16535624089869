export default {
  primary: '#0D3688',
  secondary: '#3C91D4',

  pageBackground: '#F4F4F4',
  primaryBackground: '#fff',
  secondaryBackground: '#D6E4FF',
  leaderCommentBackground: 'rgba(60, 145, 212, 0.3)',
  allCommentBackground: 'rgba(172, 234, 255, 0.35)',
  modalBackground: '#F8F8F8',
  listItemHover: '#EBEBEB',

  text: '#0A0A0A',
  subtleText: '#636363',
  inverseText: '#fff',
  lightText: '#808080',

  error: '#AD324D',
  delete: '#BA414C',

  maxWidth: '1440px',
  smallText: '0.7rem',
  normalText: '0.875rem',
  largeText: '1.125rem',
}
