import styled from 'styled-components'
export { default as RadioButtons } from './RadioButtons'
export { default as Input } from './Input'
export { default as TextAreaInput } from './TextAreaInput'
export { default as DatePicker } from './DatePicker'
export { default as Field } from './Field'
export { default as HelpText } from './HelpText'
export { default as withErrorMessage } from './withErrorMessage'
export { default as Select } from './Select'
export { default as SimpleSelect } from './SimpleSelect'
export { default as CountrySelector } from './CountrySelector'
export { default as BirthdateInput } from './BirthdateInput'
export { default as OrganizationInput } from './OrganizationInput'
export { default as ParentCaseSelector } from './ParentCaseSelector'
export { default as ShareTypeSelector } from './ShareTypeSelector'
export { default as ProjectStatusSelector } from './ProjectStatusSelector'

export const Label = styled.label`
  color: ${({ theme: { text } }) => text};
`
