import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Button from '../components/Button'
import BackButton from '../components/BackButton'
import Page from '../components/Page'
import {
  getProject,
  isProjectPending,
  shouldFetchProject,
} from '../redux/selectors'
import { fetchProject } from '../redux/actions'
import ProjectHeader from '../components/ProjectHeader'
import AddProjectUserModal from '../components/AddProjectUserModal'
import AddProjectCompanyModal from '../components/AddProjectCompanyModal'
import DeleteProjectModal from '../components/DeleteProjectModal'
import ProjectCompanyList from '../components/ProjectCompanyList'
import ProjectUserList from '../components/ProjectUserList'
import ProjectHistory from '../components/ProjectHistory'
import I18n, { useI18nFunction } from '../components/I18n'

const Project = ({
  projectId,
  project,
  pending,
  shouldFetchProject,
  fetchProject,
}) => {
  const I18nFunction = useI18nFunction()

  useEffect(() => {
    shouldFetchProject && fetchProject(projectId)
  }, [shouldFetchProject, fetchProject, projectId])

  const [showAddProjectUserModal, setShowAddProjectUserModal] = useState(false)
  const [showAddProjectCompanyModal, setShowAddProjectCompanyModal] =
    useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [companyActions, setCompanyActions] = useState(null)
  const [userActions, setUserActions] = useState(null)

  const onAddProjectUserClicked = () => {
    setShowAddProjectUserModal(true)
  }

  const onAddProjectCompanyClicked = () => {
    setShowAddProjectCompanyModal(true)
  }

  const handleClick = () => {
    setUserActions(null)
    setCompanyActions(null)
  }

  return (
    <Page>
      {showAddProjectUserModal && (
        <AddProjectUserModal
          projectId={projectId}
          onClose={() => setShowAddProjectUserModal(false)}
        />
      )}
      {showAddProjectCompanyModal && (
        <AddProjectCompanyModal
          projectId={projectId}
          onClose={() => setShowAddProjectCompanyModal(false)}
        />
      )}
                
      {showDeleteModal &&
        <DeleteProjectModal
          project={project}
          onClose={() => setShowDeleteModal(false)}
        />
      }
      <OuterWrapper onClick={handleClick}>
        <BackButton subtle text={I18nFunction({ id: 'Back' })} />
        {project && !pending && <ProjectHeader project={project} setShowDeleteModal={setShowDeleteModal}/>}
        <ActionsWrapper>
          <Actions>
            <ActionButton
              small
              type="submit"
              onClick={() => onAddProjectUserClicked()}
            >
              <I18n id="Add_project_users" />
            </ActionButton>
            <ActionButton
              small
              type="submit"
              onClick={() => onAddProjectCompanyClicked()}
            >
              <I18n id="Add_project_companies" />
            </ActionButton>
            <ActionButton small onClick={() =>
                (window.location.href = `${window.location.origin}/api/projects/${project.id}/generate_project_pdf`)
              }>
                <I18n id="Export_project_pdf" />
            </ActionButton>
          </Actions>
        </ActionsWrapper>
      </OuterWrapper>
      <Content onClick={handleClick}>
        <UsersWrapper>
          <Title>Brukere</Title>
          <ProjectUserList
            projectId={projectId}
            projectUsers={project?.projectUsers}
            action={userActions}
            setAction={setUserActions}
            unsetCompanyActions={setCompanyActions}
          />
        </UsersWrapper>
        <CompaniesWrapper>
          <Title>Selskaper</Title>
          <ProjectCompanyList
            projectId={projectId}
            projectCompanies={project?.projectCompanies}
            action={companyActions}
            setAction={setCompanyActions}
            unsetUserActions={setUserActions}
          />
        </CompaniesWrapper>
        <HistoryWrapper>
        <Title>Historikk</Title>
          <History>
            <ProjectHistory project={project} />
          </History>
        </HistoryWrapper>
      </Content>
      </Page>
    )
}

const ActionsWrapper = styled.div`
  background-color: ${(props) => props.theme.secondaryBackground};
  padding: 1em;
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};

  & > * {
    margin: 0 0.5em;
  }
`

const ActionButton = styled(Button)`
  background: #0c3689;
  border: 2px solid #0c3689;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 10em;
`

const OuterWrapper = styled.div`
  color: black;
  padding-top: 0.5em;
  background-color: ${(props) => props.theme.secondaryBackground};
`

const Content = styled.div`
  display: flex;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
  padding: 0em;
  padding-top: 2em;
  gap: 1em;
`

const UsersWrapper = styled.div`
  width: 36%;
  height: 600px;
`

const CompaniesWrapper = styled.div`
  width: 36%;
  height: 600px;
`

const Title = styled.div`
  font-size: ${(props) => props.theme.largeText};
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 1em;
  font-weight: bold;
`

const HistoryWrapper = styled.div`
  width: 28%;
  padding-bottom: 1em;
`

const History = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  border-radius: 5px;
  padding: 2em;
`

// const HistoryTitle = styled.h2`
//   color: ${({ theme: { text } }) => text};
//   margin: 0;
//   padding-bottom: 1em;
// `

const mapStateToProps = (state, props) => ({
  project: getProject(state, props.projectId),
  shouldFetchProject: shouldFetchProject(state, props.projectId),
  pending: isProjectPending(state, props.projectId),
})

const mapDispatchToProps = { fetchProject }

export default connect(mapStateToProps, mapDispatchToProps)(Project)
