import * as yup from 'yup'

const schema = (obj) => yup.object().shape(obj)

// Rules
const specialCharRegex =
  /^[A-Za-z0-9,.\s&\\*";:!'`%()+,@æøåÆØÅüÜäÄöÖôÔèÈàÀòÒùÙáÁéÉóÓúÚíÍõÕñÑ\-\\_\\/\\]*$/

const requiredString = (I18nFunction, maxLength) =>
  yup
    .string()
    .nullable()
    .required(I18nFunction( { id: `validation.required`} ) ) 
    .min(1, I18nFunction( { id: `validation.required`} ))
    .max(maxLength, I18nFunction( { id: `validation.max_char_text`} )) 
    .matches(specialCharRegex, I18nFunction( { id: `validation.special_char`} ))

const number = yup.number().nullable()
const requiredNumber = (I18nFunction, message) =>
  number
    .typeError(message || I18nFunction( { id: `validation.required`} ))
    .required(message || I18nFunction( { id: `validation.required`} ))

const notRequiredNumber = (I18nFunction, message) =>
  number.typeError(message || I18nFunction( { id: `validation.required`} ))

const notRequiredString = (I18nFunction, maxLength) =>
  yup
    .string()
    .nullable()
    .max(maxLength, I18nFunction( { id: `validation.max_char_text`} ))
    .matches(specialCharRegex, I18nFunction( { id: `validation.special_char`} ))

const organizationName = requiredString

const parentCaseId = requiredString

const organizationNumber = (I18nFunction) =>
  yup
    .string()
    .when(['isCompanyRegistered'], {
      is: (isCompanyRegistered) => isCompanyRegistered !== 'yes',
      then: yup.string().nullable(),
      otherwise: yup.string().nullable(),
    })
    .when(['country', 'isCompanyRegistered'], {
      is: (country, isCompanyRegistered) =>
        country === 'NO' && isCompanyRegistered !== 'no',
      then: yup
        .string()
        .nullable()
        .required(I18nFunction( { id: `validation.required`} ))
        .matches(/^[\d ]*$/, I18nFunction( { id: `validation.orgnr_number`} ))
        .matches(/^[98]\S*$/, I18nFunction( { id: `validation.orgnr`} ))
        .test({
          test: (orgnr) => {
            if (!orgnr) return

            const weights = [3, 2, 7, 6, 5, 4, 3, 2]
            const split = orgnr.replace(/ /g, '').split('')
            const controlNumber =
              11 -
              (weights.reduce(
                (sum, weight, index) => sum + weight * split[index],
                0,
              ) % 11 || 11)

            return Number(split[split.length - 1]) === controlNumber
          },
          message: I18nFunction( { id: `validation.nor_orgnr`} ),
        }),
      otherwise: yup.string().nullable(),
    })
    .when(['country', 'isCompanyRegistered'], {
      is: (country, isCompanyRegistered) =>
        country !== 'NO' && isCompanyRegistered !== 'no',
      then: yup.string().nullable().required(I18nFunction( { id: `validation.required`} )),
      otherwise: yup.string().nullable(),
    })

const userId = requiredString
const country = requiredString
const shareType = requiredString
const roleType = requiredString
const title = requiredString
const description = requiredString
const project_status = notRequiredString
const project_assessment_status = notRequiredString
const fromDate = () => yup.date().nullable()
const toDate = (I18nFunction) =>
  yup
    .date()
    .nullable()
    .when('fromDate', (fromDate, schema) =>
      fromDate
        ? schema.min(fromDate, I18nFunction({ id: `validation.to_date` }))
        : schema,
    )
const stockCount = (I18nFunction) =>
  requiredNumber(I18nFunction).min(
    0,
    I18nFunction({ id: `validation.number_negative` }),
  )
const isCompanyListed = (I18nFunction, maxLength) =>
  yup.string().when('isCompanyRegistered', {
    is: (isCompanyRegistered) => isCompanyRegistered === 'no',
    then: yup.string().nullable(),
    otherwise: requiredString(I18nFunction, maxLength),
  })
const ownershipStatus = requiredString
const monthlyReward = (I18nFunction) =>
  requiredNumber(I18nFunction).min(
    0,
    I18nFunction({ id: `validation.number_negative` }),
  )
const notRequiredMonthlyReward = (I18nFunction) =>
  notRequiredNumber(I18nFunction).min(
    0,
    I18nFunction({ id: `validation.number_negative` }),
  )
const monthlyTimeCommitment = (I18nFunction) =>
  requiredNumber(I18nFunction).min(
    0,
    I18nFunction({ id: `validation.number_negative` }),
  )
const notRequredMonthlyTimeCommitment = (I18nFunction) =>
  notRequiredNumber(I18nFunction).min(
    0,
    I18nFunction({ id: `validation.number_negative` }),
  )
const firstName = requiredString
const lastName = requiredString
const isResidentAbroad = requiredString
const address = requiredString
const postCode = requiredString
const notRequredPostCode = notRequiredString
const postPlace = requiredString
const notRequiredPostPlace = notRequiredString
const relationType = requiredString
const birthdate_day = (I18nFunction) =>
  requiredNumber(I18nFunction, I18nFunction({ id: `validation.day_required` }))
    .min(1, I18nFunction({ id: `validation.day_range` }))
    .max(31, I18nFunction({ id: `validation.day_range` }))
const birthdate_month = (I18nFunction) =>
  requiredNumber(
    I18nFunction,
    I18nFunction({ id: `validation.month_required` }),
  )
    .min(1, I18nFunction({ id: `validation.month_range` }))
    .max(12, I18nFunction({ id: `validation.month_range` }))
const currentYear = new Date().getUTCFullYear()
const birthdate_year = (I18nFunction) =>
  requiredNumber(I18nFunction, I18nFunction({ id: `validation.year_required` }))
    .min(1900, I18nFunction({ id: 'validation.year_range', currentYear }))
    .max(
      currentYear,
      I18nFunction({ id: 'validation.year_range', currentYear }),
    )
const comment = (I18nFunction, maxLength) =>
  notRequiredString(I18nFunction, maxLength)
const isCaseRelevant = requiredString
const email = (I18nFunction, maxLength) =>
  yup
    .string()
    .email()
    .nullable()
    .required(I18nFunction({ id: `validation.required` }))
    .min(1, I18nFunction({ id: `validation.required` }))
    .max(maxLength, I18nFunction({ id: `validation.max_char_text` }))

export const role = (I18nFunction) => {
  if (process.env.REACT_APP_HABREG_CLIENT?.toLowerCase() === 'snn') {
    return schema({
      organizationName: organizationName(I18nFunction, 100),
      organizationNumber: organizationNumber(I18nFunction),
      country: country(I18nFunction, 50),
      roleType: roleType(I18nFunction, 50),
      title: title(I18nFunction, 100),
      fromDate: fromDate(I18nFunction),
      toDate: toDate(I18nFunction),
      postCode: postCode(I18nFunction, 6),
      postPlace: postPlace(I18nFunction, 50),
      monthlyReward: monthlyReward(I18nFunction, 50),
      monthlyTimeCommitment: monthlyTimeCommitment(I18nFunction, 50),
      comment: comment(I18nFunction, 500),
    })
  } else {
    return schema({
      organizationName: organizationName(I18nFunction, 100),
      organizationNumber: organizationNumber(I18nFunction),
      country: country(I18nFunction, 50),
      roleType: roleType(I18nFunction, 50),
      title: title(I18nFunction, 100),
      fromDate: fromDate(I18nFunction),
      toDate: toDate(I18nFunction),
      postCode: notRequredPostCode(I18nFunction, 6),
      postPlace: notRequiredPostPlace(I18nFunction, 50),
      monthlyReward: notRequiredMonthlyReward(I18nFunction, 50),
      monthlyTimeCommitment: notRequredMonthlyTimeCommitment(I18nFunction, 50),
      comment: comment(I18nFunction, 500),
      isCaseRelevant: isCaseRelevant(I18nFunction, 50),
    })
  }
}

export const ownership = (I18nFunction) => {
  if (process.env.REACT_APP_HABREG_CLIENT?.toLowerCase() === 'snn') {
    return schema({
      organizationName: organizationName(I18nFunction, 50),
      organizationNumber: organizationNumber(I18nFunction),
      country: country(I18nFunction, 50),
      stockCount: stockCount(I18nFunction),
      isCompanyListed: isCompanyListed(I18nFunction, 50),
      fromDate: fromDate(I18nFunction),
      toDate: toDate(I18nFunction),
      ownershipStatus: ownershipStatus(I18nFunction, 50),
      shareType: shareType(I18nFunction, 50),
      comment: comment(I18nFunction, 500),
    })
  } else {
    return schema({
      organizationName: organizationName(I18nFunction, 100),
      organizationNumber: organizationNumber(I18nFunction),
      country: country(I18nFunction, 50),
      stockCount: stockCount(I18nFunction),
      isCompanyListed: isCompanyListed(I18nFunction, 50),
      fromDate: fromDate(I18nFunction),
      toDate: toDate(I18nFunction),
      ownershipStatus: ownershipStatus(I18nFunction, 50),
      shareType: shareType(I18nFunction, 50),
      comment: comment(I18nFunction, 500),
      isCaseRelevant: isCaseRelevant(I18nFunction, 50),
    })
  }
}

export const indirectOwnership = (I18nFunction) => {
  if (process.env.REACT_APP_HABREG_CLIENT?.toLowerCase() === 'snn') {
    return schema({
      parentCaseId: parentCaseId(I18nFunction, 100),
      organizationName: organizationName(I18nFunction, 50),
      organizationNumber: organizationNumber(I18nFunction),
      country: country(I18nFunction, 50),
      stockCount: stockCount(I18nFunction),
      isCompanyListed: isCompanyListed(I18nFunction, 50),
      fromDate: fromDate(I18nFunction),
      toDate: toDate(I18nFunction),
      ownershipStatus: ownershipStatus(I18nFunction, 50),
      comment: comment(I18nFunction, 500),
    })
  } else {
    return schema({
      parentCaseId: parentCaseId(I18nFunction, 100),
      organizationName: organizationName(I18nFunction, 100),
      organizationNumber: organizationNumber(I18nFunction),
      country: country(I18nFunction, 50),
      stockCount: stockCount(I18nFunction),
      isCompanyListed: isCompanyListed(I18nFunction, 50),
      fromDate: fromDate(I18nFunction),
      toDate: toDate(I18nFunction),
      ownershipStatus: ownershipStatus(I18nFunction, 50),
      comment: comment(I18nFunction, 500),
      isCaseRelevant: isCaseRelevant(I18nFunction, 50),
    })
  }
}

export const otherConnection = (I18nFunction) => {
  return schema({
    organizationName: organizationName(I18nFunction, 100),
    title: title(I18nFunction, 50),
    fromDate: fromDate(I18nFunction),
    toDate: toDate(I18nFunction),
    comment: comment(I18nFunction, 500),
  })
}

export const relation = (I18nFunction) =>
  schema({
    firstName: firstName(I18nFunction, 50),
    lastName: lastName(I18nFunction, 50),
    email: email(I18nFunction, 50),
    isResidentAbroad: isResidentAbroad(I18nFunction, 50),
    address: address(I18nFunction, 100),
    postCode: postCode(I18nFunction, 6),
    postPlace: postPlace(I18nFunction, 50),
    relationType: relationType(I18nFunction, 50),
    birthdate_day: birthdate_day(I18nFunction),
    birthdate_month: birthdate_month(I18nFunction),
    birthdate_year: birthdate_year(I18nFunction),
    comment: comment(I18nFunction, 500),
  })

export const birthdateForm = (I18nFunction) => {
  return schema({
    birthdate_day: birthdate_day(I18nFunction),
    birthdate_month: birthdate_month(I18nFunction),
    birthdate_year: birthdate_year(I18nFunction),
    address: address(I18nFunction, 100),
    postCode: postCode(I18nFunction, 6),
    postPlace: postPlace(I18nFunction, 100),
  })
}

export const addressForm = (I18nFunction) =>
  schema({
    address: address(I18nFunction, 100),
    postCode: postCode(I18nFunction, 6),
    postPlace: postPlace(I18nFunction, 100),
  })

export const addSupplier = (I18nFunction) =>
  schema({
    organizationName: organizationName(I18nFunction),
    organizationNumber: organizationNumber(I18nFunction),
    country: country(I18nFunction),
  })

export const projectForm = (I18nFunction) =>
  schema({
    title: title(I18nFunction, 100),
    description: description(I18nFunction, 500),
    project_status: project_status(I18nFunction, 50),
    project_assessment_status: project_assessment_status(I18nFunction, 50)
  })

export const projectCompanyForm = (I18nFunction) =>
  schema({
    country: country(I18nFunction, 50),
    organizationName: organizationName(I18nFunction, 100),
    organizationNumber: organizationNumber(I18nFunction),
  })

export const projectUserForm = (I18nFunction) =>
  schema({
    userId: userId(I18nFunction, 50),
  })