import { schema } from 'normalizr'
import {
  USER_ROLE,
  PUBLIC_DATA_ROLE,
  USER_OWNERSHIP,
  PUBLIC_DATA_OWNERSHIP,
  USER_OTHER_CONNECTION,
  RELATION,
  RELATION_ROLE,
  RELATION_OWNERSHIP,
  RELATION_OTHER_CONNECTION,
  RELATION_PUBLIC_DATA_ROLE,
  RELATION_PUBLIC_DATA_OWNERSHIP,
} from '../connectionTypes'

import {
  USER_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  USER_OTHER_CONNECTION_CASE,
  RELATION_OWNERSHIP_CASE,
  RELATION_ROLE_CASE,
  RELATION_OTHER_CONNECTION_CASE,
} from '../caseTypes'

export const suggestion = new schema.Entity(
  'suggestion',
  {},
  {
    idAttribute: 'organizationNumber',
  },
)

export const arrayOfSuggestions = new schema.Array(suggestion)

export const user = new schema.Entity('user')
export const arrayOfUsers = new schema.Array(user)

export const event = new schema.Entity('event')
export const arrayOfEvents = new schema.Array(event)

export const notification = new schema.Entity('notification')
export const arrayOfNotifications = new schema.Array(notification)
export const objectOfNotifications = new schema.Object(notification)

export const connection = new schema.Entity('connection')

export const Case = new schema.Entity('case')
export const arrayOfCase = new schema.Array(Case)

export const ownershipApplication = new schema.Entity('ownershipApplication')
export const arrayOfOwnershipApplications = new schema.Array(
  ownershipApplication,
)

export const roleApplication = new schema.Entity('roleApplication')
export const arrayOfRoleApplications = new schema.Array(roleApplication)

export const userRole = new schema.Entity(USER_ROLE)
export const arrayOfUserRoles = new schema.Array(userRole)

export const userOwnership = new schema.Entity(USER_OWNERSHIP)
export const arrayOfUserOwnerships = new schema.Array(userOwnership)

export const userOtherConnection = new schema.Entity(USER_OTHER_CONNECTION)
export const arrayOfUserOtherConnections = new schema.Array(userOtherConnection)

export const userRoleCase = new schema.Entity(USER_ROLE_CASE)
export const arrayOfUserRoleCases = new schema.Array(userRoleCase)

export const userOwnershipCase = new schema.Entity(USER_OWNERSHIP_CASE)
export const arrayOfUserOwnershipCases = new schema.Array(userOwnershipCase)

export const userOtherConnectionCase = new schema.Entity(
  USER_OTHER_CONNECTION_CASE,
)
export const arrayOfUserOtherConnectionCases = new schema.Array(
  userOtherConnectionCase,
)

export const relationRoleCase = new schema.Entity(RELATION_ROLE_CASE)
export const arrayOfRelationRoleCases = new schema.Array(relationRoleCase)

export const relationOwnershipCase = new schema.Entity(RELATION_OWNERSHIP_CASE)
export const arrayOfRelationOwnershipCases = new schema.Array(
  relationOwnershipCase,
)

export const relationOtherConnectionCase = new schema.Entity(
  RELATION_OTHER_CONNECTION_CASE,
)
export const arrayOfRelationOtherConnectionCases = new schema.Array(
  relationOtherConnectionCase,
)

export const relation = new schema.Entity(
  RELATION,
  {},
  {
    processStrategy: (relation) => {
      const [year, month, day] = relation.birthdate
        ? relation.birthdate.split('-')
        : []
      return {
        ...relation,
        birthdate_day: day,
        birthdate_month: month,
        birthdate_year: year,
      }
    },
  },
)

export const relation_proff = new schema.Entity('relation')
export const arrayOfRelations = new schema.Array(relation)

export const relationRole = new schema.Entity(RELATION_ROLE)
export const arrayOfRelationRoles = new schema.Array(relationRole)

export const relationOwnership = new schema.Entity(RELATION_OWNERSHIP)
export const arrayOfRelationOwnerships = new schema.Array(relationOwnership)

export const relationOtherConnection = new schema.Entity(
  RELATION_OTHER_CONNECTION,
)
export const arrayOfRelationOtherConnections = new schema.Array(
  relationOtherConnection,
)

export const publicDataRole = new schema.Entity(
  PUBLIC_DATA_ROLE,
  {},
  {
    processStrategy: (publicDataRole) => ({
      ...publicDataRole,
      country: 'NO',
    }),
  },
)
export const arrayOfPublicDataRoles = new schema.Array(publicDataRole)

export const publicDataOwnership = new schema.Entity(
  PUBLIC_DATA_OWNERSHIP,
  {},
  {
    processStrategy: (publicDataRole) => ({
      ...publicDataRole,
      country: 'NO',
    }),
  },
)
export const arrayOfPublicDataOwnerships = new schema.Array(publicDataOwnership)

export const relationPublicDataRole = new schema.Entity(
  RELATION_PUBLIC_DATA_ROLE,
  {},
  {
    processStrategy: (relationPublicDataRole) => ({
      ...relationPublicDataRole,
      country: 'NO',
    }),
  },
)
export const arrayOfRelationPublicDataRoles = new schema.Array(
  relationPublicDataRole,
)

export const relationPublicDataOwnership = new schema.Entity(
  RELATION_PUBLIC_DATA_OWNERSHIP,
  {},
  {
    processStrategy: (relationPublicDataRole) => ({
      ...relationPublicDataRole,
      country: 'NO',
    }),
  },
)
export const arrayOfRelationPublicDataOwnerships = new schema.Array(
  relationPublicDataOwnership,
)

export const publicRelationDataResponse = new schema.Object({
  relationPublicDataRole: arrayOfRelationPublicDataRoles,
  relationPublicDataOwnership: arrayOfRelationPublicDataOwnerships,
})

export const eventSetting = new schema.Entity(
  'eventSetting',
  {},
  {
    idAttribute: 'type',
  },
)

export const arrayOfEventSettings = new schema.Object(eventSetting)
export const appSettingsResponse = new schema.Object({
  eventSettings: arrayOfEventSettings,
})

export const userProfileDataResponse = new schema.Object({
  case: arrayOfCase,
  relation: arrayOfRelations,
})

export const indirectCasesResponse = new schema.Object({
  case: arrayOfCase
})

export const connectionsResponse = new schema.Object({
  userRole: arrayOfUserRoles,
  userOwnership: arrayOfUserOwnerships,
  userOtherConnection: arrayOfUserOtherConnections,
  relation: arrayOfRelations,
  relationRole: arrayOfRelationRoles,
  relationOwnership: arrayOfRelationOwnerships,
  relationOtherConnection: arrayOfRelationOtherConnections,
  publicDataRole: arrayOfPublicDataRoles,
  publicDataOwnership: arrayOfPublicDataOwnerships,
  relationPublicDataRole: arrayOfRelationPublicDataRoles,
  relationPublicDataOwnership: arrayOfRelationPublicDataOwnerships,
})

export const applicationResponse = new schema.Object({
  case: arrayOfCase,
  ownershipApplication: arrayOfOwnershipApplications,
  roleApplication: arrayOfRoleApplications,
})

export const casesResponse = new schema.Object({
  userRoleCase: arrayOfUserRoleCases,
  userOwnershipCase: arrayOfUserOwnershipCases,
  userOtherConnectionCase: arrayOfUserOtherConnectionCases,
  relationRoleCase: arrayOfRelationRoleCases,
  relationOwnershipCase: arrayOfRelationOwnershipCases,
  relationOtherConnectionCase: arrayOfRelationOtherConnectionCases,
})

export const publicDataResponse = new schema.Object({
  publicDataRole: arrayOfPublicDataRoles,
  publicDataOwnership: arrayOfPublicDataOwnerships,
})

export const proffPersonResult = new schema.Object('proffResult')
export const arrayOfProffPersonResult = new schema.Array(proffPersonResult)

const companyOptions = {
  idAttribute: 'organizationNumber',
  processStrategy: (company) => ({
    country: 'NO',
    ...company,
  }),
}

export const company = new schema.Entity(
  'company',
  {
    connections: {
      users: arrayOfUsers,
      userRole: arrayOfUserRoles,
      userOwnership: arrayOfUserOwnerships,
      relationRole: arrayOfRelationRoles,
      relationOwnership: arrayOfRelationOwnerships,
      publicDataRole: arrayOfPublicDataRoles,
      publicDataOwnership: arrayOfPublicDataOwnerships,
      relationPublicDataRole: arrayOfRelationPublicDataRoles,
      relationPublicDataOwnership: arrayOfRelationPublicDataOwnerships,
      relation: arrayOfRelations,
    },
    cases: {
      userRoleCase: arrayOfUserRoleCases,
      userOwnershipCase: arrayOfUserOwnershipCases,
      userOtherConnectionCase: arrayOfUserOtherConnectionCases,
      relationRoleCase: arrayOfRelationRoleCases,
      relationOwnershipCase: arrayOfRelationOwnershipCases,
      relationOtherConnectionCase: arrayOfRelationOtherConnectionCases,
    },
  },
  companyOptions,
)
export const arrayOfCompanies = new schema.Array(company)

export const uploadSuppliers = new schema.Object('fileUploadEvent')

export const uploadCustomers = new schema.Object('fileUploadEvent')

export const dataUpdateLog = new schema.Object('dataUpdateLog')

export const project = new schema.Entity('project')
export const arrayOfProjects = new schema.Array(project)
