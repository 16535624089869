// reducer.js
import {
  FETCH_APP_CONTENT_SUCCEEDED,
  FETCH_APP_CONTENT_FAILED,
  SET_STATIC_APP_CONTENT,
} from './index'
import staticContent from './static_default'
import { getDefaultLanguage } from './languageUtils';

const language = getDefaultLanguage();

const initialState = staticContent[language] // Load the public content for the default language

export const appContent = (state = initialState, action) => {

  if (!action) {
    console.error('Reducer was called without an action')
    return state
  }
  switch (action.type) {
    case FETCH_APP_CONTENT_FAILED:
      return {
        ...state,
        error: action.errors,
      }
    case FETCH_APP_CONTENT_SUCCEEDED:
    case SET_STATIC_APP_CONTENT:
    
      return action.payload
      
    default:
      return { ...state }
  }
}
