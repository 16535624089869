import { useState, useEffect } from 'react'

const useDelaySetFalse = (value, delay) => {
    const [delayedValue, setDelayedValue] = useState(true)

    useEffect(() => {
      let timer
      if (value) setDelayedValue(true)
      else
        timer = setTimeout(() => {
          setDelayedValue(false)
        }, delay) //Keep the spinner for delay milliseconds to show something is happening.
      // Clean up the timer when the component unmounts or when value changes
      return () => {
        if (timer) {
          clearTimeout(timer)
        }
      }
    }, [value])
    return delayedValue;
  }

  export default useDelaySetFalse;