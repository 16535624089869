import React from 'react'
import { connect } from 'react-redux'
import { Select } from '../index'
import { getAllCases, getSelectedCase } from '../../../redux/selectors'

const ParentCaseSelector = (props) => {

  return (
    <Select
      options={Object.entries(props.parentCases).map(([code, name]) => ({
        label: name,
        value: code,
      }))}
      {...props}
      onChange={({ value }, { name }) =>
        props.onChange({ target: { name, value } })
      }
      disabled={props.disabled}
      value={
        props.value !== undefined && {
          label: props.parentCases[props.value],
          value: props.value,
        }
      }
    />
  )
}

const mapStateToProps = (state, props) => {
  var parentCases = {}
  const Case = getSelectedCase(state)
  const caseId = Case?.id

  parentCases[caseId] = `${Case?.organizationName} (#${caseId})`
  getAllCases(state).filter((Case) => Case.directConnectionCaseId == caseId).map((c) => (
    parentCases[c.id] = `${c.organizationName} (#${c.id})`
  ))

  return{
     parentCases: parentCases
  }
}

export default connect(mapStateToProps)(ParentCaseSelector)