import { call, put, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  FETCH_ME,
  FETCH_NOTIFICATIONS,
  DEFAULT_NOTIFICATION_PAGE_INDEX,
} from '../currentUser'
import {
  CHECK_AUTH_STATUS,
  CHECK_AUTH_STATUS_SUCCEEDED,
  CHECK_AUTH_STATUS_FAILED,
  LOG_OUT,
  LOG_OUT_SUCCEEDED,
  LOG_OUT_FAILED,
  VERIFY_AUTH_CODE,
  VERIFY_AUTH_CODE_SUCCEEDED,
  VERIFY_AUTH_CODE_FAILED,
} from './actionTypes'
import { get, post } from '../fetch'
import { fetchAppContent } from '../actions'

function* checkAuthStatus() {
  try {
    const { data, errors } = yield call(() => get('/check_auth_status'))
    if (errors) {
      yield put({ type: CHECK_AUTH_STATUS_FAILED, errors })
    } else {
      yield put({
        type: CHECK_AUTH_STATUS_SUCCEEDED,
        authenticated: data.authenticated,
        admin: data.admin,
      })
    }
  } catch (e) {
    console.error(e)
    yield put({
      type: CHECK_AUTH_STATUS_FAILED,
      errors: [{ detail: e.message }],
    })
  }
}

function* verifyAuthCode({ code, state }) {
  try {
    const { data, errors } = yield call(() =>
      post('/auth/verify', { code, state }),
    )
    if (errors) {
      yield put({ type: VERIFY_AUTH_CODE_FAILED, errors })
    } else {
      yield put({
        type: VERIFY_AUTH_CODE_SUCCEEDED,
        authenticated: data.authenticated,
        admin: data.admin,
      })
    }
  } catch (e) {
    console.error(e)
    yield put({
      type: VERIFY_AUTH_CODE_FAILED,
      errors: [{ detail: e.message }],
    })
  }
}

function* logOut() {
  try {
    const { errors } = yield call(() => post('/logout'))
    if (errors) {
      yield put({ type: LOG_OUT_FAILED, errors })
    } else {
      yield put({ type: LOG_OUT_SUCCEEDED })
    }
  } catch (e) {
    console.error(e)
    yield put({ type: LOG_OUT_FAILED, errors: [{ detail: e.message }] })
  }
}

function* goToRoot() {
  yield call(() => window.history.replaceState(null, '', './'))
  yield put(push('/'))
}

function* putFetchMe({ authenticated }) {
  if (authenticated) {
    yield put({ type: FETCH_ME })
    yield put({ type: FETCH_NOTIFICATIONS, notificationsPageIndex: DEFAULT_NOTIFICATION_PAGE_INDEX, onlyUnread: false})
  }
}

function* putFetchAppContent({ authenticated }) {
  try {
    if (authenticated) {
      yield put(fetchAppContent())
    }
  } catch (e) {
    console.error('Error fetching private content:', e)
  }
}

function* saga() {
  yield takeEvery(CHECK_AUTH_STATUS, checkAuthStatus)
  yield takeEvery(VERIFY_AUTH_CODE, verifyAuthCode)
  yield takeEvery(VERIFY_AUTH_CODE_SUCCEEDED, checkAuthStatus)
  yield takeEvery(VERIFY_AUTH_CODE_SUCCEEDED, goToRoot)
  yield takeEvery(CHECK_AUTH_STATUS_SUCCEEDED, putFetchAppContent)
  yield takeEvery(CHECK_AUTH_STATUS_SUCCEEDED, putFetchMe)

  yield takeEvery(LOG_OUT, logOut)
  yield takeEvery(LOG_OUT_SUCCEEDED, goToRoot)

}

export default saga
