import React, { useContext } from 'react'
import { CountrySelector } from '../form'
import { ConnectionFormContext } from './ConnectionForm'

const ConnectionInput = (props) => {
  const { connection, handleChange, handleBlur, visited, errors } = useContext(
    ConnectionFormContext,
  )
  return (
    <CountrySelector
      name="country"
      value={connection.country}
      visited={visited.country}
      error={errors.country}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={props.locked}
    />
  )
}

export default ConnectionInput
