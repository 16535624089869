import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Button from './Button'
import MultiApproveCasesListItem from './MultiApproveCasesListItem.js'
import { multiApproveCases } from '../redux/actions'
import I18n, { useI18nFunction } from './I18n'

const MultiApproveCasesModal = ({
  selectedCaseIds,
  setShowMultiApproveCasesModal,
  cases,
  selectedCases,
  multiApproveCases,
  clearAllCases
  }) => {

    const I18nFunction = useI18nFunction();

    const [commentText, setCommentText] = useState("")
    const [casesApprovedMessage, setCasesApprovedMessage] = useState(false)

    const onClose = () => {
      setShowMultiApproveCasesModal(false)
      clearAllCases()
    }

    return (
      <Overlay onClick={onClose}>
        <ContentWrapper onClick={(e) => e.stopPropagation()}>
            <BackButton 
              onClick={onClose}
              >
              <I18n id="Cancel"/>
            </BackButton>
            <OwnershipColumns>
              <SelectedCasesColumn>
                <Title>
                  {I18nFunction({
                    id: 'multi_approve_cases.selected_cases',
                    state: "selected",
                    count: selectedCaseIds.length,
                  })}
                </Title>
                <Scrollbox>
                  <List>
                    {selectedCases.map((Item) => ( 
                      <li key={Item.id ? (Item.caseId ? Item.caseId + '-' + Item.id : Item.id) : -1}>
                        <MultiApproveCasesListItem
                          Item={Item} 
                        />
                      </li>
                    ))}
                  </List>
                </Scrollbox>
              </SelectedCasesColumn>
              {casesApprovedMessage ? (
                  <CommentColumn>
                    <ApprovedMessage>
                      <Title>
                        {I18nFunction({
                            id: 'multi_approve_cases.selected_cases',
                            state: "approved",
                            count: selectedCaseIds.length,
                        })}
                      </Title>
                      <Button 
                        onClick={onClose}
                        >
                        <I18n id="GoBack"/>
                      </Button>
                    </ApprovedMessage>
                  </CommentColumn> 
                ):(
                  <CommentColumn>
                    <CommentDescription>
                      <Title>
                        <I18n id="change_status_title" />
                      </Title>
                      <p>
                        <i>
                          <I18n id="multi_approve_cases.comment_description" />
                        </i>
                      </p>
                    </CommentDescription>
                    <CommentForm>
                      <Comment
                        placeholder={I18nFunction({ id: "comment_placeholder" })}
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                        required
                      />
                      <ButtonWrapper>
                        <Button
                              pending={false}
                              type="submit"
                              onClick={() => multiApproveCases(selectedCaseIds, 'approved', commentText) 
                                              && setCasesApprovedMessage(true)}
                              disabled={!commentText}
                              inactive={!commentText}
                            >
                            <I18n id="multi_approve_cases.approve_selected_cases" />
                        </Button>
                      </ButtonWrapper>
                    </CommentForm>
                  </CommentColumn>
                )
              }
            </OwnershipColumns>
          </ContentWrapper>
      </Overlay>
    )
  }

const ApprovedMessage = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5%;
  background-color: ${(props) => props.theme.pageBackground};
`

const Title = styled.h2`
  margin-top: 0px;
`

const BackButton = styled.button.attrs({
    type: 'button',
  })`
    -webkit-appearance: initial !important;
    right: 2%;
    top: 2%;
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    border: none;
    color: ${({ theme }) => theme.secondary};
    font-size: 1em;
    background-color: ${(props) => props.theme.primaryBackground};
  `

const List = styled.ul`
  list-style: none;
  margin: 0;
  margin-bottom: 5em;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 0.5em;

`

const Scrollbox = styled.div`
  margin: 0;
  position: relative;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
  height: 90%;
  overflow: auto;
  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const SelectedCasesColumn = styled.div`
  width: 65%;
  background-color: ${(props) => props.theme.pageBackground};
  padding: 2em;
  margin: 0 auto;
`

const CommentColumn = styled.div`
  width: 35%;
  background-color: ${(props) => props.theme.pageBackground};
  padding: 2em;
  margin: 0 auto;

`

const CommentDescription = styled.div`
  height: 20%
`

const CommentForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 80%;
`

const Comment = styled.textarea`
  display: block;
  width: 100%;
  min-height: 25em;
  border: none;
  margin: 1rem auto;
  padding: 2rem;
  background: #fff;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
`

const OwnershipColumns = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  height: 100%;
  gap: 3em;
  padding: 3em;;
`

const ContentWrapper = styled.div`
  overflow: auto;
  position: relative;
  background-color: ${(props) => props.theme.primaryBackground};
  margin: 0 auto;
  height: 80%;
  width: 85%;
  -moz-box-shadow: 0px 0px 18px 6px #ccc;
  -webkit-box-shadow: 0px 0px 18px 6px #ccc;
  box-shadow: 0px 0px 10px 0px #ccc;
  display: flex;
  flex-direction: column;
`

const Overlay = styled.div`
  overflow: auto
  animation: none;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`

const mapStateToProps = (state, props) => ({
  selectedCases: props.cases.filter((c) => props.selectedCaseIds.includes(c.id))
})

const mapDispatchToProps = {
  multiApproveCases,
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiApproveCasesModal)

