/* eslint-disable */
import React from 'react'
import { AssembleContent, updatedHTMLObjectData } from './htmlRenderUtils';


// Main App Functions Below

export default {
  footer_copy_right: ({ functionData }) => {
    return (
      <p>
        &copy; {new Date().getFullYear()} {functionData.all_rights_reserved}
      </p>
    )
  },
  connection_form: {
    help_texts: {
      is_supplier: ({ HABREG_CLIENT_NAME, functionData }) => {
        return functionData.data1.replace('{clientName}', HABREG_CLIENT_NAME)
      },
    },
    headings: {
      datasource_prefixes: ({ connection, functionData }) => {
        if (connection.isApplication) {
          return functionData.data1
        }
        if (connection.fetchedOn) {
          return functionData.data2
        }
        return functionData.data3
      },
    },

    tabs: {
      role: {
        renderInfo: ({ HABREG_CLIENT_NAME, functionData }) => {
          const updatedFunctionData = updatedHTMLObjectData(
            functionData,
            '{props.HABREG_CLIENT_NAME}',
            HABREG_CLIENT_NAME,
          )

          return <AssembleContent data={updatedFunctionData} />
        },
      },
      relation: {
        renderInfo: ({ HABREG_CLIENT_NAME, functionData }) => {
          const updatedFunctionData = updatedHTMLObjectData(
            functionData,
            '{props.HABREG_CLIENT_NAME}',
            HABREG_CLIENT_NAME,
          )

          return <AssembleContent data={updatedFunctionData} />
        },
      },
    },
  },
  ConnectionType: ({ caseType, functionData }) => {
    if (caseType.includes('IndirectOwnership')) return functionData.indirect_ownership
    if (caseType.includes('IndirectRole')) return functionData.indirect_connection
    if (caseType.includes('Ownership')) return functionData.ownership
    if (caseType.includes('Role')) return functionData.role
    if (caseType.includes('Other')) return functionData.other
  },


  Current_supplier_status: ({ functionData, ...props }) => {
    if (props.supplierRelations?.length > 0) {
      return (
        <span>
          <b>{functionData.isSupplier}</b>
          <ul style={{ marginBottom: '0px' }}>
            {props.supplierRelations.map((e) => (
              <li
                key={e.supplierOfOrganizationNumber}
                style={{ margin: '0px' }}
              >
                {e.supplierOfOrganizationName} ({e.supplierOfOrganizationNumber}
                )
              </li>
            ))}
          </ul>
        </span>
      )
    }
    return (
      <span>
        {functionData.isNotSupplier.map((segment, index) =>
          segment.style === 'bold' ? (
            <b key={index}>{segment.text}</b>
          ) : (
            segment.text
          ),
        )}
      </span>
    )
  },
  Current_client_status: ({ functionData, ...props }) => {
    if (props.customerRelations?.length > 0) {
      return (
        <span>
          <b>{functionData.isClient}</b>
          <ul style={{ marginBottom: '0px' }}>
            {props.customerRelations.map((e) => (
              <li
                key={e.customerOfOrganizationNumber}
                style={{ margin: '0px' }}
              >
                {e.customerOfOrganizationName} ({e.customerOfOrganizationNumber}
                )
              </li>
            ))}
          </ul>
        </span>
      )
    }
    return (
      <span>
        {functionData.isNotClient.map((segment, index) =>
          segment.style === 'bold' ? (
            <b key={index}>{segment.text}</b>
          ) : (
            segment.text
          ),
        )}
      </span>
    )
  },

  connection_list_item: {
    ownership_description: ({ stockCount, shareType, stakePercentage, functionData }) => {
      const template = functionData[shareType] || functionData.default
      return (!stakePercentage || isNaN(stakePercentage))
      ? template.replace('{stockCount}', stockCount || "").replace('{stakePercentage}', "").replace(/[%()]/g, '')
      : template.replace('{stockCount}', stockCount || "").replace('{stakePercentage}', stakePercentage);    
    },

    ownership_application_description: ({
      stockCount,
      shareType,
      functionData,
    }) => {
      const template = functionData[shareType] || functionData.default
      return template.replace('{stockCount}', stockCount)
    },
  },
  case_events: {
    case_updated: ({
      property,
      propertyOldValue,
      propertyNewValue,
      functionData,
      I18nFunction,
    }) => {
      if (!property) {
        return functionData.default
      }
      if (['manager_statement', 'board_statement'].includes(property)) {
        return functionData.statement_change.replace(
          '{property}',
          I18nFunction({ id: `case_properties.${property}` }),
        )
      }
      if (property === 'status') {
        return functionData.status_change
          .replace(
            '{property}',
            I18nFunction({ id: `case_properties.${property}` }),
          )
          .replace(
            '{oldStatus}',
            I18nFunction({ id: `case_statuses.${propertyOldValue}` }),
          )
          .replace(
            '{newStatus}',
            I18nFunction({ id: `case_statuses.${propertyNewValue}` }),
          )
      }
      return functionData.generic_change
        .replace(
          '{property}',
          I18nFunction({ id: `case_properties.${property}` }),
        )
        .replace('{oldValue}', propertyOldValue)
        .replace('{newValue}', propertyNewValue)
    },

    case_data_mismatch: ({ property, functionData, I18nFunction }) => {
      if (property) {
        return functionData.mismatch.replace(
          '{property}',
          I18nFunction({ id: `connection_form.labels.${property}` }),
        )
      }
      return functionData.default
    },

    case_conflict_in_project: ({ property, functionData, I18nFunction }) => {
      console.log('property', property)
      if (property) {
        console.log(property)
        return functionData.conflict.replace('{property}', property)
      }
      return functionData.default
    },

    application_created: ({ applicationId, functionData }) => {
      if (!applicationId) {
        return functionData.default;
      } else {
        return functionData.with_id.replace('{applicationId}', applicationId);
      }
    },



    application_updated: ({ property, propertyOldValue, propertyNewValue, functionData, I18nFunction }) => {
      if (!property) {
        return functionData.default;
      }
      if (['manager_statement', 'board_statement'].includes(property)) {
        return functionData.statement_changed.replace('{property}', I18nFunction({
          id: `case_properties.${property}`,
        }));
      }
      if (property === 'status') {
        return functionData.status_changed
          .replace('{property}', I18nFunction({ id: `application_properties.${property}` }))
          .replace('{propertyOldValue}', I18nFunction({ id: `application_statuses.${propertyOldValue}` }))
          .replace('{propertyNewValue}', I18nFunction({ id: `application_statuses.${propertyNewValue}` }));
      }
      return functionData.generic_change
        .replace('{property}', I18nFunction({ id: `application_properties.${property}` }))
        .replace('{propertyOldValue}', propertyOldValue)
        .replace('{propertyNewValue}', propertyNewValue);
    },



    case_company_updated: ({functionData, ...props}) => {
      if (props.property == 'is_customer') {
        return functionData.is_customer
          .replace('{organizationName}', props.organizationName)
          .replace('{action}', props.property_new_value === true ? functionData.become_action : functionData.no_longer_action)
          .replace('{userCompanyName}', props.userCompanyName || props.HABREG_CLIENT_NAME);
      }

      
      if (props.property == 'is_supplier') {
        return functionData.is_supplier
          .replace('{organizationName}', props.organizationName)
          .replace('{action}', props.property_new_value === true ? functionData.become_action : functionData.no_longer_action)
          .replace('{userCompanyName}', props.userCompanyName || props.HABREG_CLIENT_NAME);
      }

      return functionData.default;
    },
  },



  
  application_id_updates: ({ applicationId, functionData }) => {
    if (applicationId) {
      return functionData.with_id.replace('{applicationId}', applicationId);
    } else {
      return functionData.without_id;
    }
  },

  filterType: ({ payload, functionData }) => {
    return functionData[payload] || "";
  },

  company_events: {

    company_added: ({ payload: { organizationName }, functionData }) => {
      return functionData.template.replace('{organizationName}', organizationName);
    },


    company_customer_relation_updated: ({ payload: { organizationName, customerOfOrganizationName, addedOrRemoved }, functionData }) => {
      const template =
        addedOrRemoved === 'added'
          ? functionData.addedTemplate
          : functionData.removedTemplate;
      
      return template
        .replace('{organizationName}', organizationName)
        .replace('{customerOfOrganizationName}', customerOfOrganizationName);
    },


    company_supplier_relation_updated: ({
      payload: { organizationName, supplierOfOrganizationName, addedOrRemoved },
      functionData
    }) => {
      const template =
        addedOrRemoved === 'added'
          ? functionData.addedTemplate
          : functionData.removedTemplate;
      
      return template
        .replace('{organizationName}', organizationName)
        .replace('{supplierOfOrganizationName}', supplierOfOrganizationName);
    },


  },

  multi_approve_cases: {
    selected_cases: ({ count, state, functionData }) => {
      const template = functionData[state]
      return template.replace('{count}', count)
    },
  },

  indirect_ownership_list: {
    indirect_title: ({ num_indirect, isRole, functionData }) => {
      const template = functionData[isRole ? "indirect_connections" : "indirect_ownership"]
      if (num_indirect){
        return template.replace('{num_indirect}', num_indirect)
      }
      return template.replace(' ({num_indirect})', "")
    },
  },

  indirect_case: ({ parent_case_name, functionData }) => {
    const template = functionData.parent_case_name
    if (parent_case_name){
    return template.replace('{parent_case_name}', parent_case_name)
    }
    return ""
  },

  share_percentage: ({ share, functionData }) => {
      const template = functionData.percentage
      if (share){
      return template.replace('{share}', share)
      }
      return ""
    },

  case_info: {
    description: ({ type, data, functionData }) => {
      const template = functionData[type]
      return template.replace(`{${type}}`, data)
    },
    birthdate: ({ day, month, year, functionData }) => {
      const template = functionData.birthdate
      return template.replace('{day}', day).replace('{month}', month).replace('{year}', year)
    },
    address: ({ address, post_code, post_place, functionData }) => {
      const template = functionData.full_address
      return template.replace('{address}', address).replace('{post_code}', post_code).replace('{post_place}', post_place)
    },
    name: ({ first_name, last_name, functionData }) => {
      const template = functionData.full_name
      return template.replace('{first_name}', first_name).replace('{last_name}', last_name)
    },
  },
  project_list_item: {
    users: ({ count, functionData }) => {
      const template = count == 1 ? functionData['user'] : functionData['users']
      return template.replace('{count}', count)
    },
    companies: ({ count, functionData }) => {
      const template = count == 1 ? functionData['company'] : functionData['companies']
      return template.replace('{count}', count)
    },
    conflicts: ({ count, functionData }) => {
      const template = count == 1 ? functionData['conflict'] : functionData['conflicts']
      return template.replace('{count}', count)
    },
  },

  conflict_list_user: {
    ownership: ({ stockCount, shareType, stakePercentage, company, functionData }) => {
      const template = functionData[shareType] || functionData.default
      return template.replace('{stockCount}', stockCount).replace('{stakePercentage}', stakePercentage).replace('{company}', company)
    },
    role: ({ title, company, functionData }) => {
      const template = functionData['description']
      return template.replace('{title}', title).replace('{company}', company)
    },
    relation_ownership: ({ stockCount, shareType, stakePercentage, company, functionData }) => {
      const template = functionData[shareType] || functionData.default
      return template.replace('{stockCount}', stockCount).replace('{stakePercentage}', stakePercentage).replace('{company}', company)
    },
    relation_role: ({ title, company, functionData }) => {
      const template = functionData['description']
      return template.replace('{title}', title).replace('{company}', company)
    },
    indirect_ownership: ({ company, functionData }) => {
      const template = functionData['description']
      return template.replace('{company}', company)
    },
    indirect_role: ({ company, functionData }) => {
      const template = functionData['description']
      return template.replace('{company}', company)
    },
    relation_indirect_ownership: ({ company, functionData }) => {
      const template = functionData['description']
      return template.replace('{company}', company)
    },
    relation_indirect_role: ({ company, functionData }) => {
      const template = functionData['description']
      return template.replace('{company}', company)
    },
  },

  conflict_list_company: {
    ownership: ({ stockCount, shareType, stakePercentage, name, functionData }) => {
      const template = functionData[shareType] || functionData.default
      return template.replace('{stockCount}', stockCount).replace('{stakePercentage}', stakePercentage).replace('{name}', name)
    },
    role: ({ title, name, functionData }) => {
      const template = functionData['description']
      return template.replace('{title}', title).replace('{name}', name)
    },
    relation_ownership: ({stockCount, shareType, stakePercentage, name, functionData }) => {
      const template = functionData[shareType] || functionData.default
      return template.replace('{stockCount}', stockCount).replace('{stakePercentage}', stakePercentage).replace('{name}', name)
    },
    relation_role: ({ title, name, functionData }) => {
      const template = functionData['description']
      return template.replace('{title}', title).replace('{name}', name)
    },
    indirect_ownership: ({ name, functionData }) => {
      const template = functionData['description']
      return template.replace('{name}', name)
    },
    indirect_role: ({ name, functionData }) => {
      const template = functionData['description']
      return template.replace('{name}', name)
    },
    relation_indirect_ownership: ({ name, functionData }) => {
      const template = functionData['description']
      return template.replace('{name}', name)
    },
    relation_indirect_role: ({ name, functionData }) => {
      const template = functionData['description']
      return template.replace('{name}', name)
    },
  },

  project_event: {
    event_title: ({ event_type, payload, functionData }) => {
      const template = functionData[event_type]
      if (event_type == 'update_project') {
        if (payload.newTitle) {
          return template['new_title']
        }
        if (payload.newDescription) {
          return template['new_description']
        }
        if (payload.newProjectStatus) {
          return template['new_project_status']
        }
        if (payload.newAssessmentStatus) { 
          return template['new_assessment_status']
        }
      }
      return template
    },
    event_description: ({ event_type, payload, functionData }) => {
      const template = functionData[event_type]
      switch (event_type) {
        case 'add_project_user':
        case 'remove_project_user':
          return template.replace('{name}', payload.userName)
        case 'add_project_company':
        case 'remove_project_company':
          return template.replace('{name}', payload.organizationName)
        case "create_project":
        case "remove_project":
          return template.replace('{name}', payload.title)
        case "update_project":
          if (payload.newTitle) {
            return template['new_text_field'].replace('{name}', payload.newTitle)
          }
          if (payload.newDescription) {
            return template['new_text_field'].replace('{name}', payload.newDescription)
          }
          if (payload.newProjectStatus) {
            return template['new_status'][payload.newProjectStatus]
          }
          if (payload.newAssessmentStatus) { 
            return template['new_status'][payload.newAssessmentStatus]
          }
        default:
          return ''
      }
    }
  },

  project_header: {
    status: ({ status, functionData }) => { 
      return functionData[status]
    }
  },

  notifications: {
    automatic_email_reminder: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) => {
        return currentUser?.id == userId
          ? functionData.selfReminderTemplate
          : functionData.otherReminderTemplate.replace('{userName}', userName);
      },
    },
    manual_email_reminder: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) => {
        return currentUser?.id == userId
          ? functionData.selfEmailTemplate
          : functionData.otherEmailTemplate.replace('{userName}', userName);
      },
    },
    automatic_email_invite: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) => {
        return currentUser?.id == userId
          ? functionData.selfInvitationTemplate
          : functionData.otherInvitationTemplate.replace('{userName}', userName);
      },
    },
    automatic_email_yearly_reminder: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) => {
        return currentUser?.id == userId
          ? functionData.selfReminderTemplate
          : functionData.otherReminderTemplate.replace('{userName}', userName);
      },
    },
    automatic_email_public_data_request: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) => {
        return currentUser?.id == userId
          ? functionData.selfConnectionTemplate
          : functionData.otherConnectionTemplate.replace('{userName}', userName);
      },
    },
    send_email_to_relation: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) => {
        return currentUser?.id == userId
          ? functionData.selfEmailTemplate
          : functionData.otherEmailTemplate.replace('{userName}', userName);
      },
    },
    case_created: {
      title: ({ payload: { caseId }, functionData }) => functionData.template.replace("{caseId}", caseId)
    },


    case_updated: {
      title: ({ payload: { caseId }, functionData }) => functionData.caseUpdatedTemplate.replace("{caseId}", caseId),
      message: ({ property, propertyOldValue, propertyNewValue, functionData, I18nFunction }) => {
        if (!property) {
          return functionData.defaultCaseUpdateMessage;
        }
        if (['manager_statement', 'board_statement'].includes(property)) {
          return functionData.casePropertyEditedTemplate.replace("{property}", I18nFunction({ id: `case_properties.${property}` }));
        }
        if (property === 'status') {
          return functionData.caseStatusChangedTemplate
            .replace("{property}", I18nFunction({ id: `case_properties.${property}` }))
            .replace("{propertyOldValue}", I18nFunction({ id: `case_statuses.${propertyOldValue}` }))
            .replace("{propertyNewValue}", I18nFunction({ id: `case_statuses.${propertyNewValue}` }));
        }
        return functionData.casePropertyUpdatedTemplate
          .replace("{property}", I18nFunction({ id: `case_properties.${property}` }))
          .replace("{propertyOldValue}", propertyOldValue)
          .replace("{propertyNewValue}", propertyNewValue);
      },
    },

    
    case_company_updated: {
      title: ({ payload: { caseId }, functionData }) => {
        return functionData.companyRelatedToCaseTemplate.replace("{caseId}", caseId);
      },
      message: ({ payload, HABREG_CLIENT_NAME, functionData }) => {
        if (payload.property === 'is_customer') {
          const template = functionData.isCustomerTemplate;
          return template
            .replace("{organizationName}", payload.organizationName)
            .replace("{status}", payload.property_new_value === true ? functionData.hasBecome : functionData.isNoLonger)
            .replace("{companyName}", payload.userCompanyName || HABREG_CLIENT_NAME);
        }
        
        if (payload.property === 'is_supplier') {
          const template = functionData.isSupplierTemplate;
          return template
            .replace("{organizationName}", payload.organizationName)
            .replace("{status}", payload.property_new_value === true ? functionData.hasBecome : functionData.isNoLonger)
            .replace("{companyName}", payload.userCompanyName || HABREG_CLIENT_NAME);
        }
        return '';
      },
    },


    case_data_mismatch: {
      title: ({ payload, functionData, I18nFunction }) => {
        const template = functionData.mismatchTemplate;
        return template.replace("{label}", I18nFunction({
          id: `connection_form.labels.${payload.property}`,
        }));
      },
    },
    case_commented: {
      title: ({ currentUser, payload, functionData }) => {
        const author = currentUser?.id == payload.byUserId ? functionData.byYou : functionData.bySomeone.replace("{byUserName}", payload.byUserName);
        return functionData.commentTemplate.replace("{author}", author).replace("{caseId}", payload.caseId);
      },
    },
    application_created: {
      title: ({ payload, functionData }) => {
        return functionData.applicationTemplate.replace("{applicationId}", payload.applicationId);
      },
    },

    
    application_updated: {
      title: ({ payload, functionData }) => {
        if (payload.applicationId) {
          return functionData.applicationWithIdTemplate.replace("{applicationId}", payload.applicationId);
        } else {
          return functionData.applicationWithoutIdTemplate;
        }
      },
      message: ({ property, propertyOldValue, propertyNewValue, functionData, I18nFunction }) => {
        if (!property) {
          return functionData.applicationUpdated;
        }
        if (property === 'status') {
          return functionData.statusChangedTemplate
            .replace("{property}", I18nFunction({ id: `application_properties.${property}` }))
            .replace("{propertyOldValue}", I18nFunction({ id: `application_statuses.${propertyOldValue}` }))
            .replace("{propertyNewValue}", I18nFunction({ id: `application_statuses.${propertyNewValue}` }));
        }
        return functionData.defaultChangeTemplate
          .replace("{property}", I18nFunction({ id: `application_properties.${property}` }))
          .replace("{propertyOldValue}", propertyOldValue)
          .replace("{propertyNewValue}", propertyNewValue);
      },
    },






    
    user_created: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) => {
        return currentUser?.id == userId
          ? functionData.welcomeMessage
          : functionData.userProfileCreated.replace("{userName}", userName);
      },
    },

    
    user_updated: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) => {
        return currentUser?.id == userId
          ? functionData.ownProfileUpdated
          : functionData.otherProfileUpdated.replace("{userName}", userName);
      },
    
      message: ({
        payload: { property, propertyOldValue, propertyNewValue },
        functionData, I18nFunction
      }) => {
        if (!property) {
          return functionData.defaultMessage;
        }
    
        if (property == 'position_of_influence') {
          return functionData.positionEntered.replace(
            "{propertyValue}",
            I18nFunction({ id: `user_properties.${property}` })
          );
        }
    
        return functionData.propertyUpdated
          .replace("{property}", I18nFunction({ id: `user_properties.${property}` }))
          .replace("{oldValue}", propertyOldValue)
          .replace("{newValue}", propertyNewValue);
      }
    },


    complete_onboarding: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionCompleteOnboarding}`,
    },
    confirm_form: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionConfirmForm}`,
    },
    set_birthdate_and_address: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionSetBirthdateAddress}`,
    },


    confirmed_birthdate_and_address: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionConfirmBirthdateAddress}`
    },
    set_employment_end_date: {
      title: ({ payload: { userName }, functionData }) =>
        `${functionData.actionSetEmploymentEndDate} ${userName}.`
    },
    set_address: {
      title: ({ payload: { userName }, functionData }) =>
        `${functionData.actionSetAddress} ${userName}.`
    },
    set_phone: {
      title: ({ payload: { userName }, functionData }) =>
        `${functionData.actionSetPhone} ${userName}.`
    },
    confirmed_no_user_ownerships: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionConfirmNoOwnerships}`
    },



    cancelled_confirmed_no_user_ownerships: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionCancelConfirmationOwnerships}`
    },
    confirmed_no_user_roles: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionConfirmNoRoles}`
    },
    cancelled_confirmed_no_user_roles: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionCancelConfirmationRoles}`
    },


    confirmed_no_user_other_connections: {
    title: ({ currentUser, payload: { userId, userName }, functionData }) =>
      `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionConfirmNoOtherConnections}`
    },
    cancelled_confirmed_no_user_other_connections: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionCancelConfirmationOtherConnections}`
    },
    confirmed_no_relations: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionConfirmNoRelations}`
    },



    cancelled_confirmed_no_relations: {
      title: ({ currentUser, payload: { userId, userName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionCancelConfirmationRelations}`
    },
    create_ownership: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionAddOwnership} ${organizationName}`
    },
    update_ownership: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionUpdateOwnership} ${organizationName}`
    },



    remove_ownership: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionRemoveOwnership} ${organizationName}`
    },
    create_ownership_application: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionAddOwnershipApp} ${organizationName}`
    },
    update_ownership_application: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionUpdateOwnershipApp} ${organizationName}`
    },



    remove_ownership_application: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionRemoveOwnershipApp} ${organizationName}`
    },
    create_role: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionAddRole} ${organizationName}`
    },
    update_role: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionUpdateRole} ${organizationName}`
    },



    remove_role: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionRemoveRole} ${organizationName}`
    },
    create_role_application: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionAddRoleApp} ${organizationName}`
    },
    update_role_application: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionUpdateRoleApp} ${organizationName}`
    },



    remove_role_application: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionRemoveRoleApp} ${organizationName}`
    },
    create_other_connection: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionAddConnection} ${organizationName}`
    },
    update_other_connection: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionUpdateConnection} ${organizationName}`
    },



    remove_other_connection: {
      title: ({ currentUser, payload: { userId, userName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionRemoveOtherConnection} ${organizationName}`
    },
    create_relation: {
      title: ({ currentUser, payload: { userId, userName, relationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionAddRelation} ${relationName}.`
    },
    update_relation: {
      title: ({ currentUser, payload: { userId, userName, relationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionUpdateRelation} ${relationName}.`
    },



    remove_relation: {
      title: ({ currentUser, payload: { userId, userName, relationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionRemoveRelation} ${relationName}.`
    },
    create_relation_ownership: {
      title: ({ currentUser, payload: { userId, userName, relationName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionAddOwnershipRelation} ${relationName} ${functionData.in} ${organizationName}`
    },
    update_relation_ownership: {
      title: ({ currentUser, payload: { userId, userName, relationName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionUpdateOwnershipRelation} ${relationName} ${functionData.in} ${organizationName}`
    },



    remove_relation_ownership: {
      title: ({ currentUser, payload: { userId, userName, relationName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionRemoveOwnershipRelation} ${relationName} ${functionData.in} ${organizationName}`
    },
    create_relation_role: {
      title: ({ currentUser, payload: { userId, userName, relationName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionAddRoleRelation} ${relationName} ${functionData.in} ${organizationName}`
    },
    update_relation_role: {
      title: ({ currentUser, payload: { userId, userName, relationName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionUpdateRoleRelation} ${relationName} ${functionData.in} ${organizationName}`
    },



    remove_relation_role: {
      title: ({ currentUser, payload: { userId, userName, relationName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionRemoveRoleRelation} ${relationName} ${functionData.in} ${organizationName}`
    },
    create_relation_other_connection: {
      title: ({ currentUser, payload: { userId, userName, relationName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionAddConnectionRelation} ${relationName} ${functionData.in} ${organizationName}`
    },
    update_relation_other_connection: {
      title: ({ currentUser, payload: { userId, userName, relationName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionUpdateConnectionRelation} ${relationName} ${functionData.in} ${organizationName}`
    },


    
    remove_relation_other_connection: {
      title: ({ currentUser, payload: { userId, userName, relationName, organizationName }, functionData }) =>
        `${currentUser?.id == userId ? functionData.selfReference : userName} ${functionData.actionRemoveConnectionRelation} ${relationName} ${functionData.in} ${organizationName}`
    },


  },
  events: {
    create_role: ({ payload: { title, organizationName }, functionData }) =>
      `${functionData.addRolePhrase} ${title} ${functionData.in} ${organizationName}`,
    update_role: ({ payload: { title, organizationName }, functionData }) =>
      `${functionData.changeRolePhrase} ${title} ${functionData.in} ${organizationName}`,
    remove_role: ({ payload: { title, organizationName }, functionData }) =>
      `${functionData.removeRolePhrase} ${title} ${functionData.in} ${organizationName}`,


      
    create_ownership_application: ({ payload: { organizationName }, functionData }) =>
      `${functionData.addOwnershipApplicationPhrase} ${organizationName}`,
  
    create_other_connection_application: ({ payload: { organizationName }, functionData }) =>
      `${functionData.addConnectionApplicationPhrase} ${organizationName}`,
    
    create_role_application: ({ payload: { title, organizationName }, functionData }) =>
      `${functionData.addRoleApplicationPhrase} ${title} ${functionData.in} ${organizationName}`,
    
    create_ownership: ({ payload: { organizationName }, functionData }) =>
      `${functionData.createOwnershipPhrase} ${organizationName}`,
    
    update_ownership: ({ payload: { organizationName }, functionData }) =>
      `${functionData.updateOwnershipPhrase} ${organizationName}`,
    
    remove_ownership: ({ payload: { organizationName }, functionData }) =>
      `${functionData.removeOwnershipPhrase} ${organizationName}`,

    create_other_connection: ({ payload: { organizationName }, functionData }) =>
      `${functionData.createOtherConnectionPhrase} ${organizationName}`,
  
    update_other_connection: ({ payload: { organizationName }, functionData }) =>
      `${functionData.updateOtherConnectionPhrase} ${organizationName}`,
  
    remove_other_connection: ({ payload: { organizationName }, functionData }) =>
      `${functionData.removeOtherConnectionPhrase} ${organizationName}`,
  
    create_relation: ({ payload: { firstName, lastName }, functionData }) =>
      `${functionData.createRelationPhrase} ${firstName} ${lastName}`,
  
    update_relation: ({ payload: { firstName, lastName }, functionData }) =>
      `${functionData.updateRelationPhrase} ${firstName} ${lastName}`,
  
    remove_relation: ({ payload: { firstName, lastName }, functionData }) =>
      `${functionData.removeRelationPhrase} ${firstName} ${lastName}`,


    create_relation_role: ({ 
      payload: { title, organizationName, firstName, lastName }, 
      functionData 
    }) =>
        `${functionData.createRelationRolePhrase} ${title} ${functionData.in} ${organizationName} ${functionData.forRelation} ${firstName} ${lastName}`,
  
    update_relation_role: ({ 
      payload: { title, organizationName, firstName, lastName }, 
      functionData 
    }) =>
        `${functionData.updateRelationRolePhrase} ${title} ${functionData.in} ${organizationName} ${functionData.forRelation} ${firstName} ${lastName}`,
  
    remove_relation_role: ({ 
      payload: { title, organizationName, firstName, lastName }, 
      functionData 
    }) =>
        `${functionData.removeRelationRolePhrase} ${title} ${functionData.in} ${organizationName} ${functionData.forRelation} ${firstName} ${lastName}`,
    
    create_relation_ownership: ({ 
      payload: { organizationName, firstName, lastName }, 
      functionData 
    }) =>
        `${functionData.createRelationOwnershipPhrase} ${organizationName} ${functionData.forRelation} ${firstName} ${lastName}`,
  
    update_relation_ownership: ({ 
      payload: { organizationName, firstName, lastName }, 
      functionData 
    }) =>
        `${functionData.updateRelationOwnershipPhrase} ${organizationName} ${functionData.forRelation} ${firstName} ${lastName}`,
  
    remove_relation_ownership: ({ 
      payload: { organizationName, firstName, lastName }, 
      functionData 
    }) =>
        `${functionData.removeRelationOwnershipPhrase} ${organizationName} ${functionData.forRelation} ${firstName} ${lastName}`,




    create_relation_other_connection: ({ 
      payload: { organizationName, firstName, lastName }, 
      functionData 
    }) =>
        `${functionData.createConnectionPhrase} ${organizationName} ${functionData.forRelation} ${firstName} ${lastName}`,
  
    update_relation_other_connection: ({ 
      payload: { organizationName, firstName, lastName }, 
      functionData 
    }) =>
        `${functionData.updateConnectionPhrase} ${organizationName} ${functionData.forRelation} ${firstName} ${lastName}`,
  
    remove_relation_other_connection: ({ 
      payload: { organizationName, firstName, lastName }, 
      functionData 
    }) =>
        `${functionData.removeConnectionPhrase} ${organizationName} ${functionData.forRelation} ${firstName} ${lastName}`,

  },


  validation: {
    max_char_text_2: ({ maxLength, functionData }) =>
      `${functionData.textPhrase} ${maxLength} ${functionData.charPhrase}`,

    year_range: ({ currentYear, functionData }) => {
      return functionData.year_range_warning.replace(
        '{currentYear}',
        currentYear,
      )
    },
  },
}
