import { combineReducers } from 'redux'
import { appContent } from './appContent'
import pendingContent from './pendingContent'
import language from './language'

export { default as sagas } from './sagas'

// Define action types
export const FETCH_APP_CONTENT = 'FETCH_APP_CONTENT'
export const FETCH_APP_CONTENT_SUCCEEDED = 'FETCH_APP_CONTENT_SUCCEEDED'
export const FETCH_APP_CONTENT_FAILED = 'FETCH_APP_CONTENT_FAILED'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_STATIC_APP_CONTENT = 'SET_STATIC_APP_CONTENT'


export const setAppContentToState = (appContent) => {
  const action = {
      type: FETCH_APP_CONTENT_SUCCEEDED,
      payload: appContent,
  };
  return action;
};


export const setStaticAppContent = (appContent) => {
  const action = {
      type: SET_STATIC_APP_CONTENT,
      payload: appContent,
  };
  return action;
};

export const fetchAppContent = () => {
  return {
    type: FETCH_APP_CONTENT,
  }
}

export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  payload: language,
})

// Combine reducers
export default combineReducers({
  appContent,
  pendingContent,
  language,
})

export const getContent = (state) => state.appContent

export const getOnboardingSlides = (state) => state.appContent.onboarding.slides.slideContent

export const isContentPending = (state) => state.pendingContent

export const getLanguage = (state) => state.language

