import { decamelize } from 'humps'
import { takeEvery, takeLeading } from 'redux-saga/effects'
import { get, post } from '../fetch'
import { fetchSaga, fetchSagaPagination, takeEveryWithCancel } from '../sagas'
import * as schema from '../schema'
import { RESET_PAGINATION } from '../users'
import {
  FETCH_USER,
  FETCH_USER_FAILED,
  FETCH_USER_NAMES,
  FETCH_USER_NAMES_FAILED,
  FETCH_USER_NAMES_SUCCEEDED,
  FETCH_USER_PROFILE_CASES,
  FETCH_USER_PROFILE_CASES_FAILED,
  FETCH_USER_PROFILE_CASES_SUCCEEDED,
  FETCH_USER_PROFILE_DATA,
  FETCH_USER_PROFILE_DATA_FAILED,
  FETCH_USER_PROFILE_DATA_SUCCEEDED,
  FETCH_USER_SUCCEEDED,
  FETCH_USERS,
  FETCH_USERS_FAILED,
  FETCH_USERS_SUCCEEDED,
  SET_ADDRESS,
  SET_ADDRESS_FAILED,
  SET_ADDRESS_SUCCEEDED,
  SET_BIRTHDATE,
  SET_BIRTHDATE_FAILED,
  SET_BIRTHDATE_SUCCEEDED,
  SET_EMPLOYMENT_END_DATE,
  SET_EMPLOYMENT_END_DATE_FAILED,
  SET_EMPLOYMENT_END_DATE_SUCCEEDED,
  SET_PHONE,
  SET_PHONE_FAILED,
  SET_PHONE_SUCCEEDED,
  SET_POSITION_OF_INFLUENCE,
  SET_POSITION_OF_INFLUENCE_DESCRIPTION,
  SET_POSITION_OF_INFLUENCE_DESCRIPTION_FAILED,
  SET_POSITION_OF_INFLUENCE_DESCRIPTION_SUCCEEDED,
  SET_POSITION_OF_INFLUENCE_FAILED,
  SET_POSITION_OF_INFLUENCE_SUCCEEDED,
} from './index'
import { ROUTER_LOCATION_CHANGE } from '../connectionsForm'

function* saga() {
  yield takeEvery(
    FETCH_USER_NAMES,
    fetchSagaPagination({
      SUCCEEDED: FETCH_USER_NAMES_SUCCEEDED,
      FAILED: FETCH_USER_NAMES_FAILED,
      schema: schema.arrayOfUsers,
      fetch: ({
        pagination: {
          query,
          filters,
          sortOption,
          pageIndex,
          perPage,
        } }) =>
        get(
          `/users/names?query=${query}&filters=${filters}&sort=${sortOption}&pageIndex=${pageIndex}&perPage=${perPage}`,
        ),
    }),
  )
  yield takeEvery(
    FETCH_USERS,
    fetchSagaPagination({
      SUCCEEDED: FETCH_USERS_SUCCEEDED,
      FAILED: FETCH_USERS_FAILED,
      schema: schema.arrayOfUsers,
      fetch: ({
        pagination: { query, filters, sortOption, pageIndex, perPage },
      }) =>
        get(
          `/users?query=${query}&filters=${filters}&sort=${sortOption}&pageIndex=${pageIndex}&perPage=${perPage}`,
        ),
    }),
  )
  yield takeLeading(
    FETCH_USER,
    fetchSaga(
    {
      SUCCEEDED: FETCH_USER_SUCCEEDED,
      FAILED: FETCH_USER_FAILED,
      schema: schema.user,
      fetch: ({ userId }) => get(`/users/${userId}`),
    }),
  )
  yield takeEveryWithCancel(
    FETCH_USER_PROFILE_DATA,
    [RESET_PAGINATION,ROUTER_LOCATION_CHANGE],
    fetchSaga,
    {
      SUCCEEDED: FETCH_USER_PROFILE_DATA_SUCCEEDED,
      FAILED: FETCH_USER_PROFILE_DATA_FAILED,
      schema: schema.userProfileDataResponse,
      fetch: ({ userId }) => get(`/users/${userId}/profile_data`),
    },
  )

  
  yield takeEveryWithCancel(FETCH_USER_PROFILE_CASES, [RESET_PAGINATION,ROUTER_LOCATION_CHANGE], fetchSagaPagination, {
    SUCCEEDED: FETCH_USER_PROFILE_CASES_SUCCEEDED,
    FAILED: FETCH_USER_PROFILE_CASES_FAILED,
    schema: schema.arrayOfCase,
    fetch: ({
      userId, caseType,
      pagination:{ pageIndex,perPage }
    }
    ) =>
      get(
        `/users/${userId}/profile_data/cases?pageIndex=${pageIndex}&perPage=${perPage}&caseType=${decamelize(
          caseType,
        )}`,
      ),
  })

  yield takeEvery(
    SET_BIRTHDATE,
    fetchSaga({
      SUCCEEDED: SET_BIRTHDATE_SUCCEEDED,
      FAILED: SET_BIRTHDATE_FAILED,
      schema: schema.user,
      fetch: ({ userId, birthdate }) =>
        post(`/users/${userId}/set_birthdate`, {
          birthdate,
        }),
    }),
  )
  yield takeEvery(
    SET_EMPLOYMENT_END_DATE,
    fetchSaga({
      SUCCEEDED: SET_EMPLOYMENT_END_DATE_SUCCEEDED,
      FAILED: SET_EMPLOYMENT_END_DATE_FAILED,
      schema: schema.user,
      fetch: ({ userId, employmentEndDate }) =>
        post(`/users/${userId}/set_employment_end_date`, {
          employmentEndDate,
        }),
    }),
  )
  yield takeEvery(
    SET_ADDRESS,
    fetchSaga({
      SUCCEEDED: SET_ADDRESS_SUCCEEDED,
      FAILED: SET_ADDRESS_FAILED,
      schema: schema.user,
      fetch: ({ userId, address, postCode, postPlace }) =>
        post(`/users/${userId}/set_address`, {
          address,
          postCode,
          postPlace,
        }),
    }),
  )
  yield takeEvery(
    SET_PHONE,
    fetchSaga({
      SUCCEEDED: SET_PHONE_SUCCEEDED,
      FAILED: SET_PHONE_FAILED,
      schema: schema.user,
      fetch: ({ userId, phone }) =>
        post(`/users/${userId}/set_phone`, {
          phone,
        }),
    }),
  )
  yield takeEvery(
    SET_POSITION_OF_INFLUENCE,
    fetchSaga({
      SUCCEEDED: SET_POSITION_OF_INFLUENCE_SUCCEEDED,
      FAILED: SET_POSITION_OF_INFLUENCE_FAILED,
      schema: schema.user,
      fetch: ({ userId, positionOfInfluence }) =>
        post(`/users/${userId}/set_position_of_influence`, {
          positionOfInfluence,
        }),
    }),
  )
  yield takeEvery(
    SET_POSITION_OF_INFLUENCE_DESCRIPTION,
    fetchSaga({
      SUCCEEDED: SET_POSITION_OF_INFLUENCE_DESCRIPTION_SUCCEEDED,
      FAILED: SET_POSITION_OF_INFLUENCE_DESCRIPTION_FAILED,
      schema: schema.user,
      fetch: ({ userId, positionOfInfluenceDescription }) =>
        post(`/users/${userId}/set_position_of_influence_description`, {
          positionOfInfluenceDescription,
        }),
    }),
  )
}

export default saga
