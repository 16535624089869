import { put, takeEvery } from 'redux-saga/effects'
import { get, post, postFile } from '../fetch'
import { fetchSaga, fetchSagaPagination, takeLatestWithCancel } from '../sagas'

import {
  COMMENT_ON_CASE,
  COMMENT_ON_CASE_FAILED,
  COMMENT_ON_CASE_SUCCEEDED,
  FETCH_ALL_CASES,
  FETCH_ALL_CASES_FAILED,
  FETCH_ALL_CASES_SUCCEEDED,
  FETCH_CASE,
  FETCH_CASE_FAILED,
  FETCH_CASE_SUCCEEDED,
  FETCH_INDIRECT_CASES,
  FETCH_INDIRECT_CASES_FAILED,
  FETCH_INDIRECT_CASES_SUCCEEDED,
  FETCH_USER_RELATION_CASES,
  FETCH_USER_RELATION_CASES_FAILED,
  FETCH_USER_RELATION_CASES_SUCCEEDED,
  MULTI_APPROVE_CASES,
  MULTI_APPROVE_CASES_FAILED,
  MULTI_APPROVE_CASES_SUCCEEDED,
  POST_APPLICATION_BOARD_STATEMENT,
  POST_APPLICATION_BOARD_STATEMENT_FAILED,
  POST_APPLICATION_BOARD_STATEMENT_SUCCEEDED,
  POST_BOARD_STATEMENT,
  POST_BOARD_STATEMENT_FAILED,
  POST_BOARD_STATEMENT_SUCCEEDED,
  UPDATE_APPLICATION_BOARD_STATEMENT,
  UPDATE_APPLICATION_BOARD_STATEMENT_FAILED,
  UPDATE_APPLICATION_BOARD_STATEMENT_SUCCEEDED,
  UPDATE_APPLICATION_MANAGER_STATEMENT,
  UPDATE_APPLICATION_MANAGER_STATEMENT_FAILED,
  UPDATE_APPLICATION_MANAGER_STATEMENT_SUCCEEDED,
  UPDATE_APPLICATION_STATUS,
  UPDATE_APPLICATION_STATUS_FAILED,
  UPDATE_APPLICATION_STATUS_SUCCEEDED,
  UPDATE_BOARD_STATEMENT,
  UPDATE_BOARD_STATEMENT_FAILED,
  UPDATE_BOARD_STATEMENT_SUCCEEDED,
  UPDATE_CASE_STATUS,
  UPDATE_CASE_STATUS_FAILED,
  UPDATE_CASE_STATUS_SUCCEEDED,
  UPDATE_MANAGER_STATEMENT,
  UPDATE_MANAGER_STATEMENT_FAILED,
  UPDATE_MANAGER_STATEMENT_SUCCEEDED,
} from './index'

import { ROUTER_LOCATION_CHANGE } from '../connectionsForm'
import { SET_RELATION_PROFF_PERSON_SUCCEEDED } from '../proffSelector'
import * as schema from '../schema'

export function* fetchIndirectCases({ caseId }) {
  yield put({
    type: FETCH_INDIRECT_CASES,
    caseId: caseId,
  })
}

export function* fetchDirectCase({ directConnectionCaseId }) {
  if (directConnectionCaseId) {
    yield put({
      type: FETCH_CASE,
      caseId: directConnectionCaseId,
    })
  }
}

function* saga() {
  yield takeEvery(
    FETCH_CASE,
    fetchSaga({
      SUCCEEDED: FETCH_CASE_SUCCEEDED,
      FAILED: FETCH_CASE_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({ caseId }) => get(`/cases/${caseId}`),
    }),
  )

  yield takeEvery(FETCH_CASE_SUCCEEDED, fetchIndirectCases)

  function* triggerFetchUserRelationCases({ userId, relationId }) {
    yield put({
      type: FETCH_USER_RELATION_CASES,
      userId: userId,
      relationId: relationId,
    })
  }
  yield takeEvery(
    SET_RELATION_PROFF_PERSON_SUCCEEDED,
    triggerFetchUserRelationCases,
  )
  yield takeEvery(
    FETCH_USER_RELATION_CASES,
    fetchSaga({
      SUCCEEDED: FETCH_USER_RELATION_CASES_SUCCEEDED,
      FAILED: FETCH_USER_RELATION_CASES_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({ userId, relationId }) =>
        get(`/users/${userId}/relation/${relationId}/cases`),
    }),
  )
  yield takeLatestWithCancel(
    FETCH_ALL_CASES,
    ROUTER_LOCATION_CHANGE,
    fetchSagaPagination,
    {
      SUCCEEDED: FETCH_ALL_CASES_SUCCEEDED,
      FAILED: FETCH_ALL_CASES_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({
        pagination: {
          query,
          filters,
          sortOption,
          dateRangeOption,
          dateRangeFrom,
          dateRangeTo,
          pageIndex,
          perPage,
        },
      }) =>
        get(
          `/cases?query=${query}&filters=${filters}&sort=${sortOption}&dateRange=${dateRangeOption}&from=${dateRangeFrom}&to=${dateRangeTo}&pageIndex=${pageIndex}&perPage=${perPage}`,
        ),
    },
  )
  yield takeEvery(
    FETCH_INDIRECT_CASES,
    fetchSaga({
      SUCCEEDED: FETCH_INDIRECT_CASES_SUCCEEDED,
      FAILED: FETCH_INDIRECT_CASES_FAILED,
      schema: schema.indirectCasesResponse,
      fetch: ({ caseId }) => get(`/cases/${caseId}/indirect`),
    }),
  )
  yield takeEvery(
    COMMENT_ON_CASE,
    fetchSaga({
      SUCCEEDED: COMMENT_ON_CASE_SUCCEEDED,
      FAILED: COMMENT_ON_CASE_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({ caseId, comment, commentType }) =>
        post(`/cases/${caseId}/comments`, { comment, commentType }),
    }),
  )
  yield takeEvery(
    UPDATE_CASE_STATUS,
    fetchSaga({
      SUCCEEDED: UPDATE_CASE_STATUS_SUCCEEDED,
      FAILED: UPDATE_CASE_STATUS_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({ caseId, newStatus, comment }) =>
        post(`/cases/${caseId}/status`, {
          status: newStatus,
          comment: comment,
        }),
    }),
  )
  yield takeEvery(
    MULTI_APPROVE_CASES,
    fetchSaga({
      SUCCEEDED: MULTI_APPROVE_CASES_SUCCEEDED,
      FAILED: MULTI_APPROVE_CASES_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({ cases, newStatus, comment }) =>
        post(`/cases/multi_approve`, {
          cases: cases,
          status: newStatus,
          comment: comment,
        }),
    }),
  )
  yield takeEvery(
    UPDATE_MANAGER_STATEMENT,
    fetchSaga({
      SUCCEEDED: UPDATE_MANAGER_STATEMENT_SUCCEEDED,
      FAILED: UPDATE_MANAGER_STATEMENT_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({ caseId, managerStatement }) =>
        post(`/cases/${caseId}/manager_statement`, {
          managerStatement: managerStatement,
        }),
    }),
  )
  yield takeEvery(
    UPDATE_BOARD_STATEMENT,
    fetchSaga({
      SUCCEEDED: UPDATE_BOARD_STATEMENT_SUCCEEDED,
      FAILED: UPDATE_BOARD_STATEMENT_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({ caseId, boardStatement }) =>
        post(`/cases/${caseId}/board_statement`, {
          boardStatement: boardStatement,
        }),
    }),
  )
  yield takeEvery(
    POST_BOARD_STATEMENT,
    fetchSaga({
      SUCCEEDED: POST_BOARD_STATEMENT_SUCCEEDED,
      FAILED: POST_BOARD_STATEMENT_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({ caseId, file }) =>
        postFile(`/cases/${caseId}/upload_board_statement`, file),
    }),
  )
  yield takeEvery(
    UPDATE_APPLICATION_STATUS,
    fetchSaga({
      SUCCEEDED: UPDATE_APPLICATION_STATUS_SUCCEEDED,
      FAILED: UPDATE_APPLICATION_STATUS_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({ caseId, applicationId, newStatus, comment }) =>
        post(`/cases/${caseId}/applications/${applicationId}/status`, {
          status: newStatus,
          comment: comment,
        }),
    }),
  )
  yield takeEvery(
    UPDATE_APPLICATION_MANAGER_STATEMENT,
    fetchSaga({
      SUCCEEDED: UPDATE_APPLICATION_MANAGER_STATEMENT_SUCCEEDED,
      FAILED: UPDATE_APPLICATION_MANAGER_STATEMENT_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({ caseId, applicationId, managerStatement }) =>
        post(
          `/cases/${caseId}/applications/${applicationId}/manager_statement`,
          {
            managerStatement: managerStatement,
          },
        ),
    }),
  )
  yield takeEvery(
    POST_APPLICATION_BOARD_STATEMENT,
    fetchSaga({
      SUCCEEDED: POST_APPLICATION_BOARD_STATEMENT_SUCCEEDED,
      FAILED: POST_APPLICATION_BOARD_STATEMENT_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({ caseId, applicationId, file }) =>
        postFile(
          `/cases/${caseId}/applications/${applicationId}/upload_board_statement`,
          file,
        ),
    }),
  )

  yield takeEvery(
    UPDATE_APPLICATION_BOARD_STATEMENT,
    fetchSaga({
      SUCCEEDED: UPDATE_APPLICATION_BOARD_STATEMENT_SUCCEEDED,
      FAILED: UPDATE_APPLICATION_BOARD_STATEMENT_FAILED,
      schema: schema.arrayOfCase,
      fetch: ({ caseId, applicationId, boardStatement }) =>
        post(`/cases/${caseId}/applications/${applicationId}/board_statement`, {
          boardStatement: boardStatement,
        }),
    }),
  )
}

export default saga
