import React from 'react'
import styled from 'styled-components'
import ProfileCaseListItem from '../ProfileCaseListItem'
import ListItem from '../ProfileCaseListItem/ListItem'
import { isValidUUID } from '../../utils'
import { RELATION } from '../../connectionTypes'

const ProfileCaseListDefault = (props) => {
  const displayDraft = () => {
    const isTransientConnection = isValidUUID(props.activeConnection?.id)
    const isConfirmingPublicData = props.activeConnection?.connectionType
      ?.toLowerCase()
      .includes('public')
    const belongsToRelationCaseList =
      props.relationId === props.activeConnection?.relationId
    return (
      isTransientConnection &&
      !isConfirmingPublicData &&
      belongsToRelationCaseList
    )
  }

  return (
    <>
      <List>
        {displayDraft() && (
          <li>
            <ListItem draft={true} />
          </li>
        )}

        {props.items
          ?.filter((r) => !isValidUUID(r.id))
          .map((item, index) => (
            <ProfileCaseListItem
              key={props.caseType + item.id}
              {...props}
              {...item}
              item={item}
              active={
                (props.caseType !== RELATION &&
                  props.activeConnection?.caseId === item.id) ||
                (props.caseType === RELATION &&
                  props.activeConnection?.id === item.id)
              }
            />
          ))}
      </List>
    </>
  )
}

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  padding-right: 1em;
  & > :not(:last-child) {
    margin-bottom: 1em;
  }
`

export default ProfileCaseListDefault