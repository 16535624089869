import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  getCompanies,
  shouldFetchCompanies,
  getCompanyPagination,
  isCompaniesPending,
  isAdmin,
} from '../redux/selectors'
import { fetchCompanies, fetchLatestSupplierUpdateLog } from '../redux/actions'
import Page from '../components/Page'
import CompanyList from '../components/CompanyList'
import usePagination from '../hooks/usePagination'
import I18n, { useI18nFunction } from '../components/I18n'
import Button from '../components/Button'
import UploadFileModal from '../components/UploadFileModal'
import DataListHeader from '../components/DataList/DataListHeader'
import DataListColumns from '../components/DataList/DataListColumns'
import useSort from '../hooks/useSort'

const Companies = ({
  companies,
  fetchCompanies,
  isCompaniesPending,
  isAdmin,
  fetchLatestSupplierUpdateLog,
  companyPagination,
}) => {
  const I18nFunction = useI18nFunction();

  const companyPageProps = {
    paginationProps: {
      perPageOptions: [10, 15, 25, 50, 100, I18nFunction( { id: 'pagination.fetch_all' } )],
    },
    searchInputProps: {
      placeholder: I18nFunction({ id: 'Search_for_companies' }),
    },
    sortProps: {
      placeholder: I18nFunction({ id: 'Sort_by' }),
      options: [
        {
          label: <I18n id="Name" />,
          key: 'name',
          value: {
            iteratee: ({ organizationName }) => organizationName?.toLowerCase() ?? "",
            order: 'asc',
          },
        },
        {
          label: <I18n id="Connection_count" />,
          key: 'connection_count',
          value: {
            iteratee: ({ connectionCount }) => connectionCount || 0,
            order: 'desc',
          },
        },
      ],
    },
    multiSelectProps: {
      filters: [
        {
          key: 'caseRiskFilter',
          placeholder: I18nFunction( { id: 'filterType',  payload: 'toggle' } ), 
          options: [
            {
              label: I18nFunction({ id: 'case_risk_categories.organization_is_supplier' }),
              value: 'is_supplier',
              key: 1,
            },
            {
              label: I18nFunction({ id: 'case_risk_categories.organization_is_client' }),
              value: 'is_customer',
              key: 2,
            },
          ],
        },
      ],
    },
    listColumnProps: {
      columns: [
        {
          label: I18nFunction({ id: 'Company' }),
          key: 'name',
          width: '22%',
        },
        {
          label: I18nFunction({ id: 'Connection_count' }),
          key: 'connectionCount',
          width: '22%',
        },
        {
          label: I18nFunction({ id: 'Supplier_status' }),
          key: 'supplierStatus',
          width: '23%',
        },
        {
          label: I18nFunction({ id: 'Client_status' }),
          key: 'clientStatus',
          width: '15%',
        },
      ],
    },
  }

  const {
    query,
    pageIndex,
    perPage,
    paginationResult,
    filters,
    sortOption,
    dateRangeOption,
    dateRangeFrom,
    dateRangeTo,
    setQuery,
    setPageIndex,
    setPerPage,
    setQueryHandler,
    setFiltersHandler,
    setSortOptionHandler,
    setDateRangeOptionHandler,
    setDateRangeFromHandler,
    setDateRangeToHandler,
    setPerPageHandler,
  } = usePagination(fetchCompanies, getCompanyPagination, companyPagination)

  const { sort, setSort, sortFunction } = useSort(
    companyPageProps?.sortProps?.options[0],
  )

  const sortCompanies = () => {
    if (
      companyPagination?.sortOption &&
      companyPagination?.sortOption != sort?.key
    ) {
      let idx = companyPageProps?.sortProps?.options.findIndex(
        (o) => o.key === companyPagination?.sortOption,
      )
      setSort(companyPageProps?.sortProps?.options[idx])
    }
    return sortFunction(companies)
  }

  const [showSupplierUploadModal, setShowSupplierUploadModal] = useState(false)
  const onUploadSupplierButtonClicked = () => {
    setShowSupplierUploadModal(true)
    fetchLatestSupplierUpdateLog()
  }
  const showSupplierUploadAction = () => {
    if (
      process.env.REACT_APP_SUPPLIER_UPLOAD_ENABLED?.toLowerCase() === 'true' &&
      isAdmin
    ) {
      return (
        <ActionButton
          small
          type="submit"
          onClick={() => onUploadSupplierButtonClicked()}
        >
          <I18n id="Upload_third_party_list" />{' '}
        </ActionButton>
      )
    }
  }

  return (
    <Page>
      {showSupplierUploadModal && (
        <UploadFileModal onClose={() => setShowSupplierUploadModal(false)} />
      )}
      <Content>
        <h1>
          <I18n id="Companies" />
        </h1>
        <Options>{showSupplierUploadAction()}</Options>
        <DataListHeader
          pageProps={companyPageProps}
          query={query}
          pageIndex={pageIndex}
          perPage={perPage}
          paginationResult={paginationResult}
          filters={filters}
          sortOption={sortOption}
          dateRangeOption={dateRangeOption}
          dateRangeFrom={dateRangeFrom}
          dateRangeTo={dateRangeTo}
          setQuery={setQuery}
          setPageIndex={setPageIndex}
          setPerPage={setPerPage}
          setQueryHandler={setQueryHandler}
          setFiltersHandler={setFiltersHandler}
          setSortOptionHandler={setSortOptionHandler}
          setDateRangeOptionHandler={setDateRangeOptionHandler}
          setDateRangeFromHandler={setDateRangeFromHandler}
          setDateRangeToHandler={setDateRangeToHandler}
          setPerPageHandler={setPerPageHandler}
        />
        <DataListColumns props={companyPageProps?.listColumnProps} />
        <CompanyList companies={sortCompanies()} pending={isCompaniesPending} />
      </Content>
    </Page>
  )
}

const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em 0.5em 0.5em 0.5em;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
`

const ActionButton = styled(Button)`
  background: #0c3689;
  border: 2px solid #0c3689;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 13em;
`

const Content = styled.div`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
  padding: 0 6em;
`

const mapStateToProps = (state) => ({
  companies: getCompanies(state),
  shouldFetchCompanies: shouldFetchCompanies(state),
  companyPagination: getCompanyPagination(state),
  isCompaniesPending: isCompaniesPending(state),
  isAdmin: isAdmin(state),
})

const mapDispatchToProps = { fetchCompanies, fetchLatestSupplierUpdateLog }

export default connect(mapStateToProps, mapDispatchToProps)(Companies)
