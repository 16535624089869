import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import useDebounce from './useDebouce'

const usePagination = (fetchAction, paginationSelector, options = {}) => {
  // console.log('query', options?.query)
  // console.log('filters', options?.filters)
  // console.log('sort', options?.sortOption)
  // console.log('daterange', options?.dateRangeOption)
  // console.log('from', options?.dateRangeFrom)
  // console.log('to', options?.dateRangeTo)
  // console.log('idx', options?.pageIndex)
  // console.log('perPage', options?.perPage)
  const [query, setQuery] = useState(options?.query ? options.query : '')
  const [filters, setFilters] = useState(
    options?.filters ? options.filters : '',
  )
  const [sortOption, setSortOption] = useState(
    options?.sortOption ? options.sortOption : '',
  )
  const [dateRangeOption, setDateRangeOption] = useState(
    options?.dateRangeOption ? options.dateRangeOption : '',
  )
  const [dateRangeFrom, setDateRangeFrom] = useState(
    options?.dateRangeFrom ? options.dateRangeFrom : '',
  )
  const [dateRangeTo, setDateRangeTo] = useState(
    options?.dateRangeTo ? options.dateRangeTo : '',
  )
  const [pageIndex, setPageIndex] = useState(
    options?.pageIndex ? options.pageIndex : 1,
  )
  const [perPage, setPerPage] = useState(
    options?.perPage ? options.perPage : 10,
  ) // set from options?

  const debouncedQuery = useDebounce(query, 500)
  const debouncedFilters = useDebounce(filters, 500)
  const debouncedSortOption = useDebounce(sortOption, 500)
  const debouncedDateRangeOption = useDebounce(dateRangeOption, 500)
  const debouncedDateRangeFrom = useDebounce(dateRangeFrom, 500)
  const debouncedDateRangeTo = useDebounce(dateRangeTo, 500)

  const paginationResult = useSelector(paginationSelector)

  const setQueryHandler = (query) => {
    setPageIndex(1)
    setQuery(query)
  }

  const setFiltersHandler = (filters) => {
    setPageIndex(1)
    setFilters(filters)
  }

  const setSortOptionHandler = (sortOption) => {
    setPageIndex(1)
    setSortOption(sortOption)
  }

  const setDateRangeOptionHandler = (dateRangeOption) => {
    setPageIndex(1)
    setDateRangeOption(dateRangeOption)
  }

  const setDateRangeFromHandler = (dateRangeFrom) => {
    setPageIndex(1)
    setDateRangeFrom(dateRangeFrom)
  }

  const setDateRangeToHandler = (dateRangeTo) => {
    setPageIndex(1)
    setDateRangeTo(dateRangeTo)
  }

  const setPerPageHandler = (perPage) => {
    setPageIndex(1)
    setPerPage(perPage)
  }

  useEffect(() => {
      fetchAction({
        query: query,
        filters: filters,
        sortOption: sortOption,
        dateRangeOption: dateRangeOption,
        dateRangeFrom: dateRangeFrom,
        dateRangeTo: dateRangeTo,
        pageIndex: pageIndex,
        perPage: perPage,
      })
  }, [
    debouncedQuery,
    debouncedFilters,
    debouncedSortOption,
    debouncedDateRangeOption,
    debouncedDateRangeFrom,
    debouncedDateRangeTo,
    pageIndex,
    perPage,
    fetchAction,
  ])

  return {
    query,
    pageIndex,
    perPage,
    paginationResult,
    filters,
    sortOption,
    dateRangeOption,
    dateRangeFrom,
    dateRangeTo,
    setQuery,
    setPageIndex,
    setPerPage,
    setQueryHandler,
    setFiltersHandler,
    setSortOptionHandler,
    setDateRangeOptionHandler,
    setDateRangeFromHandler,
    setDateRangeToHandler,
    setPerPageHandler,
  }
}

export default usePagination
