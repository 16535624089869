import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { setActiveConnection, unsetActiveConnection, fetchIndirectCases, selectCase } from '../../redux/actions'
import { getCurrentUser } from '../../redux/selectors'
import { getActiveConnectionDataFromClickedListItem } from '../ConnectionForm/utils'
import checkmarkIcon from '../../icons/checkmark_circled_filled.svg'
import attentionIcon from '../../icons/circled_alert_icon.svg'
import { ReactComponent as PublicInfoIcon } from '../../icons/building-columns-solid.svg'
import I18n from '../I18n'
import { StatusBadge } from '../../CaseStatus'
import { Link } from 'react-router-dom'
import { ReactComponent as DraftIcon } from '../../icons/file-pen-solid.svg'
import { RELATION, USER_OTHER_CONNECTION, RELATION_OTHER_CONNECTION } from '../../connectionTypes'

const ListItem = ({
  itemIsApplication,
  draft,
  Case,
  checked,
  image,
  title,
  description,
  small,
  connectionType,
  active,
  setActiveConnection,
  unsetActiveConnection,
  from,
  isCurrentUsersProfile,
  relation,
  fetchIndirectCases,
  userId,
  selectCase
}) => {
  let disabledInteraction = false
  if (!isCurrentUsersProfile || itemIsApplication) disabledInteraction = true

  const isRelationOrOther = [RELATION, USER_OTHER_CONNECTION, RELATION_OTHER_CONNECTION].includes(connectionType)

  const onClick = (e) => {
    if (!disabledInteraction){
      relation ? selectCase(relation) : selectCase(Case)
      if (!isRelationOrOther){
        fetchIndirectCases(Case?.id)
      }
      if (!checked){
        autoSetActiveConnection()
      }
    }
  }

  const autoSetActiveConnection = (e) => {
    const activeConnectionData = getActiveConnectionDataFromClickedListItem(
      Case,
      relation,
    )
    unsetActiveConnection()
    active
      ? unsetActiveConnection()
      : setActiveConnection({
          id: activeConnectionData.id,
          caseId: relation ? undefined : Case?.id,
          relationId: Case?.relationId,
          connectionType: connectionType,
          connection: activeConnectionData,
        })
  }

  const noPropagation = (e) => e.stopPropagation()
  if (draft) {
    return <Draft onClick={onClick} />
  }
  return (
    <Wrapper
      className={[
        active && 'active',
        !checked && 'attention',
        disabledInteraction && 'disabledInteraction',
      ]}
    >
      {(Case?.userPublicDataRole || Case?.userPublicDataOwnership) && (
        <PublicInfoIcon
          style={{
            position: 'absolute',
            stroke: '#ccc',
            fill: '#ccc',
            width: '1em',
            height: '1em',
            top: '0.1em',
            left: '1.5em',
          }}
          title={<I18n id='case_details.public' /> }
        />
      )}
      {image && <Icon src={image} alt="" />}
      <Content>
        <Title small={small} title={title}>
          {title}
        </Title>
        <Subtitle>
          <Description small={small} description={description}>
            {description}
          </Description>
        </Subtitle>
      </Content>
      <FromWrapper>
        {from === undefined ? (
          ''
        ) : (
          <From>
            <I18n id="Updated" />
            <div>{from}</div>
          </From>
        )}
      </FromWrapper>
      {Case && (
      <RightFlexBox>
          <StatusWrapper>
            <StatusBadge status={Case?.status} />
          </StatusWrapper>
          <Buttons>
            {(checked || !isCurrentUsersProfile) ?
              <CaseLinkWrapper button Case>
                <CaseLink to={`/cases/${Case.id}`} onClick={noPropagation}>
                  <LinkText>
                    <I18n id="Go_to_case" />
                  </LinkText>
                </CaseLink>
              </CaseLinkWrapper>
              :
              <CaseLinkWrapper Case/>
            }
             {!disabledInteraction&&
            <CaseLinkWrapper button onClick={onClick}>
              <CaseLink to={''}>
                <LinkText>
                  {checked ?  <I18n id="Edit" /> : <I18n id="Confirm"/>}
                </LinkText>
              </CaseLink>
            </CaseLinkWrapper>
          }
          </Buttons>
      </RightFlexBox>
      )}
      {relation && (
      <RightFlexBox>
          <StatusWrapper/>
          <Buttons>
            <CaseLinkWrapper Case/>
            <CaseLinkWrapper button onClick={onClick}>
              <CaseLink to={''}>
                <LinkText>
                  {checked ?  <I18n id="Edit" /> : <I18n id="Confirm"/>}
                </LinkText>
              </CaseLink>
            </CaseLinkWrapper>
          </Buttons>
      </RightFlexBox>
      )}
      <MarkerWrapper>
        <img
          src={checked ? checkmarkIcon : attentionIcon}
          title={
            checked ? (
              <I18n id="Confirmed" />
            ) : (
              <I18n id="Waiting_for_confirmation" />
            )
          }
        />
      </MarkerWrapper>
    </Wrapper>
  )
}

const MarkerWrapper = styled.div`
  width: 6%
`

const Draft = ({ onClick }) => {
  const draftIconStyle = {
    display: 'block',
    marginRight: '1.5em',
    marginLeft: '0.5em',
    fill: '#3C91D4',
    width: '2em',
    height: '2em',
    flexShrink: 0,
    alignmentBaseline: 'central',
  }

  return (
    <React.Fragment>
      <Wrapper className={['active', 'attention']} onClick={onClick}>
        <DraftIcon style={draftIconStyle} />
        <Content>
          <Title>
            <I18n id="connection_list_item.new_relationship" />
          </Title>
          <Subtitle>
            <Description>
              <I18n id="connection_list_item.description" />
            </Description>
          </Subtitle>
        </Content>
      </Wrapper>
    </React.Fragment>
  )
}

const RightFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 49%;
`

const StatusWrapper = styled.div`
  width: 43%;
  display: flex;
  align-items: center;
`

const Buttons = styled.div`
  display: flex;
  width: 57%;
  gap: 0.2em;
  justify-content: right;
  padding: 0em 0.5em;
`

const CaseLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-width: ${({ Case }) => Case ? '7em' : '6em'};
  max-width: ${({ Case }) => Case ? '7em' : '6em'};
  cursor: ${({ button }) => button ? 'pointer' : 'inherit'};

  &:hover {
    background-color: ${({ theme, button }) => button ? theme.listItemHover : 'inherit'};
  }
`

const CaseLink = styled(Link)`
  padding: 0.7em;
  text-decoration: none;
`

const LinkText = styled.div`
  white-space: nowrap;
  color: ${({ theme: { secondary } }) => secondary};
  font-size: ${({ theme: { largeText } }) => largeText};
`

const Wrapper = styled.div`
  box-shadow: rgb(219 219 219) 0px 2px 8px;
  border-radius: 5px;
  position: relative;
  background-color: ${({ theme }) => theme.primaryBackground};
  display: flex;
  align-items: center;
  padding: 0.8em 1em;
  margin: 0.3em;
  border: solid 2px ${({ theme: { primaryBackground } }) => primaryBackground};

  &.active {
    border-color: ${({ theme: { primary } }) => primary};
  }
  &.attention {
    box-shadow: rgb(180 180 200) 0px 2px 8px;
  }
  &.disabledInteraction {
    cursor: default;
  }
`

const Content = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  width: 33%;
  padding-right: 0em;
`

const Title = styled.div`
  font-size: ${({ small, theme: { normalText, largeText } }) =>
    small ? normalText : largeText};
  ${({ title, theme: { secondary } }) => !title && 'color: ' + secondary}
  margin-bottom: 0.2em;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Subtitle = styled.div`
  display: flex;
  flex-direction: row;

`

const Description = styled.span`
  ${({ description, small, theme: { secondary, subtleText } }) =>
    !description ? 'color: ' + secondary : small && 'color: ' + subtleText}
  overflow: hidden;
  text-overflow: ellipsis;
`

const From = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.825em;
  align-items: center;
  justify-content: center;
`

const FromWrapper = styled.div`
  width: 12%;
`

const Icon = styled.img`
  margin-right: 1.5em;
  height: 2em;
  width: 2em;
  flex-shrink: 0;
`

const mapStateToProps = (state, props) => {
  const userId = getCurrentUser(state).id
  return {
    userId: userId
  }
}

const mapDispatchToProps = { setActiveConnection, unsetActiveConnection, fetchIndirectCases, selectCase }

export default connect(mapStateToProps, mapDispatchToProps)(ListItem)
