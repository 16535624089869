import { combineReducers } from 'redux'
export { default as sagas } from './sagas'

export const FETCH_PROJECT = 'FETCH_PROJECT'
export const FETCH_PROJECT_SUCCEEDED = 'FETCH_PROJECT_SUCCEEDED'
export const FETCH_PROJECT_FAILED = 'FETCH_PROJECT_FAILED'

export const FETCH_PROJECTS = 'FETCH_PROJECTS'
export const FETCH_PROJECTS_SUCCEEDED = 'FETCH_PROJECTS_SUCCEEDED'
export const FETCH_PROJECTS_FAILED = 'FETCH_PROJECTS_FAILED'

export const CREATE_PROJECT = 'CREATE_PROJECT'
export const CREATE_PROJECT_SUCCEEDED = 'CREATE_PROJECT_SUCCEEDED'
export const CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED'

export const UPDATE_PROJECT_INFO = 'UPDATE_PROJECT_INFO'
export const UPDATE_PROJECT_INFO_SUCCEEDED = 'UPDATE_PROJECT_INFO_SUCCEEDED'
export const UPDATE_PROJECT_INFO_FAILED = 'UPDATE_PROJECT_INFO_FAILED'

export const ADD_PROJECT_USER = 'ADD_PROJECT_USER'
export const ADD_PROJECT_USER_SUCCEEDED = 'ADD_PROJECT_USER_SUCCEEDED'
export const ADD_PROJECT_USER_FAILED = 'ADD_PROJECT_USER_FAILED'

export const ADD_PROJECT_COMPANY = 'ADD_PROJECT_COMPANY'
export const ADD_PROJECT_COMPANY_SUCCEEDED = 'ADD_PROJECT_COMPANY_SUCCEEDED'
export const ADD_PROJECT_COMPANY_FAILED = 'ADD_PROJECT_COMPANY_FAILED'

export const UPDATE_PROJECT_COMPANY_FORM = 'UPDATE_PROJECT_COMPANY_FORM'
export const CLEAR_PROJECT_COMPANY_FORM = 'CLEAR_PROJECT_COMPANY_FORM'
export const SELECT_PROJECT_COMPANY = 'SELECT_PROJECT_COMPANY'
export const DESELECT_PROJECT_COMPANY = 'DESELECT_PROJECT_COMPANY'
export const CLEAR_SELECTED_PROJECT_COMPANIES =
  'CLEAR_SELECTED_PROJECT_COMPANIES'

export const UPDATE_PROJECT_USER_FORM = 'UPDATE_PROJECT_USER_FORM'
export const CLEAR_PROJECT_USER_FORM = 'CLEAR_PROJECT_USER_FORM'
export const SELECT_PROJECT_USER = 'SELECT_PROJECT_USER'
export const DESELECT_PROJECT_USER = 'DESELECT_PROJECT_USER'
export const CLEAR_SELECTED_PROJECT_USERS = 'CLEAR_SELECTED_PROJECT_USERS'

export const REMOVE_PROJECT_USER = 'REMOVE_PROJECT_USER'
export const REMOVE_PROJECT_USER_SUCCEEDED = 'REMOVE_PROJECT_USER_SUCCEEDED'
export const REMOVE_PROJECT_USER_FAILED = 'REMOVE_PROJECT_USER_FAILED'

export const REMOVE_PROJECT_COMPANY = 'REMOVE_PROJECT_COMPANY'
export const REMOVE_PROJECT_COMPANY_SUCCEEDED =
  'REMOVE_PROJECT_COMPANY_SUCCEEDED'
export const REMOVE_PROJECT_COMPANY_FAILED = 'REMOVE_PROJECT_COMPANY_FAILED'

export const COMMENT_ON_PROJECT = 'COMMENT_ON_PROJECT'
export const COMMENT_ON_PROJECT_SUCCEEDED = 'COMMENT_ON_PROJECT_SUCCEEDED'
export const COMMENT_ON_PROJECT_FAILED = 'COMMENT_ON_PROJECT_FAILED'

export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_PROJECT_SUCCEEDED = 'DELETE_PROJECT_SUCCEEDED'
export const DELETE_PROJECT_FAILED = 'DELETE_PROJECT_FAILED'

export const fetchProject = (projectId) => ({
  type: FETCH_PROJECT,
  projectId,
})

export const fetchProjects = (
  pagination = { query: '', pageIndex: 1, perPage: 20 },
) => ({
  type: FETCH_PROJECTS,
  pagination,
})

export const createProject = (title, description, project_status, project_assessment_status, custom_data) => ({
  type: CREATE_PROJECT,
  title,
  description,
  project_status,
  project_assessment_status,
  custom_data,
})

export const updateProjectInfo = (projectId, title, description, project_status, project_assessment_status) => ({
  type: UPDATE_PROJECT_INFO,
  projectId,
  title,
  description,
  project_status,
  project_assessment_status,
})

export const updateProjectUserForm = (props) => ({
  type: UPDATE_PROJECT_USER_FORM,
  props,
})

export const clearProjectUserForm = () => ({
  type: CLEAR_PROJECT_USER_FORM,
})

export const selectProjectUser = (projectId, props) => ({
  type: SELECT_PROJECT_USER,
  projectId,
  props,
})

export const deselectProjectUser = (projectId, userId) => ({
  type: DESELECT_PROJECT_USER,
  projectId,
  userId,
})

export const clearSelectedProjectUsers = (projectId) => ({
  type: CLEAR_SELECTED_PROJECT_USERS,
  projectId,
})

export const addProjectUser = (projectId, selectedProjectUsers) => ({
  type: ADD_PROJECT_USER,
  projectId,
  selectedProjectUsers,
})

export const removeProjectUser = (projectId, userId) => ({
  type: REMOVE_PROJECT_USER,
  projectId,
  userId,
})

export const commentOnProject = (projectId, comment) => ({
  type: COMMENT_ON_PROJECT,
  projectId,
  comment,
})

export const updateProjectCompanyForm = (props) => ({
  type: UPDATE_PROJECT_COMPANY_FORM,
  props,
})

export const clearProjectCompanyForm = () => ({
  type: CLEAR_PROJECT_COMPANY_FORM,
})

export const selectProjectCompany = (projectId, props) => ({
  type: SELECT_PROJECT_COMPANY,
  projectId,
  props,
})

export const deselectProjectCompany = (projectId, organizationNumber) => ({
  type: DESELECT_PROJECT_COMPANY,
  projectId,
  organizationNumber,
})

export const clearSelectedProjectCompanies = (projectId) => ({
  type: CLEAR_SELECTED_PROJECT_COMPANIES,
  projectId,
})

export const addProjectCompany = (projectId, selectedProjectCompanies) => ({
  type: ADD_PROJECT_COMPANY,
  projectId,
  selectedProjectCompanies,
})

export const removeProjectCompany = (projectId, organizationNumber) => ({
  type: REMOVE_PROJECT_COMPANY,
  projectId,
  organizationNumber,
})

export const deleteProject = (projectId) => ({
  type: DELETE_PROJECT,
  projectId
})

export const pagination = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROJECTS_SUCCEEDED:
      return {
        ...action.payload.pagination,
      }
    default:
      return state
  }
}

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROJECTS_SUCCEEDED:
      return {
        ...action.payload.entities?.project,
      }

    case FETCH_PROJECT_SUCCEEDED:
    case CREATE_PROJECT_SUCCEEDED:
    case UPDATE_PROJECT_INFO_SUCCEEDED:
    case ADD_PROJECT_USER_SUCCEEDED:
    case ADD_PROJECT_COMPANY_SUCCEEDED:
    case REMOVE_PROJECT_USER_SUCCEEDED:
    case REMOVE_PROJECT_COMPANY_SUCCEEDED:
    case COMMENT_ON_PROJECT_SUCCEEDED:
      return Object.entries(action.payload.entities.project || {}).reduce(
        (state, [id, project]) => ({
          ...state,
          [id]: {
            ...state[id],
            ...project,
            fetchStatus: 'succeeded',
          },
        }),
        state,
      )
    
    case DELETE_PROJECT_SUCCEEDED: {
      let newState = { ...state }
      delete newState[action.projectId]
      return newState
      }

    case FETCH_PROJECT:
      return {
        ...state,
        [action.projectId]: {
          ...(state[action.projectId] || {}),
          fetchStatus: 'fetching',
        },
      }
    case ADD_PROJECT_USER:
    case ADD_PROJECT_COMPANY:
    case REMOVE_PROJECT_USER:
    case REMOVE_PROJECT_COMPANY:
    case COMMENT_ON_PROJECT:
      return {
        ...state,
        [action.projectId]: {
          ...(state[action.projectId] || {}),
        },
      }

    case FETCH_PROJECT_FAILED:
    case ADD_PROJECT_USER_FAILED:
    case ADD_PROJECT_COMPANY_FAILED:
    case REMOVE_PROJECT_USER_FAILED:
    case REMOVE_PROJECT_COMPANY_FAILED:
    case COMMENT_ON_PROJECT_FAILED:
      return {
        ...state,
        [action.projectId]: {
          ...(state[action.projectId] || {}),
          fetchStatus: 'failed',
        },
      }

    default:
      return state
  }
}

export const getProjects = (state) => Object.values(state.byId)

export const getProject = (state, projectId) => state.byId[projectId]

const projectsStatus = (state = 'not fetched', action) => {
  switch (action.type) {
    case FETCH_PROJECTS:
      return 'fetching'

    case FETCH_PROJECTS_SUCCEEDED:
      return 'succeeded'

    case FETCH_PROJECTS_FAILED:
      return 'failed'

    default:
      return state
  }
}

export const shouldFetchProjects = (state) =>
  !['succeeded', 'failed'].includes(state.projectsStatus)

export const shouldFetchProject = (state, projectId) =>
  !['succeeded', 'failed', 'fetching'].includes(
    (state.byId[projectId] || {}).fetchStatus,
  )

export const isProjectsPending = (state) =>
  ['fetching', 'not fetched'].includes(state.projectsStatus)

export const isProjectPending = (state, projectId) =>
  !state.byId[projectId] ||
  !state.byId[projectId].fetchStatus ||
  'fetching' === state.byId[projectId].fetchStatus

export const getProjectPagination = (state) => state.pagination

const projectCompanyForm = (state = { country: 'NO' }, action) => {
  switch (action.type) {
    case UPDATE_PROJECT_COMPANY_FORM:
      return {
        ...state,
        ...action.props,
      }
    case CLEAR_PROJECT_COMPANY_FORM:
      return { country: 'NO' }

    default:
      return state
  }
}

const selectedProjectCompanies = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PROJECT_SUCCEEDED:
      return {
        [Object.keys(action.payload.entities.project)[0]]: [],
      }
    case FETCH_PROJECT_SUCCEEDED:
      return {
        [action.projectId]: [],
      }
    case SELECT_PROJECT_COMPANY:
      return {
        ...state,
        [action.projectId]: [...state[action.projectId], action.props],
      }

    case DESELECT_PROJECT_COMPANY:
      return {
        ...state,
        [action.projectId]: state[action.projectId].filter(
          (company) => company.organizationNumber != action.organizationNumber,
        ),
      }
    case CLEAR_SELECTED_PROJECT_COMPANIES:
    case ADD_PROJECT_COMPANY_SUCCEEDED:
      return {
        ...state,
        [action.projectId]: [],
      }

    default:
      return state
  }
}

const projectUserForm = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROJECT_USER_FORM:
      return {
        ...state,
        ...action.props,
      }
    case CLEAR_PROJECT_USER_FORM:
      return {}

    default:
      return state
  }
}

const selectedProjectUsers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PROJECT_SUCCEEDED:
      return {
        [Object.keys(action.payload.entities.project)[0]]: [],
      }
    case FETCH_PROJECT_SUCCEEDED:
      return {
        [action.projectId]: [],
      }
    case SELECT_PROJECT_USER:
      return {
        ...state,
        [action.projectId]: [...state[action.projectId], action.props],
      }

    case DESELECT_PROJECT_USER:
      return {
        ...state,
        [action.projectId]: state[action.projectId].filter(
          (user) => user.userId != action.userId,
        ),
      }
    case CLEAR_SELECTED_PROJECT_USERS:
    case ADD_PROJECT_USER_SUCCEEDED:
      return {
        ...state,
        [action.projectId]: [],
      }

    default:
      return state
  }
}

export const getProjectCompanyForm = (state) => state.projectCompanyForm

export const getSelectedProjectCompanies = (state, projectId) =>
  state.selectedProjectCompanies[projectId]

export const getProjectUserForm = (state) => state.projectUserForm

export const getSelectedProjectUsers = (state, projectId) =>
  state.selectedProjectUsers[projectId]

export default combineReducers({
  byId,
  projectsStatus,
  pagination,
  projectCompanyForm,
  projectUserForm,
  selectedProjectCompanies,
  selectedProjectUsers,
})
