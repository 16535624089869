import React from 'react'

import OwnershipCaseListItem from './OwnershipCaseListItem'
import RoleCaseListItem from './RoleCaseListItem'
import RelationCaseListItem from './RelationCaseListItem'
import OtherCaseListItem from './OtherCaseListItem'

import {
  USER_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  USER_OTHER_CONNECTION_CASE,
  RELATION_CASE,
  RELATION_OWNERSHIP_CASE,
  RELATION_ROLE_CASE,
  RELATION_OTHER_CONNECTION_CASE,
  USER_INDIRECT_OWNERSHIP_CASE,
  RELATION_INDIRECT_OWNERSHIP_CASE,
  USER_INDIRECT_ROLE_CASE,
  RELATION_INDIRECT_ROLE_CASE,
} from '../../caseTypes'

// return different listitems based on case type
const CaseListItem = (props) => {
  switch (props.caseType) {
    case USER_OWNERSHIP_CASE:
    case USER_INDIRECT_OWNERSHIP_CASE:
    case RELATION_OWNERSHIP_CASE:
    case RELATION_INDIRECT_OWNERSHIP_CASE:
      return <OwnershipCaseListItem {...props} />
    case USER_ROLE_CASE:
    case USER_INDIRECT_ROLE_CASE:
    case RELATION_ROLE_CASE:
    case RELATION_INDIRECT_ROLE_CASE:
      return <RoleCaseListItem {...props} />
    case USER_OTHER_CONNECTION_CASE:
    case RELATION_OTHER_CONNECTION_CASE:
      return <OtherCaseListItem {...props} />
    case RELATION_CASE:
      return <RelationCaseListItem {...props} />
    default:
      return null
  }
}

export default CaseListItem
