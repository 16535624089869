import { isBefore, subYears } from 'date-fns'
import { confirmedNoConnectionsProp } from '../../utils'
import { FETCH_COMPANY_CONNECTIONS_SUCCEEDED } from '../companies'
import {
  CREATE_CONNECTION_APPLICATION_SUCCEEDED,
  CREATE_CONNECTION_SUCCEEDED,
  REMOVE_CONNECTION_SUCCEEDED,
} from '../connections'
import {
  CANCEL_CONFIRM_NO_CONNECTIONS_SUCCEEDED,
  CONFIRM_NO_CONNECTIONS_SUCCEEDED,
  FETCH_ME_SUCCEEDED,
  SET_BIRTHDATE_AND_ADDRESS_SUCCEEDED
} from '../currentUser'
import {
  SET_PROFF_PERSON_SUCCEEDED,
  SET_RELATION_PROFF_PERSON_SUCCEEDED,
} from '../proffSelector'
import {
  CREATE_RELATION_SUCCEEDED,
  REMOVE_RELATION_SUCCEEDED,
} from '../relations'
import {
  FETCH_USER,
  FETCH_USER_FAILED,
  FETCH_USER_PROFILE_DATA,
  FETCH_USER_PROFILE_DATA_FAILED,
  FETCH_USER_PROFILE_DATA_SUCCEEDED,
  FETCH_USER_SUCCEEDED,
  FETCH_USERS_SUCCEEDED,
  FETCH_USER_NAMES,
  FETCH_USER_NAMES_SUCCEEDED,
  FETCH_USER_NAMES_FAILED,
  SET_ADDRESS_SUCCEEDED,
  SET_BIRTHDATE_SUCCEEDED,
  SET_EMPLOYMENT_END_DATE_SUCCEEDED,
  SET_PHONE_SUCCEEDED,
  SET_POSITION_OF_INFLUENCE_DESCRIPTION_SUCCEEDED,
  SET_POSITION_OF_INFLUENCE_SUCCEEDED,
} from './index'

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER:
    case FETCH_USER_NAMES:
        return {
        ...state,
        [action.userId]: {
          ...(state[action.userId] || {}),
          status: 'fetching',
        },
      }

    case FETCH_USER_FAILED:
    case FETCH_USER_NAMES_FAILED:
        return {
        ...state,
        [action.userId]: {
          ...(state[action.userId] || {}),
          status: 'failed',
        },
      }

    case FETCH_USERS_SUCCEEDED:
    case FETCH_USER_NAMES_SUCCEEDED:
        return {
        ...action.payload.entities?.user,
      }
    case FETCH_USER_SUCCEEDED:
    case FETCH_ME_SUCCEEDED:
    case SET_EMPLOYMENT_END_DATE_SUCCEEDED:
    case SET_BIRTHDATE_AND_ADDRESS_SUCCEEDED:
    case SET_BIRTHDATE_SUCCEEDED:
    case SET_ADDRESS_SUCCEEDED:
    case SET_PHONE_SUCCEEDED:
    case SET_POSITION_OF_INFLUENCE_SUCCEEDED:
    case SET_POSITION_OF_INFLUENCE_DESCRIPTION_SUCCEEDED:
    case FETCH_COMPANY_CONNECTIONS_SUCCEEDED:
    case CONFIRM_NO_CONNECTIONS_SUCCEEDED:
    case CANCEL_CONFIRM_NO_CONNECTIONS_SUCCEEDED:
      return Object.entries(action.payload.entities.user || {}).reduce(
        (state, [id, user]) => ({
          ...state,
          [id]: {
            ...state[id],
            ...user,
            status: 'succeeded',
            profileDatafetchStatus: undefined,
          },
        }),
        state,
      )

    case FETCH_USER_PROFILE_DATA:
      return {
        ...state,
        [action.userId]: {
          ...state[action.userId],
          profileDatafetchStatus: 'fetching',
        },
      }
    case FETCH_USER_PROFILE_DATA_FAILED:
      return {
        ...state,
        [action.userId]: {
          ...state[action.userId],
          profileDatafetchStatus: 'failed',
        },
      }
    case FETCH_USER_PROFILE_DATA_SUCCEEDED:
      return {
        ...state,
        [action.userId]: {
          ...state[action.userId],
          profileDatafetchStatus: 'succeeded',
        },
      }
    case SET_PROFF_PERSON_SUCCEEDED:
    case SET_RELATION_PROFF_PERSON_SUCCEEDED:
    case CREATE_CONNECTION_SUCCEEDED:
    case CREATE_RELATION_SUCCEEDED:
    case CREATE_CONNECTION_APPLICATION_SUCCEEDED:
    case REMOVE_CONNECTION_SUCCEEDED:
    case REMOVE_RELATION_SUCCEEDED:
      return {
        ...state,
        [action.userId]: {
          ...state[action.userId],
          profileDatafetchStatus: undefined,
        },
      }

    default:
      return state
  }
}

export const getUser = (state, userId) => state[userId]
export const shouldFetchUser = (state, userId) =>
  !state[userId] || state[userId].status !== 'succeeded'
export const isUserFetching = (state, userId) =>
  !state[userId] || !state[userId].status || 'fetching' === state[userId].status
export const isUserProfileDataFetching = (state, userId) =>
  state[userId] && state[userId].profileDatafetchStatus === 'fetching'
export const shouldFetchUserProfileData = (state, userId) =>
  state[userId] && state[userId].profileDatafetchStatus === undefined

export const hasConfirmedNoConnections = (state, userId, connectionType) => {
  return isBefore(
    subYears(Date.now(), 1),
    new Date(state?.[userId]?.[confirmedNoConnectionsProp(connectionType)]),
  )
}

export default byId
