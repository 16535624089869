import React from 'react'
import { prettyDate } from '../../dateUtils'
import CaseListItem from './CaseListItem'
import caseProps from '../caseProps'
import { useI18nFunction } from '../I18n'
import {
  USER_INDIRECT_ROLE_CASE,
  RELATION_INDIRECT_ROLE_CASE
} from '../../caseTypes'

const RoleCaseListItem = (props) => {
  const I18nFunction = useI18nFunction();
  const isIndirect = [USER_INDIRECT_ROLE_CASE, RELATION_INDIRECT_ROLE_CASE].includes(props.caseType)
  return (
    <li>
      <CaseListItem
        {...props}
        image={caseProps[props.Case.caseType].image}
        title={props.Case.organizationName}
        description={
          !isIndirect ? 
          props.Case.caseSubType : 
          I18nFunction({
            id: 'connection_list_item.ownership_description',
            stockCount: '',
            shareType: 'indirect_role',
            stakePercentage: ''
            })
          }
        from={prettyDate(props.Case.updatedOn)}
        userConnection={
          isIndirect ? props.Case.userOwnership :
          !props.Case.caseType.includes('relation')
            ? props.Case.userRole
            : props.Case.relationRole
        }
        publicConnection={
          isIndirect ? props.Case.userPublicDataOwnership :
          !props.Case.caseType.includes('relation')
            ? props.Case.userPublicDataRole
            : props.Case.relationPublicDataRole
        }
        userName={props.Case.userName}
        relationName={props.Case.relationName}
      />
    </li>
  )
}

export default RoleCaseListItem
