import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  getAllCases,
  isCasesPending,
  shouldFetchCases,
  getCasePagination,
  getCurrentUserId
} from '../redux/selectors'
import { fetchAllCases } from '../redux/actions'
import Page from '../components/Page'
import LinkStyled from '../components/Link'
import Button from '../components/Button'
import MultiApproveCasesModal from '../components/MultiApproveCasesModal'
import usePagination from '../hooks/usePagination'
import I18n, { useI18nFunction } from '../components/I18n'
import DataListHeader from '../components/DataList/DataListHeader'
import DataListColumns from '../components/DataList/DataListColumns'
import DataList from '../components/DataList/DataList'
import useSort from '../hooks/useSort'
import useToggleCases from '../hooks/useToggleCases'

import { IN_PROGRESS, APPROVED, NOT_APPROVED } from '../CaseStatus'

const Cases = ({
  cases,
  casesToApprove,
  pending,
  shouldFetchCases,
  fetchAllCases,
  casePagination,
}) => {
  const I18nFunction = useI18nFunction();


  const casePageProps = {
    paginationProps: {
      perPageOptions: [10, 15, 25, 50, 100, I18nFunction( { id: 'pagination.fetch_all' } )],
    },
    searchInputProps: {
      placeholder: I18nFunction( { id: 'Search_for_cases' } ),
    },
    sortProps: {
      placeholder: I18nFunction({ id: 'Sort_by' }),
      options: [
        {
          label: I18nFunction({ id: 'Created_on_desc' }),
          key: 'created_on_desc',
          value: {
            iteratee: 'createdOn',
            order: 'desc',
          },
        },
        {
          label: I18nFunction({ id: 'Created_on_asc' }),
          key: 'created_on_asc',
          value: {
            iteratee: 'createdOn',
            order: 'asc',
          },
        },
        {
          label: I18nFunction({ id: 'Updated_on_desc' }),
          key: 'updated_on_desc',
          value: {
            iteratee: 'updatedOn',
            order: 'desc',
          },
        },
        {
          label: I18nFunction({ id: 'Updated_on_asc' }),
          key: 'updated_on_asc',
          value: {
            iteratee: 'updatedOn',
            order: 'asc',
          },
        },
      ],
    },
    multiSelectProps: {
      filters: [
        {
          key: 'caseStatusFilter',
          placeholder: I18nFunction( { id: 'filterType',  payload: 'list' } ),
          options: [
            {
              label: I18nFunction({ id: 'case_statuses.in_progress' }),
              value: IN_PROGRESS,
              key: 1,
            },
            {
              label: I18nFunction({ id: 'case_statuses.approved' }),
              value: APPROVED,
              key: 2,
            },
            {
              label: I18nFunction({ id: 'case_statuses.not_approved' }),
              value: NOT_APPROVED,
              key: 3,
            },
          ],
        },
        {
          key: 'caseRiskFilter',
          placeholder: I18nFunction( { id: 'filterType',  payload: 'toggle' } ), 
          options: [
            {
              label: I18nFunction({ id: 'case_risk_categories.organization_is_supplier' }),
              value: 'is_supplier_for_employee_org',
              key: 1,
            },
            {
              label: I18nFunction({ id: 'case_risk_categories.organization_is_client' }),
              value: 'is_customer_for_employee_org',
              key: 2,
            },
            {
              label: I18nFunction({ id: 'case_risk_categories.position_of_influence' }),
              value: 'position_of_influence',
              key: 3,
            },
            {
              label: I18nFunction({ id: 'case_risk_categories.is_board_member' }),
              value: 'is_board_member',
              key: 4,
            },
            // Not yet implemented in back-end
            // {
            //   label: I18nFunction({ id: 'case_risk_categories.no_risk' }),
            //   value: 'no_risk',
            //   key: 5,
            // },
          ],
        },
        {
          key: 'caseTypeFilter',
          placeholder: I18nFunction( { id: 'filterType',  payload: 'type' } ),
          options: [
            {
              label: I18nFunction({ id: 'case_types.user_ownership' }),
              value: 'user_ownership',
              key: 1,
            },
            {
              label: I18nFunction({ id: 'case_types.user_role' }),
              value: 'user_role',
              key: 2,
            },
            {
              label: I18nFunction({ id: 'case_types.user_other_connection' }),
              value: 'user_other_connection',
              key: 3,
            },
            {
              label: I18nFunction({ id: 'case_types.relation_ownership' }),
              value: 'relation_ownership',
              key: 4,
            },
            {
              label: I18nFunction({ id: 'case_types.relation_role' }),
              value: 'relation_role',
              key: 5,
            },
            {
              label: I18nFunction({ id: 'case_types.relation_other_connection' }),
              value: 'relation_other_connection',
              key: 6,
            },
          ],
        },
        {
          key: 'directFilter',
          placeholder: I18nFunction( { id: 'filterType',  payload: 'direct' } ),
          options: [
            {
              label: I18nFunction({ id: 'direct_filter.direct' }),
              value: 'direct',
              key: 1,
            },
            {
              label: I18nFunction({ id: 'direct_filter.indirect' }),
              value: 'indirect',
              key: 2,
            },
          ],
        },
      ],
    },
    dateRangeFilterProps: {
      dateType: {
        label: I18nFunction({ id: 'Time_period' }),
        placeholder: I18nFunction({ id: 'Time_period_select' }),
        filtrable: true,
        type: 'dateType',
        options: [
          {
            label: I18nFunction({ id: 'Updated_on' }),
            key: 'updated_on',
            value: {},
          },
          {
            label: I18nFunction({ id: 'Created_on' }),
            key: 'created_on',
            value: {},
          },
        ],
      },
      fromDate: {
        label: I18nFunction({ id: 'filterList_properties.caseFromDate' }),
        placeholder: I18nFunction({ id: 'Date_placeholder' }),
        propertyName: 'createdOn',
        id: 'fromDate',
        value: new Date(),
        type: 'range',
      },
      toDate: {
        label: I18nFunction({ id: 'filterList_properties.caseToDate' }),
        placeholder: I18nFunction({ id: 'Date_placeholder' }),
        buttonDescription: I18nFunction({ id: 'Reset_data_filter' }),
        propertyName: 'createdOn',
        id: 'toDate',
        value: new Date(),
        type: 'range',
      },
    },
    listColumnProps: {
      columns: [
        {
          label: I18nFunction({ id: 'CaseId' }),
          key: 'id',
          width: '6%',
        },
        {
          label: I18nFunction({ id: 'Created_on' }),
          key: 'createdOn',
          width: '9%',
        },
        {
          label: I18nFunction({ id: 'Updated_on' }),
          key: 'updatedOn',
          width: '9%',
        },
        {
          label: I18nFunction({ id: 'case_has_risks' }),
          key: 'risk',
          width: '55%',
        },
        {
          label: 'Status',
          key: 'status',
          width: '15%',
        },
      ],
    },
  }

  const {
    query,
    pageIndex,
    perPage,
    paginationResult,
    filters,
    sortOption,
    dateRangeOption,
    dateRangeFrom,
    dateRangeTo,
    setQuery,
    setPageIndex,
    setPerPage,
    setQueryHandler,
    setFiltersHandler,
    setSortOptionHandler,
    setDateRangeOptionHandler,
    setDateRangeFromHandler,
    setDateRangeToHandler,
    setPerPageHandler,
  } = usePagination(fetchAllCases, getCasePagination, casePagination)

  const { sort, setSort, sortFunction } = useSort(
    casePageProps?.sortProps?.options[0],
  )

  const sortCases = () => {
    if (casePagination?.sortOption && casePagination?.sortOption != sort?.key) {
      let idx = casePageProps?.sortProps?.options.findIndex(
        (o) => o.key === casePagination?.sortOption,
      )
      setSort(casePageProps?.sortProps?.options[idx])
    }
    return sortFunction(cases)
  }

  const { toggleAllCases, selectedCaseIds, selectedCases, toggleCase, clearAllCases } =
  useToggleCases(casesToApprove)

  const caseIdsToApprove = Object.values(casesToApprove).map(({id}) => id)

  const buttonDisabled = selectedCaseIds.length === 0 ? 1 : 0

  const handleToggleAll = () => {
    toggleAllCases()
  }

  const [showMultiApproveCasesModal, setShowMultiApproveCasesModal] = useState(false)

  return (
    <Page>
      <Content>
        {showMultiApproveCasesModal && (
          <MultiApproveCasesModal
            selectedCaseIds={selectedCaseIds}
            setShowMultiApproveCasesModal={setShowMultiApproveCasesModal}
            cases={cases}
            clearAllCases={clearAllCases}
          />
        )}
        <h1>
          <I18n id="Cases" />
        </h1>
        <Options>
          <ActionButton
                small
                inactive={buttonDisabled}
                disabled={buttonDisabled}
                onClick={() =>
                  setShowMultiApproveCasesModal(true)
                }
              >
                <I18n id="multi_approve_cases.approve_selected_cases" />
          </ActionButton>
          <ActionButton
              small
              onClick={() =>
                (window.location.href = `${window.location.origin}/api/cases/download_active_cases`)
              }
            >
              <I18n id="Export_active_cases" />
            </ActionButton>
        </Options>
        <DataListHeader
          pageProps={casePageProps}
          query={query}
          pageIndex={pageIndex}
          perPage={perPage}
          paginationResult={paginationResult}
          filters={filters}
          sortOption={sortOption}
          dateRangeOption={dateRangeOption}
          dateRangeFrom={dateRangeFrom}
          dateRangeTo={dateRangeTo}
          setQuery={setQuery}
          setPageIndex={setPageIndex}
          setPerPage={setPerPage}
          setQueryHandler={setQueryHandler}
          setFiltersHandler={setFiltersHandler}
          setSortOptionHandler={setSortOptionHandler}
          setDateRangeOptionHandler={setDateRangeOptionHandler}
          setDateRangeFromHandler={setDateRangeFromHandler}
          setDateRangeToHandler={setDateRangeToHandler}
          setPerPageHandler={setPerPageHandler}
        />
        <HeaderWrapper>
          <DataListColumns props={casePageProps?.listColumnProps} />
          <ToggleAllButton small as="button" onClick={handleToggleAll} to="">
            {selectedCaseIds.length === 0 ? (
              <I18n id="Select_all" />
            ) : (
              <I18n id="Remove_selection" />
            )}
          </ToggleAllButton>
        </HeaderWrapper>
        <DataList 
          data={sortCases()} 
          pending={pending} 
          selectedCases={selectedCases} 
          toggleCase={toggleCase}
          caseIdsToApprove={caseIdsToApprove}
        />
      </Content>
    </Page>
  )
}

const Content = styled.div`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
  padding: 0 6em;
`

const ToggleAllButton = styled(LinkStyled)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: calc(100% + 1em);
  white-space: nowrap;
`

const HeaderWrapper = styled.div`
  position: relative;
`

const ActionButton = styled(Button)`
  background: #0c3689;
  border: 2px solid #0c3689;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 13em;
`

const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  padding: 0.5em 0.5em 0.5em 0.5em;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};

  & > * {
    margin: 0 0.5em;
  }
`

const mapStateToProps = (state) => {
  const currentUserId = getCurrentUserId(state)

  const cases = getAllCases(state)
  const casesToApprove = cases.filter((c) => c.userId != currentUserId)

    return {
    cases: cases,
    casesToApprove: casesToApprove,
    pending: isCasesPending(state),
    shouldFetchCases: shouldFetchCases(state),
    casePagination: getCasePagination(state),
  }
}

const mapDispatchToProps = { fetchAllCases }

export default connect(mapStateToProps, mapDispatchToProps)(Cases)
