import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import I18n, { useI18nFunction } from '../I18n'
import Button from '../Button'
import { Input, TextAreaInput, ProjectStatusSelector } from '../form'
import useValidation from '../../hooks/useValidation'
import useVisited from '../../hooks/useVisited'
import * as schema from '../../validation'

const ProjectForm = ({ createProject, onClose, pending }) => {
  const I18nFunction = useI18nFunction()

  const [state, setState] = useState({
    title: '',
    description: '',
    project_status: '',
    project_assessment_status: '',
    custom_data: {
      case_number: ''
    },
  })

  const { errors, validate } = useValidation(schema.projectForm(I18nFunction))

  useEffect(() => {
    const cancelablePromise = validate(state)
    return cancelablePromise.cancel
  }, [validate, state])

  const { visited, visitField, visitFields } = useVisited()

  const handleBlur = (e) => visitField(e.target.name)

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeData = (e) => {
    setState({
      ...state,
      custom_data: {
        ...state.custom_data,
        [e.target.name]: e.target.value,
      }
    })
  }

  const hasErrors = Object.keys(errors).length > 0

  const handleSubmit = () => {
    //e.preventDefault()
    if (hasErrors) {
      visitFields(Object.keys(errors))
    } else {
      createProject(state.title, state.description, state.project_status, state.project_assessment_status, state.custom_data)
      onClose()
    }
  }

  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <InputText>
            <I18n id="Title" />
          </InputText>
          <InputField>
            <Input
              name="title"
              id="title"
              value={state.title}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.title}
              visited={visited.title}
              />
          </InputField>
        </InputWrapper>
        <InputWrapper>
          <InputText>
            <I18n id="Description" />
          </InputText>
          <InputField>
            <TextAreaInput
              name="description"
              id="description"
              value={state.description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.description}
              visited={visited.description}
              rows={'5'}
              />
          </InputField>
        </InputWrapper>
        <InputWrapper>
          <InputText>
            <I18n id="Project_status" />
          </InputText>
          <InputField>
            <ProjectStatusSelector
              name="project_status"
              id="project_status"
              value={state.project_status}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.project_status}
              visited={visited.project_status}
              />
          </InputField>
        </InputWrapper>
        <InputWrapper>
          <InputText>
            <I18n id="Project_assessment_status" />
          </InputText>
          <InputField>
            <ProjectStatusSelector
              name="project_assessment_status"
              id="project_assessment_status"
              value={state.project_assessment_status}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.project_assessment_status}
              visited={visited.project_assessment_status}
              />
          </InputField>
        </InputWrapper>
        <InputWrapper>
          <InputText>
            <I18n id="Project_custom_fields.Case_number" />
          </InputText>
          <InputField>
            <Input
              name="case_number"
              id="case_number"
              value={state.custom_data.case_number}
              onChange={handleChangeData}
              onBlur={handleBlur}
              />
          </InputField>
        </InputWrapper>
        <Actions>
          <Button
            type="submit"
            small
            onClick={() => handleSubmit()}
            inactive={hasErrors}
            pending={pending}
          >
            <I18n id="Create_project" />
          </Button>
          <Button subtle onClick={onClose} small>
            <I18n id="Close" />
          </Button>
        </Actions>
      </Form>
    </FormWrapper>
  )
}

const Form = styled.form`
  margin: 0 auto;
`

const FormWrapper = styled.div`
  width: 100%;
  padding: 2em 1em;
  border-radius: 5px;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding-bottom: 1em;
`

const InputText = styled.div`
  width: 20%;
`

const InputField = styled.div`
  width: 80%;
`

const Actions = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  & > * {
    margin: 0 1em;
  }
`
export default ProjectForm
