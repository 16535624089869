import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useDelaySetFalse from '../hooks/useDelaySetFalse'
import caretDown from '../icons/caret-down.svg'
import Spinner from './SmallSpinner'

const ScrollPaginate = ({
  listItems,
  fetchListItems,
  pagination = {},
  pageIndex,
  setPageIndex,
  loading,
  children,
  maxHeight,
  isMoreData,
  shouldFetchData,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const scrollContainerRef = useRef(null)

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    // Restore the scroll position
    if (scrollContainer) {
      scrollContainer.scrollBottom = scrollPosition
    }

    if (shouldFetchData) {
      loadMoreData()
    }
  }, [listItems])

  const delayedLoadingIndicator = useDelaySetFalse(loading,500)
  function loadMoreData() {
    setPageIndex(pageIndex + 1)
    fetchListItems(pageIndex + 1)
  }
  const handleScroll = (e) => {
    // Define the method to check for the bottom of the component
    const bottom =
      Math.round(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight,
      ) <= 1

    if (bottom && !loading && e.target.scrollTop > 0) {
      // User has scrolled to the bottom of this component
      // Trigger action to fetch more content
      if (pagination.perPage * pageIndex < pagination.total) {
        setScrollPosition(e.target.scrollBottom)
        loadMoreData()
      }
    }
  }

  return (
    <Scrollbox
      ref={scrollContainerRef}
      onScroll={handleScroll}
      maxHeight={maxHeight}
    >
      {children}
      {(isMoreData || delayedLoadingIndicator) && (
        <ScrollIndicator onClick={loadMoreData}>
          {delayedLoadingIndicator && <Spinner size={'2em'} color={'secondary'} />}
          {!delayedLoadingIndicator && !loading && <DropdownIcon src={caretDown} alt="" />}
        </ScrollIndicator>
      )}
    </Scrollbox>
  )
}

const Scrollbox = styled.div`
  max-height: ${(props) => props.maxHeight || 'auto'}
  scrollbar-gutter: stable;
  overflow-y: overlay;
  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border: 3px solid var(--scrollbarBG);
 `
const ScrollIndicator = styled.div`
  height: 20px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
const DropdownIcon = styled.img`
  height: 100%;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #efefef;
  }
`

export default ScrollPaginate
