import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import I18n from './I18n'
import ProjectForm from './ProjectForm/ProjectForm'
import { createProject } from '../redux/actions'

const CreateProjectModal = ({ onClose, createProject }) => {
  return (
    <Overlay onClick={onClose}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <FormWrapper>
          <Header>
            <h1>
              <I18n id="New_project" />
            </h1>
          </Header>
          <Body>
            <ProjectForm createProject={createProject} onClose={onClose} />
          </Body>
        </FormWrapper>
      </ContentWrapper>
    </Overlay>
  )
}

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`

const ContentWrapper = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  margin: 0 auto;
  min-height: 450px;
  min-width: 700px;
  -moz-box-shadow: 0px 0px 18px 6px #ccc;
  -webkit-box-shadow: 0px 0px 18px 6px #ccc;
  box-shadow: 0px 0px 18px 6px #ccc;
  padding: 1em;
  display: flex;
  flex-direction: column;
`

const FormWrapper = styled.div`
  padding: 2em;
  background-color:  ${(props) => props.theme.pageBackground};
  border-radius: 5px;
`

const Header = styled.div`
  margin: 0;
`

const Body = styled.div`
  flex-grow: 7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  createProject,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectModal)
