import { normalize } from 'normalizr'
import { call, cancel, fork, put, select, take } from 'redux-saga/effects'

import { sagas as content } from './appContent'
import { sagas as applications } from './applications'
import { sagas as appSettings } from './appSettings'
import { sagas as auth } from './auth'
import { sagas as cases } from './cases'
import { sagas as companies } from './companies'
import { sagas as connections } from './connections'
import { sagas as currentUser } from './currentUser'
import { sagas as events } from './events'
import { sagas as proffSelector } from './proffSelector'
import { sagas as projects } from './projects'
import { sagas as relations } from './relations'
import { sagas as reminder } from './reminder'
import { sagas as suggestions } from './suggestions'
import { sagas as users } from './users'

function* saga() {
  yield fork(auth)
  yield fork(events)
  yield fork(users)
  yield fork(connections)
  yield fork(companies)
  yield fork(projects)
  yield fork(suggestions)
  yield fork(currentUser)
  yield fork(reminder)
  yield fork(cases)
  yield fork(applications)
  yield fork(appSettings)
  yield fork(content)
  yield fork(proffSelector)
  yield fork(relations)
}

// custom fetchPaginationSaga
export const fetchSagaPagination = ({
  SUCCEEDED,
  FAILED,
  schema,
  fetch,
  selectors = [],
}) =>
  function* saga(props) {
    try {
      let selected = []
      for (let i = 0; i < selectors.length; i++) {
        selected.push(yield select(selectors[i]))
      }
      const { data, errors } = yield call(fetch, { ...props, selected })
      if (errors) {
        yield put({
          ...props,
          type: FAILED,
          errors,
        })
      } else {
        yield put({
          ...props,
          type: SUCCEEDED,
          payload: {
            ...normalize(data.items, schema),
            pagination: data.pagination,
          },
        })
      }
    } catch (error) {
      console.error(error)
      yield put({
        ...props,
        type: FAILED,
        errors: [{ detail: error.message }],
      })
    }
  }

export const fetchSaga = ({
  SUCCEEDED,
  FAILED,
  schema,
  fetch,
  selectors = [],
}) =>
  function* saga(props) {
    try {
      let selected = []
      for (let i = 0; i < selectors.length; i++) {
        selected.push(yield select(selectors[i]))
      }
      const { data, errors } = yield call(fetch, { ...props, selected })
      if (errors) {
        yield put({
          ...props,
          type: FAILED,
          errors,
        })
      } else {
        yield put({
          ...props,
          type: SUCCEEDED,
          payload: normalize(data, schema),
        })
      }
    } catch (error) {
      console.error(error)
      yield put({
        ...props,
        type: FAILED,
        errors: [{ detail: error.message }],
      })
    }
  }
export const takeEveryWithCancel = (
  FETCH_PATTERN,
  cancel_patterns,
  saga,
  args,
) => {
  return fork(function* () {
    const tasks = []
    while (true) {
      // Wait for either the fetch action or the cancellation action
      const action = yield take([FETCH_PATTERN, ...cancel_patterns])

      if (action.type === FETCH_PATTERN) {
        const task = yield fork(saga(args), action)
        tasks.push(task)
      } else if (cancel_patterns.includes(action.type)) {
        while (tasks.length > 0) {
          const task = tasks.pop()
          yield cancel(task)
        }
      }
    }
  })
}
export const takeLatestWithCancel = (
  FETCH_PATTERN,
  CANCEL_PATTERN,
  saga,
  args) =>
   fork(function*() { 
    let lastTask
     while (true) { 
      const action = yield take([FETCH_PATTERN, CANCEL_PATTERN])
      
      //Cancel task regardless FETCH or CANCEL
      if (lastTask) {
        yield cancel(lastTask) // cancel is no-op if the task has already terminated
      }
      if (action.type===FETCH_PATTERN)
        lastTask = yield fork(saga(args), action)
      }
})

export const takeLeadingUniqueArgs = (patternOrChannel, saga, args) => {
  const tasks = {}
  return fork(function*() { 
    while (true)
    {
      const action = yield take(patternOrChannel);
      const taskKey = Object.entries(action).map(([k, v]) => `${k}:${v}`).join('-')
      if (!tasks[taskKey])
        tasks[taskKey] = yield fork(saga(args),action); 
      
      else if (tasks[taskKey] && !tasks[taskKey].isRunning()) 
        delete tasks[taskKey]
    }
  })
}

export default saga
