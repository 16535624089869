import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { prettyDate } from '../dateUtils'
import redFlag from '../icons/red_flag.svg'
import I18n, { useI18nFunction } from './I18n'
import DataListSpinner from './DataList/DataListSpinner'

const ProjectList = ({ projects, pending }) => {

  const I18nFunction = useI18nFunction();

  return (
    <>
      {pending ? (
        <DataListSpinner />
      ) : (
        <List>
          {projects.map(
            ({
              title,
              createdOn,
              numberOfProjectUsers,
              numberOfProjectCompanies,
              numberOfConflicts,
              id,
            }) => (
              <li key={id}>
                <Project to={`/projects/${encodeURIComponent(id)}`}>
                  <Title>{title}</Title>
                  <CreatedOn>{prettyDate(createdOn)}</CreatedOn>
                  <Column>
                    {I18nFunction({
                      id: 'project_list_item.users',
                      count: numberOfProjectUsers ? numberOfProjectUsers : 0,
                    })}
                  </Column>
                  <Column>
                    {I18nFunction({
                      id: 'project_list_item.companies',
                      count: numberOfProjectCompanies ? numberOfProjectCompanies : 0,
                    })}
                  </Column>
                  <Column>
                    {numberOfConflicts ? (
                      <Flag src={redFlag} alt="" />
                    ) : (
                      <NoFlag />
                    )}
                    {I18nFunction({
                      id: 'project_list_item.conflicts',
                      count: numberOfConflicts ? numberOfConflicts : 0,
                    })}
                  </Column>
                  <LinkText>
                    <I18n id="Go_to_project" />
                  </LinkText>
                </Project>
              </li>
            ),
          )}
        </List>
      )}
    </>
  )
}

export const getNumConflicts = (projectUsers) => {
  let conflicts = 0
  for(let i = 0; i < projectUsers.length; i++) {
    if(projectUsers[i].conflicts !== null && projectUsers[i].conflicts.length > 0) {
      conflicts += projectUsers[i].conflicts.length
    }
  }
  return conflicts
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 1em;
  }
`

const Project = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { text } }) => text};
  display: flex;
  padding: 1em 1.5em;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
`

const Title = styled.div`
  font-size: ${({ theme: { largeText } }) => largeText};
  width: 37%;
  display: flex;
  align-items: center;
`

const CreatedOn = styled.div`
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Column = styled.div`
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Flag = styled.img`
  height: 1.25em;
  width: 1.25em;
  margin-right: 1em;
`

const NoFlag = styled.div`
  height: 1.25em;
  width: 1.25em;
  margin-right: 1em;
`

const LinkText = styled.div`
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: end;
  white-space: nowrap;
  color: ${({ theme: { secondary } }) => secondary};
  font-size: ${({ theme: { largeText } }) => largeText};
`

export default ProjectList
