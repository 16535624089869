import React from 'react'
import styled from 'styled-components'
import ownershipIcon from '../../icons/chartLine.svg'
import roleIcon from '../../icons/role.svg'
import relationIcon from '../../icons/relation.svg'
import otherIcon from '../../icons/other.svg'
import I18n from '../I18n'
import { useI18nFunction } from '../I18n'
import { camelize } from 'humps'

import {
    USER_OWNERSHIP_CASE,
    USER_ROLE_CASE,
    USER_OTHER_CONNECTION_CASE,
    RELATION_OWNERSHIP_CASE,
    RELATION_ROLE_CASE,
    RELATION_OTHER_CONNECTION_CASE,
  } from '../../caseTypes'

const CaseInfo = (props) => {
    if (props.Case.relationType){
        return <RelationCaseInfo {...props}/>
    }
    switch (camelize(props.Case.caseType)) {
    case USER_OWNERSHIP_CASE:
    case RELATION_OWNERSHIP_CASE:
        return <OwnershipCaseInfo {...props}/>
    case USER_ROLE_CASE:
    case RELATION_ROLE_CASE:
        return <RoleCaseInfo {...props}/>
    case USER_OTHER_CONNECTION_CASE:
    case RELATION_OTHER_CONNECTION_CASE:
        return <OtherConnectionCaseInfo {...props}/>
    default:
        throw new Error('Unknown case type')
    }
}

const OwnershipCaseInfo = ({ 
    Case
  }) => {

    const I18nFunction = useI18nFunction()
    const ownershipData = Case.userOwnership || Case.userPublicDataOwnership || Case.relationOwnership || Case.relationPublicDataOwnership

    const renderDescription = () => {
      if (ownershipData != null) {
        
        return I18nFunction( { 
          id:'connection_list_item.ownership_description',
          stockCount: ownershipData.stockCount,
          shareType: ownershipData.shareType,
          stakePercentage: parseFloat(ownershipData.stakePercentage).toFixed(2),
        })
      } else {
        return I18nFunction( { id: 'connection_list_item.ownership_description_fallback' } )
      }
    }

    return (
      <Wrapper>
          <CaseType>
            <Icon src={ownershipIcon} alt="" />
            <Description>
              <I18n id="case_info.case_type.ownership"/>
            </Description>
          </CaseType>
          <Title>{Case.organizationName} </Title>
          <Description>
            {I18nFunction( { 
              id:'case_info.description',
              type: 'org_nr',
              data: Case.organizationNumber,
            })}
          </Description>
          <Description>
            {renderDescription()}
          </Description>
          <Description>
            <I18n id="Case" /> #{Case.id}
          </Description>
      </Wrapper>
    )
}

const RoleCaseInfo  = ({ 
    Case,
  }) => {
    const I18nFunction = useI18nFunction();
    return (
      <Wrapper>
          <CaseType>
            <Icon src={roleIcon} alt="" />
            <Description>
              <I18n id="case_info.case_type.role"/>
            </Description>
          </CaseType>
          <Title>{Case.organizationName}</Title>
          <Description>
            {I18nFunction( { 
              id:'case_info.description',
              type: 'org_nr',
              data: Case.organizationNumber,
            })}
          </Description>
          <Description>{Case.caseSubType}</Description>
      </Wrapper>
    )
}

const RelationCaseInfo = ({
    Case,
  }) => {
    const I18nFunction = useI18nFunction()
    return (
      <Wrapper>
        <CaseType>
          <Icon src={relationIcon} alt="" />
          <Description>
            <I18n id="case_info.case_type.relation"/>
          </Description>
        </CaseType>
        <Title>
          {I18nFunction( { 
            id:'case_info.name',
            first_name: Case.firstName,
            last_name: Case.lastName,
          })}
        </Title>
        <Description>
        {I18nFunction( { 
            id:'case_info.birthdate',
            day: Case.birthdate_day,
            month: Case.birthdate_month,
            year: Case.birthdate_year,
          })}
        </Description>
        <Description>
          {I18nFunction( { 
            id:'case_info.address',
            address: Case.address,
            post_code: Case.postCode,
            post_place: Case.postPlace,
          })}
        </Description>
      </Wrapper>
    )
}

const OtherConnectionCaseInfo = ({
    Case
  }) => {
    return (
      <Wrapper>
        <CaseType>
          <Icon src={otherIcon} alt="" />
          <Description>
            <I18n id="case_info.case_type.other"/>
          </Description>
        </CaseType>
        <Title>{Case.organizationName}</Title>
        <Description>{Case.caseSubType}</Description>
      </Wrapper>
    )
}

const Description = styled.p`
  color: ${({ theme: { subtleText } }) => subtleText};  
  margin: 0px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`

const CaseType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Title = styled.h1`
  margin: 0px;
`

const Icon = styled.img`
  margin-right: 0.5em;
  height: 1.7em;
  width: 1.7em;
  flex-shrink: 0;
`
export default CaseInfo   