import React from 'react'
import styled from 'styled-components'
import I18n from '../I18n'

const ProffRelationHeader = ({loading}) => {
  return (
    loading ?
    <div>
      <Header>
        <h1>
          <I18n id="proff_id_form.relation_header" />
        </h1>
        <p>
          <I18n id="proff_id_form.sub_header" />
        </p>
      </Header>
    </div>
    :
    <div>
      <Header>
        <h1>
          <I18n id="proff_id_form.relation_header" />
        </h1>
        <p>
          <I18n id="proff_id_form.sub_header" />
        </p>
      </Header>
      <h1>
        <I18n id="proff_id_form.form_header" />
      </h1>
      <Description>
        <I18n id="proff_id_form.relation_description" />
      </Description>
    </div>
  )
}

const Header = styled.section`

  & > p {
    font-size: ${(props) => props.theme.largeText};
  }
`

const Description = styled.div`
  max-width: 800px;
`
export default ProffRelationHeader
