import React from 'react'
import { connect } from 'react-redux'
import uuid from 'uuid/v4'
import styled from 'styled-components'
import Button from './Button'

const Plus = styled.span`
  font-size: 2em;
  line-height: 0;
`

const AddConnectionButton = ({
  children,
  addConnection,
  hasTransientConnection,
  indirect,
}) => (
  indirect ? 
  <ActionButton
    small
    onClick={addConnection}
    inactive={hasTransientConnection}
    disabled={hasTransientConnection}
  >
    <Plus>+</Plus>
    <span>{children}</span>
  </ActionButton>
  :
  <Button
    onClick={addConnection}
    inactive={hasTransientConnection}
    disabled={hasTransientConnection}
  >
    <Plus>+</Plus>
    <span>{children}</span>
  </Button>
)

const ActionButton = styled(Button)`
  background: #0c3689;
  border: 2px solid #0c3689;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 9em;
`


const mapStateToProps = () => ({})

const mapDispatchToProps = (
  dispatch,
  { userId, connectionType, ownershipType, directConnectionCaseId, relationId, action },
) => ({
  addConnection: () =>
    dispatch(action({ id: uuid(), userId, relationId, connectionType, ownershipType, directConnectionCaseId })),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddConnectionButton)
