import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  RELATION_CASE,
  USER_OTHER_CONNECTION_CASE,
  USER_OWNERSHIP_CASE,
  USER_ROLE_CASE,
} from '../../caseTypes'
import {
  RELATION,
  USER_OTHER_CONNECTION,
  USER_OWNERSHIP,
  USER_ROLE,
} from '../../connectionTypes'
import { unsetActiveConnection } from '../../redux/actions'
import {
  getCaseStatistics,
  getCurrentUser,
  getUser,
  getUserCasesOfType,
  getUserRelations,
  isAdmin,
  isCasesPending,
  isRelationCasesPending,
  isReminderFetching,
  isUserProfileDataFetching
} from '../../redux/selectors'
import { useI18nFunction } from '../I18n'
import TabContent from '../TabContent'
import { Tab, TabList, TabPanel, Tabs } from '../tabs'
import ProfileCaseSection from './ProfileCaseSection'

import ownershipIcon from '../../icons/chartLine.svg'
import otherIcon from '../../icons/other.svg'
import relationIcon from '../../icons/relation.svg'
import roleIcon from '../../icons/role.svg'
import { resetPagination } from '../../redux/actions'

const ProfileConnectionTabs = ({
  currentUser,
  userId,
  userOwnershipCases,
  userRoleCases,
  userOtherConnectionCases,
  userRelations,
  ownershipRegistrationCompleted,
  roleRegistrationCompleted,
  relationRegistrationCompleted,
  otherConnectionRegistrationCompleted,
  unsetActiveConnection,
  resetPagination,
  caseStatistics,
  isCasesPending,
  isRelationCasesPending,
  isUserProfileDataFetching
}) => {
  const [tabIndex, setTabIndex] = useState(0)
  const I18nFunction = useI18nFunction()
  let tabs = [
    {
      enabled: true,
      ...I18nFunction({
        id: 'connection_form.tabs.ownership',
        returnRawObject: true,
      }),
      image: ownershipIcon,
      connectionType: USER_OWNERSHIP,
      caseType: USER_OWNERSHIP_CASE,
      connectionFormTabName: 'ownership',
      tabStats: caseStatistics[USER_OWNERSHIP_CASE],
      items: userOwnershipCases,
      hasApplicationComponents:
        process.env.REACT_APP_APPLICATIONS_ENABLED?.toLowerCase() === 'true',
      connectionKeys: {
        publicData: 'userPublicDataOwnership',
        userRegistered: 'userOwnership',
        applications: 'ownershipApplications',
      },
      completed: ownershipRegistrationCompleted,
      isCasesPending: isCasesPending,
    },
    {
      enabled: true,
      ...I18nFunction({
        id: 'connection_form.tabs.role',
        returnRawObject: true,
      }),
      image: roleIcon,
      connectionType: USER_ROLE,
      caseType: USER_ROLE_CASE,
      connectionFormTabName: 'role',
      tabStats: caseStatistics[USER_ROLE_CASE],
      items: userRoleCases,
      hasApplicationComponents:
        process.env.REACT_APP_APPLICATIONS_ENABLED?.toLowerCase() === 'true',
      connectionKeys: {
        publicData: 'userPublicDataRole',
        userRegistered: 'userRole',
        applications: 'roleApplications',
      },
      completed: roleRegistrationCompleted,
      isCasesPending: isCasesPending,
    },
    {
      enabled:
        process.env.REACT_APP_RELATIONS_ENABLED?.toLowerCase() === 'true',
      ...I18nFunction({
        id: 'connection_form.tabs.relation',
        returnRawObject: true,
      }),
      image: relationIcon,
      connectionType: RELATION,
      caseType: RELATION,
      connectionFormTabName: 'relation',
      tabStats: caseStatistics[RELATION_CASE],
      items: userRelations,
      hasApplicationComponents: false,
      completed: relationRegistrationCompleted,
      isCasesPending: isRelationCasesPending,
    },
    {
      enabled: true,
      ...I18nFunction({
        id: 'connection_form.tabs.other',
        returnRawObject: true,
      }),
      image: otherIcon,
      connectionType: USER_OTHER_CONNECTION,
      caseType: USER_OTHER_CONNECTION_CASE,
      connectionFormTabName: 'other',
      tabStats: caseStatistics[USER_OTHER_CONNECTION_CASE],
      items: userOtherConnectionCases,
      hasApplicationComponents: false,
      connectionKeys: {
        publicData: null,
        userRegistered: 'userOtherConnection',
        applications: null,
      },
      completed: otherConnectionRegistrationCompleted,
      isCasesPending: isCasesPending,
    },
  ]
  const onTabClicked = (index) => {
    if (index !== tabIndex) {
      unsetActiveConnection()
      setTabIndex(index)
      resetPagination()
    }
  }
  return (
    <TabsWrapper>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => onTabClicked(tabIndex)}
      >
        <TabList>
          {tabs
            .filter((t) => t.enabled === true)
            .map((props, index) => {
              return (
                <Tab key={index}>
                  <TabContent {...props} index={index} isUserProfileDataFetching={isUserProfileDataFetching} />
                </Tab>
              )
            })}
        </TabList>

        {tabs
          .filter((t) => t.enabled === true)
          .map((props, index) => (
            <TabPanel key={index}>
              <ProfileCaseSection
                {...props}
                userId={userId}
                isCurrentUsersProfile={userId === currentUser.id}
              />
            </TabPanel>
          ))}
      </Tabs>
    </TabsWrapper>
  )
}

const TabsWrapper = styled.div`
  margin-bottom: 5em;
`

const mapDispatchToProps = {
  unsetActiveConnection,
  resetPagination,
}

const mapStateToProps = (state, props) => {
  const user = getUser(state, props.userId) || {}
  const currentUser = getCurrentUser(state)
  return {
    currentUser,
    isAdmin: isAdmin(state),
    confirmedFormOn: user.confirmedFormOn,
    lastLoggedIn: user.lastLoggedIn,
    isReminderFetching: isReminderFetching(state),

    userOwnershipCases: getUserCasesOfType(
      state,
      props.userId,
      USER_OWNERSHIP_CASE,
    ),
    userRoleCases: getUserCasesOfType(state, props.userId, USER_ROLE_CASE),
    userOtherConnectionCases: getUserCasesOfType(
      state,
      props.userId,
      USER_OTHER_CONNECTION_CASE,
    ),
    userRelations: getUserRelations(state, props.userId),
    caseStatistics: getCaseStatistics(state),
    isCasesPending: isCasesPending(state),
    isRelationCasesPending: isRelationCasesPending(state, props.userId),
    isUserProfileDataFetching: isUserProfileDataFetching(state,props.userId)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileConnectionTabs)
