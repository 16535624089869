import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import I18n from './I18n'
import Button from './Button'
import {
  addProjectCompany,
  deselectProjectCompany,
  clearSelectedProjectCompanies,
} from '../redux/actions'
import { getSelectedProjectCompanies } from '../redux/selectors'
import ProjectCompanyForm from './ProjectForm/ProjectCompanyForm'

const AddProjectCompanyModal = ({
  projectId,
  onClose,
  addProjectCompany,
  selectedProjectCompanies,
  deselectProjectCompany,
  clearSelectedProjectCompanies,
}) => {
  const handleSubmit = () => {
    addProjectCompany(projectId, selectedProjectCompanies)
    onClose()
  }

  const handleDeselect = (organizationNumber) => {
    deselectProjectCompany(projectId, organizationNumber)
  }

  const handleClose = () => {
    onClose()
    clearSelectedProjectCompanies(projectId)
  }

  return (
    <Overlay onClick={handleClose}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <Body>
          <HeaderWrapper>
            <Header>Legg til selskaper til prosjekt</Header>
          </HeaderWrapper>
          <ProjectCompanyForm
            projectId={projectId}
            selectedProjectCompanies={selectedProjectCompanies}
          />
          <Scrollbox>
            <SubHeader>Valgte selskaper</SubHeader>
            <List>
              {selectedProjectCompanies?.map((company) => (
                <li key={company.organizationNumber}>
                  <CompanyListItem>
                    <Organization>
                      <OrganizationName>
                        {company.organizationName}
                      </OrganizationName>
                      <OrganizationNumber>
                        {company.organizationNumber}
                      </OrganizationNumber>
                    </Organization>
                    <Deselect>
                      <DeselectButton
                        onClick={() =>
                          handleDeselect(company.organizationNumber)
                        }
                      >
                        ×
                      </DeselectButton>
                    </Deselect>
                  </CompanyListItem>
                </li>
              ))}
            </List>
          </Scrollbox>
          <Options>
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              inactive={!selectedProjectCompanies || selectedProjectCompanies?.length < 1}
              disabled={!selectedProjectCompanies || selectedProjectCompanies?.length < 1}
            >
              <I18n id="Add_project_companies" />
            </Button>
            <Button subtle onClick={handleClose} small>
              <I18n id="Cancel" />
            </Button>
          </Options>
        </Body>
      </ContentWrapper>
    </Overlay>
  )
}

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`

const ContentWrapper = styled.div`
  position: relative;
  overflow: auto
  background-color: ${(props) => props.theme.primaryBackground};
  margin: 0 auto;
  height: 70%;
  width: 40%;
  border-radius: 8px;
  -moz-box-shadow: 0px 0px 18px 6px #ccc;
  -webkit-box-shadow: 0px 0px 18px 6px #ccc;
  box-shadow: 0px 0px 10px 0px #ccc;
  display: flex;
  flex-direction: column;
  gap: 2em;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5%;
  background-color: ${(props) => props.theme.primaryBackground};
  border-radius: 6px;
`
const Header = styled.h1`
  margin: 0;
  color: ${(props) => props.theme.text};
`

const SubHeader = styled.h2`
  margin: 0;
  margin-bottom: 0.5em;
  color: ${(props) => props.theme.text};
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.primaryBackground};
  border-radius: 6px;
  padding: 2em;
  gap: 1em;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  margin-bottom: 5em;
  padding: 1em;

  & > :not(:last-child) {
    margin-bottom: 0.5em;

`

const Scrollbox = styled.div`
  background-color: ${(props) => props.theme.pageBackground};
  margin: 0;
  position: relative;
  padding: 1em;
  border-radius: 6px;
  height: 42%;
  overflow: auto;
  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const CompanyListItem = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.primaryBackground};
  box-shadow: rgb(219 219 219) 0px 2px 5px;
  padding: 0.5em 1em;
  border-radius: 5px;
`
const Organization = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  gap: 1em;
`
const OrganizationName = styled.div``
const OrganizationNumber = styled.div`
  color: ${({ theme: { lightText } }) => lightText};
`

const Deselect = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 10%;
`

const DeselectButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme: { lightText } }) => lightText};
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
  margin: 0px;
`

const Options = styled.div`
  display: flex;
  height: 10%;
  justify-content: flex-end;
  padding: 0em 0.5em 0.5em 0.5em;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  gap: 1em;
`

const mapStateToProps = (state, props) => ({
  selectedProjectCompanies: getSelectedProjectCompanies(state, props.projectId),
})

const mapDispatchToProps = {
  addProjectCompany,
  deselectProjectCompany,
  clearSelectedProjectCompanies,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddProjectCompanyModal)
