import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import LinkStyled from '../../components/Link'
import { camelize } from 'humps'
import I18n from '../I18n'
import { useI18nFunction } from '../I18n'
import IndirectOwnershipListItem from './IndirectOwnershipListItem'
import {
  USER_OWNERSHIP_CASE,
  USER_INDIRECT_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  USER_INDIRECT_ROLE_CASE,
  RELATION_OWNERSHIP_CASE,
  RELATION_INDIRECT_OWNERSHIP_CASE,
  RELATION_ROLE_CASE,
  RELATION_INDIRECT_ROLE_CASE,
} from '../../caseTypes'
import {
  USER_INDIRECT_OWNERSHIP,
  USER_INDIRECT_ROLE,
  RELATION_INDIRECT_OWNERSHIP,
  RELATION_INDIRECT_ROLE,
} from '../../ownershipTypes'
import { 
  USER_OWNERSHIP,
  RELATION_OWNERSHIP
} from '../../connectionTypes'
import SmallSpinner from '../SmallSpinner'
import Button from '../../components/Button'
import {
    getUserCasesOfType,
    getCurrentUser,
    isIndirectCasesPending,
    getSelectedCase,
  } from '../../redux/selectors'
  import {
    addConnection,
    massConfirmIndirectConnections,
  } from '../../redux/actions'
import AddConnectionButton from '../AddConnectionButton'
import useToggleCases from '../../hooks/useToggleCases'
import indirectIcon from '../../icons/indirect.svg'

const IndirectOwnershipList = ({
    userId,
    caseId, 
    relationId,
    isRole,
    connectionType,
    indirectOwnershipType,
    indirectCases,
    addConnection,
    massConfirmIndirectConnections,
    pending,
    }) => {

    const I18nFunction = useI18nFunction()

    const { toggleAllCases, selectedCaseIds, selectedCases, toggleCase, clearAllCases } =
    useToggleCases(indirectCases)

    const buttonDisabled = selectedCaseIds.length === 0 ? 1 : 0
    const hasIndirectCases = indirectCases.length > 0

    const handleToggleAll = () => {
      toggleAllCases()
    }

    const onConfirm = () => {
      massConfirmIndirectConnections(userId, relationId, caseId, connectionType, selectedCaseIds)
      clearAllCases()
    }

    return (
      pending ? (
        <>
          <TitleWrapper>
            <Icon src={indirectIcon} alt="" />
            <IndirectTitle>
              {I18nFunction( { 
                id:'indirect_ownership_list.indirect_title',
                num_indirect: indirectCases.length,
                isRole: isRole,
              })}
            </IndirectTitle>
          </TitleWrapper>
          <IndirectLoading>
            <SmallSpinner size={'3em'} color={'secondary'} />
            {isRole ?
              <LoadingText>
                <I18n id="indirect_ownership_list.loading_indirect_connections"/>
              </LoadingText>
              :
              <LoadingText>
                <I18n id="indirect_ownership_list.loading_indirect_ownership"/>
              </LoadingText>
            }
          </IndirectLoading>
        </>
      ) : (
      caseId && (
        hasIndirectCases ?
        <>
        <TitleAndOptions>
          <TitleWrapper>
            <Icon src={indirectIcon} alt="" />
            <IndirectTitle>
              {I18nFunction( { 
                id:'indirect_ownership_list.indirect_title',
                num_indirect: indirectCases.length,
                isRole: isRole,
              })}
            </IndirectTitle>
          </TitleWrapper>
          <Options>
            <ActionButton
              small
              inactive={buttonDisabled}
              disabled={buttonDisabled}
              onClick={onConfirm}
            >
              <I18n id="indirect_ownership_list.confirm_selected"/>
            </ActionButton>
            <AddConnectionButton
              small
              indirect
              connectionType={connectionType}
              ownershipType={indirectOwnershipType}
              directConnectionCaseId={caseId}
              userId={userId}
              relationId={relationId}
              hasTransientConnection={false}
              action={addConnection}
            >
              <I18n id="Add"/>
            </AddConnectionButton>
          </Options>
        </TitleAndOptions>
        <Header> 
          <ToggleAllButton onClick={handleToggleAll} to="">
          {selectedCaseIds.length === 0 ? (
            <I18n id="Select_all" />
          ) : (
            <I18n id="Remove_selection" />
          )}
        </ToggleAllButton>
          <ColumnTitles>
            <Case>
              <I18n id="CaseId" />
            </Case>
            <OrganizationName>
              <I18n id="indirect_ownership_list.list_columns.name_and_org_nr"/>
            </OrganizationName>
            <StakePercentage>
              {!isRole && 
                <I18n id="indirect_ownership_list.list_columns.indirect_share"/>
              }
            </StakePercentage>
            <Status>
              <I18n id="indirect_ownership_list.list_columns.status"/>
            </Status>
            <Action/>
          </ColumnTitles>
        </Header>
        <Wrapper>
          <WrapperCenter>
            <IndirectOwnershipListBox>
              {
                indirectCases.length > 0 && (
                  <Scrollbox>
                    <List>
                    {indirectCases
                      ?.map((item, index) => (
                        <IndirectOwnershipListItem 
                            key={caseId + item.id}
                            Case={item}
                            selectedCases={selectedCases}
                            toggleCase={toggleCase}
                            indirectOwnershipType={indirectOwnershipType}
                            isRole={isRole}
                        />
                      ))}
                    </List>
                  </Scrollbox>
                )
              }
            </IndirectOwnershipListBox>
          </WrapperCenter>
        </Wrapper>
      </>
      :
      <TitleAndOptions>
        <TitleWrapper>
          <Icon src={indirectIcon} alt="" />
          <IndirectTitle>
            {I18nFunction( { 
              id:'indirect_ownership_list.indirect_title',
              num_indirect: indirectCases.length,
              isRole: isRole,
            })}
          </IndirectTitle>
        </TitleWrapper>
        <Options>
          <AddConnectionButton
            small
            indirect
            connectionType={connectionType}
            ownershipType={indirectOwnershipType}
            directConnectionCaseId={caseId}
            userId={userId}
            relationId={relationId}
            hasTransientConnection={false}
            action={addConnection}
          >
            <I18n id="Add"/>
          </AddConnectionButton>
        </Options>
      </TitleAndOptions>
      ) 
    )
  )
}

const LoadingText = styled.div`
  margin-right: 2em;
`

const IndirectTitle = styled.h2`
  color: ${({ theme: { text } }) => text};
  margin-left: 1em;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ToggleAllButton = styled(LinkStyled)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-left: 1em;
  padding-right: 1em;
  width: 8%;
`

const ColumnTitles = styled.div`
  width: 92%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-right: 2.5em;
  border-bottom: 1px solid #DCDCDC;
  color: ${({ theme: { subtleText } }) => subtleText};
`

const Case = styled.div`
  width: 11%;
`

const OrganizationName = styled.div`
  width: 53%;
  display: flex;
  align-items: center;
`

const StakePercentage = styled.div`
  width: 27%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const Status = styled.div`
  width: 9%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const Action = styled.div`
  width: 2%
  display: flex;
  align-items: center;
  justify-content: space-around;
`
  
const ActionButton = styled(Button)`
  background: #0c3689;
  border: 2px solid #0c3689;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 10em;
`

const Options = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.5em;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};

  & > * {
    margin: 0 0.5em;
  }
`

const Icon = styled.img`
  margin-left: 1em;
  height: 1,7em;
  width: 1.7em;
  flex-shrink: 0;
`

const TitleAndOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  padding-right: 0.5em;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const WrapperCenter = styled.div`
  width: 95%;
  height: 100%
`

const Scrollbox = styled.div`
  margin: 0;
  position: relative;
  max-height: 300px;
  padding: 0em 1em;
  overflow: auto;
  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const List = styled.ul`
  list-style: none;
  padding-top: 0px;
  padding-left: 0px;

  & > :not(:last-child) {
    margin-bottom: 0.5em;
  }
`

const IndirectLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 100%;
  gap: 2em;
  padding: 2em;
  color: ${({ theme }) => theme.secondary};
`

const IndirectOwnershipListBox = styled.div` 

`
const mapDispatchToProps = {
  addConnection,
  massConfirmIndirectConnections
}

const mapStateToProps = (state, props) => {
    const userId = getCurrentUser(state).id
    const Case = getSelectedCase(state)
    const caseId = Case?.id
    const caseType = Case?.caseType

    let connectionType 
    let indirectCaseType
    let indirectOwnershipType
    let isRole

    if (caseType){
      switch (camelize(caseType)){
        case USER_OWNERSHIP_CASE:
          connectionType = USER_OWNERSHIP
          indirectCaseType = USER_INDIRECT_OWNERSHIP_CASE
          indirectOwnershipType = USER_INDIRECT_OWNERSHIP
          isRole = false
          break
        case USER_ROLE_CASE:
          connectionType = USER_OWNERSHIP
          indirectCaseType = USER_INDIRECT_ROLE_CASE
          indirectOwnershipType = USER_INDIRECT_ROLE
          isRole = true
          break
        case RELATION_OWNERSHIP_CASE:
          connectionType = RELATION_OWNERSHIP
          indirectCaseType = RELATION_INDIRECT_OWNERSHIP_CASE
          indirectOwnershipType = RELATION_INDIRECT_OWNERSHIP
          isRole = false
          break
        case RELATION_ROLE_CASE:
          connectionType = RELATION_OWNERSHIP
          indirectCaseType = RELATION_INDIRECT_ROLE_CASE
          indirectOwnershipType = RELATION_INDIRECT_ROLE
          isRole = true
          break
      }
    }

    return{
        caseId: caseId,
        relationId: Case?.relationId ? Case?.relationId : undefined,
        caseType: caseType,
        connectionType: connectionType,
        indirectOwnershipType: indirectOwnershipType,
        isRole: isRole,
        indirectCases: getUserCasesOfType(
            state,
            userId,
            indirectCaseType).filter((Case) =>
                Case.directConnectionCaseId == caseId
              ),
        pending: isIndirectCasesPending(state, caseId)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndirectOwnershipList)