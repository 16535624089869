import { isBefore, subYears } from 'date-fns'
import { LOG_OUT_SUCCEEDED } from '../auth'
import { CREATE_CONNECTION_SUCCEEDED } from '../connections'
import { confirmedNoConnectionsProp } from '../../utils'
import {
  FETCH_ME_SUCCEEDED,
  COMPLETE_ONBOARDING_SUCCEEDED,
  SET_BIRTHDATE_AND_ADDRESS_SUCCEEDED,
  CONFIRM_NO_CONNECTIONS_SUCCEEDED,
  CANCEL_CONFIRM_NO_CONNECTIONS_SUCCEEDED,
  ACCEPTED_COOKIES_SUCCEEDED,
} from './index'
import { SET_PROFF_PERSON_SUCCEEDED } from '../proffSelector'

export { default as sagas } from './sagas'

const initialState = null

const user = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ME_SUCCEEDED:
    case COMPLETE_ONBOARDING_SUCCEEDED:
    case ACCEPTED_COOKIES_SUCCEEDED:
    case SET_BIRTHDATE_AND_ADDRESS_SUCCEEDED:
    case CONFIRM_NO_CONNECTIONS_SUCCEEDED:
    case SET_PROFF_PERSON_SUCCEEDED:
    case CANCEL_CONFIRM_NO_CONNECTIONS_SUCCEEDED:
      return action.payload.entities.user[action.payload.result] || {}

    case CREATE_CONNECTION_SUCCEEDED:
      return {
        ...state,
        [confirmedNoConnectionsProp(action.connectionType)]: null,
      }

    case LOG_OUT_SUCCEEDED:
      return initialState

    default:
      return state
  }
}

export const get = (state) => state
export const getId = (state) => (state ? state.id : undefined)
export const isFetching = (state) => !state

export const isProjectAdmin = (state) => (state ? state.isProjectAdmin : undefined)
export const isManager = (state) => (state ? state.isManager : undefined)

export const hasConfirmedTermsAndConditions = (state) =>
  state.completedOnboardingOn
    ? isBefore(subYears(Date.now(), 1), new Date(state.completedOnboardingOn))
    : false


export const hasAcceptedCookies = (state) => {
  if (!state || !state.acceptedCookiesOn) {
    return false;
  }
  
  return isBefore(subYears(Date.now(), 1), new Date(state.acceptedCookiesOn));
};

export const hasConfirmedBirthdateAndAddress = (state) =>
  state.confirmedBirthdateAndAddressOn
    ? isBefore(
        subYears(Date.now(), 1),
        new Date(state.confirmedBirthdateAndAddressOn),
      )
    : false

export const hasConfirmedProffSelection = (state) =>
  state.completedProffSelection
    ? isBefore(subYears(Date.now(), 1), new Date(state.completedProffSelection))
    : false

export const hasConfirmedNoConnections = (state, connectionType) =>
  isBefore(
    subYears(Date.now(), 1),
    new Date(state[confirmedNoConnectionsProp(connectionType)]),
  )

export default user
