import React, { useState } from 'react'
import styled from 'styled-components'
import { Dropdown, MenuItem } from './Dropdown'
import norwegian from '../../icons/norway.svg'
import english from '../../icons/united-kingdom.svg'
import { setLanguage } from '../../redux/actions'
import { connect } from 'react-redux'
import { getLanguage } from '../../redux/selectors'

const languages = {
  no: {
    name: 'Norsk',
    icon: norwegian,
    id: 'no',
  },
  en: {
    name: 'English',
    icon: english,
    id: 'en',
  },
}

const LanguageSelector = ({setLanguage, language, Backdrop, selected, onToolClicked}) => {


  const [isOpen, setIsOpen] = useState(selected)

  const { icon, name } = languages[language]

  return (
    <Wrapper>
      <ActiveLanguage onClick={() => onToolClicked()}>
        <Icon src={icon} alt={name} />
      </ActiveLanguage>
      {isOpen && (
        <>
          <Backdrop/>
          <Dropdown>
            {Object.values(languages).map(({ name, icon, id }) => (
              <MenuItem
                key={name}
                onClick={() => {
                  setLanguage(id)
                  setIsOpen(false)
                }}
              >
                <Icon src={icon} alt={name} />
                <span>{name}</span>
              </MenuItem>
            ))}
          </Dropdown>
        </>

      )}
    </Wrapper>
  )
}

const ActiveLanguage = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: inherit;
  transform: translateY(0.125em);
  &:hover {
    opacity: 0.8;
  }
`

const Icon = styled.img`
  height: 2em;
  width: 2em;
`

const Wrapper = styled.div`
  position: relative;
`


const mapStateToProps = (state) => {
  return {
    language: getLanguage(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return { 
    setLanguage: (language) => dispatch(setLanguage(language)) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)

