import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ReactTextarea from 'react-textarea-autosize'
import Button from './Button'
import I18n, { useI18nFunction } from '../components/I18n'
import { prettyDate } from '../dateUtils'
import { commentOnProject } from '../redux/actions'

const ProjectHistory = ({ project, commentOnProject }) => {
  const I18nFunction = useI18nFunction()
  const [comment, setComment] = useState('')

  const events = project?.events || []
  const comments = project?.comments || []
  const history = [...events, ...comments]
  history.sort((a, b) => a.createdOn.localeCompare(b.createdOn))

  const handleSubmitComment = () => {
    commentOnProject(project?.id, comment)
    setComment('')
  }

  return (
    <div>
        <Scrollbox>
          <List>
            {history?.map(({ id, createdOn, eventType, userName, payload }) => (
              <li key={id + createdOn}>
                {eventType &&
                  <EventItem>
                      <EventType>
                        {I18nFunction({
                          id: 'project_event.event_title',
                          event_type: eventType,
                          payload: payload
                        })}
                    </EventType>
                    <EventDate>{prettyDate(createdOn)}</EventDate>
                      <EventText>
                        {I18nFunction({
                          id: 'project_event.event_description',
                          event_type: eventType,
                          payload: payload,
                        })}
                      </EventText>
                  </EventItem>
                }
                {userName && payload.comment &&
                  <EventItem className="comment">
                    <EventType>
                      {userName}
                    </EventType>
                    <EventDate>{prettyDate(createdOn)}</EventDate>
                    <EventText>
                      {payload.comment}
                    </EventText>
                    <Comment>Kommentar</Comment>
                  </EventItem>
                }
              </li>
            ))}
          </List>
      </Scrollbox>
      <Form onSubmit={handleSubmitComment}>
        <StyledTextarea
          placeholder={I18nFunction({ id: 'comment_placeholder' })}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          required
          minRows={2}
        />
        <Button
          pending={false}
          type="submit"
          small
          onClick={handleSubmitComment}
          disabled={!comment}
          inactive={!comment}
        >
          <I18n id="Comment" />
        </Button>
      </Form>
    </div>
  )
}

const Scrollbox = styled.div`
  margin: 0;
  position: relative;
  padding: 1em 1em 2em 0em;
  max-height: 400px;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 0.5em;
  }
`

const EventItem = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: auto;
  gap: 0.5em;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 0.5em;
  min-height: 3em;
  background-color: ${({ theme: { pageBackground } }) => pageBackground};
  
  &.comment {
    background-color: ${({ theme: { allCommentBackground } }) => allCommentBackground};
  }

`

const EventType = styled.div`
  font-weight: bold;
`

const EventDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  color: ${({ theme: { subtleText } }) => subtleText};
  white-space: nowrap;
`

const Comment = styled.div`
  color: ${({ theme: { subtleText } }) => subtleText};
  font-size: ${({ theme: { smallText } }) => smallText};
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const EventText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`

const Form = styled.form`
  text-align: right;
`

const StyledTextarea = styled(ReactTextarea)`
  display: block;
  width: 100%;
  resize: none;
  padding: 1em;
  border: none;
  line-height: inherit;
  margin: 1em auto;
  border-radius: 10px;
  background-color: ${({ theme: { allCommentBackground } }) => allCommentBackground};
`

const mapDispatchToProps = { commentOnProject }

export default connect(null, mapDispatchToProps)(ProjectHistory)
