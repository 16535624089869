import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchUserProfileCases } from '../../redux/actions'
import { isValidUUID } from '../../utils'
import I18n from '../I18n'
import ScrollPaginate from '../ScrollPaginate'
import ProfileCaseListDefault from './ProfileCaseListDefault'

const ProfileCaseList = (props) => {

  const dispatch = useDispatch()
  useEffect(() => {
    if (props.caseType)
      dispatch(fetchUserProfileCases(props.userId, props.caseType))
  }, [])
  const shouldFetchData =
    (props.casePagination?.perPage * props.pageIndex < props.casePagination?.total) &&
    (props.casePagination?.total > props.numberOfTotalItems)
  return (
    <ScrollPaginate
      fetchListItems={(pageIndex) => {
        dispatch(fetchUserProfileCases(props.userId, props.caseType, pageIndex))
      }}
      listItems={props.items}
      loading={props.isCasesPending}
      maxHeight={'500px'}
      pageIndex={props.pageIndex}
      pagination={props.casePagination}
      setPageIndex={props.setPageIndex}
      shouldFetchData={shouldFetchData}
      isMoreData={shouldFetchData}
    >
        <ProfileCaseListDefault {...props} />
      {props.items?.length == 0 &&
        !isValidUUID(props.activeConnection?.id) &&
        !props.isCasesPending && (
          <div>
            <I18n id="connection_form.No_connections_registered" />{' '}
          </div>
        )}
    </ScrollPaginate>
  )
}


export default ProfileCaseList
