import { call, put, takeEvery, all } from 'redux-saga/effects'
import {
  FETCH_APP_CONTENT,
  FETCH_APP_CONTENT_FAILED,
  SET_LANGUAGE,
} from './index'
import { LOG_OUT_SUCCEEDED } from '../auth/actionTypes'
import {
  setAppContentToState,
  setStaticAppContent,
} from './index'
import {
  setLocalStorageLanguage,
  getDefaultLanguage,
} from './languageUtils'
import { getUnCamelized } from '../fetch'
import staticContent from './static_default'

let staticContentEn = staticContent['en']
let staticContentNo = staticContent['no']

export function* clearAppContent() {
  const language = getDefaultLanguage()
  staticContentEn = staticContent['en']
  staticContentNo = staticContent['no']

  if (language === 'en') {
    yield put(setStaticAppContent(staticContentEn))
  } else {
    yield put(setStaticAppContent(staticContentNo))
  }
}

function* fetchMergedContent(language) {
  try {
    let { data, errors } = yield call(() =>
      getUnCamelized(`/content/${language}`),
    )

    if (errors) {
      yield put({
        type: FETCH_APP_CONTENT_FAILED,
        errors,
      })
      return null // Return null if there were errors.
    } else {
      return data.content
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: FETCH_APP_CONTENT_FAILED,
      errors: [{ detail: error.message }],
    })
    return null // Return null also in case of exceptions.
  }
}


function* fetchAllAppContentSaga() {
  try {
    // Setting the global variables to the retrieved data
    [staticContentEn, staticContentNo] = yield all([
      call(fetchMergedContent, 'en'),
      call(fetchMergedContent, 'no'),
    ])

    if (getDefaultLanguage() === 'en') {
      yield put(setAppContentToState(staticContentEn))
    } else {
      yield put(setAppContentToState(staticContentNo))
    }

  } catch (error) {
    console.error(error)
    yield put({
      type: FETCH_APP_CONTENT_FAILED,
      errors: [{ detail: error.message }],
    })
  }
}

function* setLanguageSaga(action) {
  const newLanguage = action.payload
  yield setLocalStorageLanguage(newLanguage)

  if (newLanguage === 'en') {
    yield put(setAppContentToState(staticContentEn))
  } else {
    yield put(setAppContentToState(staticContentNo))
  }
}

function* saga() {
  yield takeEvery(FETCH_APP_CONTENT, fetchAllAppContentSaga)
  yield takeEvery(SET_LANGUAGE, setLanguageSaga)
  yield takeEvery(LOG_OUT_SUCCEEDED, clearAppContent)
}

export default saga
